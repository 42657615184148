const incident = {
  list: {
    searchPlaceholder: "Found names or CIM...",
    createNewBtn: "Create new",
    assignedTo: "Assigned to",
    chooseEmployeeBtn: "Choose",
    incidentDetails: "Details",
  },
  selectType: {
    selectIncidentTypeTitle: "Select Incident Type",
    backBtn: "Back",
  },
  info: {
    incidentDetailsTitle: "Incident details",
    editButton: "Edit",
    infoTitle: "Info",
    customer: "Customer",
    created: "Created",
    assignedEmployee: "Assigned to",
    store: "Store",
    priority: "Priority",
    origin: "Origin",
    customerMood: "Customer mood",
    description: "Description",
  },
  statusInfo: {
    statusInfoTitle: "Incident Status",
    changeStatusBtn: "Change status",
    closeBtn: "Close",
  },
  changeStatus: {
    changeStatusTitle: "Change incident status",
    description: "Add description",
    descriptionPlaceholder: "Add a note about this",
    cancelBtn: "Cancel",
    submitChangeBtn: "Submit change",
    successMessage: "Incident status updated successfully.",
  },
  tabTitles: {
    basicInfo: "Basic info",
    incidentInfo: "Incident info",
    otherInfo: "Other info",
    completed: "Completed",
  },
  tabDescriptions: {
    basicInfo: "Type, customer, store",
    incidentInfo: "Order, title, ...",
    otherInfo: "Priority, Origin, ...",
    completed: "Woah, we are here",
  },
  form: {
    createNewIncidentTitle: "Create new incident",
    editIncidentTitle: "Edit incident",
    customer: "Customer",
    customerPlaceholder: "Select",
    changeCustomerBtn: "Change",
    store: "Store",
    changeStoreBtn: "Change",
    assignedEmployee: "Assigned to",
    assignToMe: "To me",
    assignToOthers: "To others",
    assignToDepartment: "To department",
    noAssign: "No one",
    assignedEmployeePlaceholder: "Select",
    title: "Title",
    titlePlaceholder: "Enter incident title",
    priority: "Priority",
    priorityPlaceholder: "Select",
    origin: "Origin",
    originPlaceholder: "Select",
    topicDescription: "Topic description",
    topicDescriptionPlaceholder: "Add a note about this topic",
    attachmentOrder: "Assigned order",
    attachmentOrderPlaceholder: "Select",
    attachLineItem: "Assigned line item",
    attachLineItemBtn: "Select line item",
    changeOrderBtn: "Change",
    customerMood: "Customer mood",
    description: "Description",
    descriptionPlaceholder: "Add a note about this incident",
    cancelBtn: "Cancel",
    backBtn: "Back",
    submitBtn: "Submit",
    nextBtnBtn: "Next",
    submitChangeText: "Please submit your changes",
    submitChangeBtn: "Submit change",
    incidentTypeMessage: "Incident type is required",
    customerMoodMessage: "Customer mood is required",
    createSuccessMessage: "Incident added successfully.",
    updateSuccessMessage: "Incident updated successfully.",
    assignEmployeeMessage: "Employee assigned successfully.",
  },
  chooseCustomer: {
    title: "Choose customer",
    chooseBtn: "Choose",
  },
  chooseEmployee: {
    title: "Choose employee",
    search: "Search",
    chooseBtn: "Choose",
  },
  chooseDepartment: {
    title: "Choose department",
    search: "Search",
    chooseBtn: "Choose",
  },
  chooseOrder: {
    title: "Choose order",
    search: "Search",
    chooseBtn: "Choose",
    clearBtn: "Clear",
  },
  chooseLineItem: {
    title: "Choose line item",
    search: "Search name",
    chooseBtn: "Choose",
  },
  filter: {
    title: "Filter",
    resetFilterBtn: "Reset filter",
    customer: "Customer",
    customerPlaceholder: "Select customer",
    assignedOrder: "Assigned order",
    assignedOrderPlaceholder: "Select order",
    store: "Store",
    storePlaceholder: "Select store",
    assignedEmployee: "Assigned to",
    assignedEmployeePlaceholder: "Select employee",
    assignToMe: "Assign to me",
    assignToOthers: "Assign to others",
    assignToDepartment: "Assign to department",
    noAssign: "No assign",
    incidentStatus: "Incident status",
    cancelBtn: "Cancel",
    submitBtn: "Submit filter",
  },
  details: {
    goToNextStepButton: "Go to next step",
    finalButtonButton: "Final",
    subjectName: "Type",
    createdAt: "Created at",
    store: "Store",
    generalTab: "Basic info",
    activitiesTab: "Timeline",
    documentsTab: "Documents",
    alertTitle: "Incident status is {{ status }}",
    alertText: "You must change status to can editing again",
  },
  activities: {
    addNewButton: "Add new",
    createNewActivityTitle: "Create new activity",
    chooseActivityType: "Choose activity type",
    chooseActivityTypeBackBtn: "Back",
    activityDescription: "Description",
    activityModifiedBy: "modified by",
    active: "Active",
    moreDetailsBtn: "More details",
    lessDetailsBtn: "Less details",
    deleteActivityTitle: "Delete activity",
    deleteActivityCancelBtn: "Cancel",
    deleteActivityDeleteBtn: "Delete activity",
    cancelActivityTitle: "Cancel activity",
    cancelActivityCancelBtn: "Cancel",
    cancelActivityDeleteBtn: "Cancel activity",
    completeActivityTitle: "Complete activity",
    completeActivityCancelBtn: "Cancel",
    completeActivityDeleteBtn: "Complete activity",
    info: {
      infoTitle: "Info",
      descriptionTitle: "Description",
      createdAt: "Created at",
      duration: "Duration",
      dueDate: "Due date",
      priority: "Priority",
      status: "Status",
      assignedName: "Assigned to",
    },
    createNew: {
      drawerTitle: "Create new activity",
      subject: "Task title",
      subjectPlaceholder: "Enter title",
      description: "Description",
      descriptionPlaceholder: "Add a note about this ",
      priority: "Priority",
      duration: "Duration",
      dueDate: "Due date",
      assignEmployee: "Assigned to",
      successMessage: "Task has been created.",
      cancelButton: "Cancel",
      submitButton: "Submit activity",
    },
  },
  commissions: {
    createNewBtn: "Create new Commission group",
  },
  changeStep: {
    title: "Change incident Step",
    currentStep: "Current step",
    nextStep: "Next step",
    technicalInspectionNeeded: "Technical inspection needed",
    technicalInspectionNeededYes: "Yes",
    technicalInspectionNeededNo: "No",
    description: "Description",
    assignToOrderTitle: "Assign to order",
    assignToOrderText: "You must assign this incident to order first.",
    cancelButton: "Cancel",
    goToNextStepButton: "Go to next Step",
  },
  checkRecordExistenceMessages: {
    account: "Company is not synced in CRM",
    contact: "Customer is not synced in CRM",
    salesOrder: "Order is not synced in CRM",
  },
};
export default incident;
