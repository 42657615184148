import { Fragment, useContext, useMemo } from "react";
import { ProductDetailsContext } from "..";
import {
  Accordion,
  Icon,
  Image,
  Text,
  Translate,
  Wrapper,
} from "../../../../components";
import { AmountCalculationType } from "../../../../enums";
import { useConverters, useFindAttribute, useToggle } from "../../../../hooks";
import { iconNameType } from "../../../../types";

export default function General() {
  const { product, hasBrand, brand } = useContext(ProductDetailsContext);
  const { convertAmount } = useConverters();
  const findAttribute = useFindAttribute();
  const [showCommission, toggleCommission] = useToggle(false);
  const salesCommission = product?.salesPersonCommission;
  const hasSalesCommission = salesCommission;
  const salesPersonCommissionValue = useMemo(() => {
    const isSalesCommissionPercent =
      salesCommission?.calculationType === AmountCalculationType.Percent;
    if (!showCommission) return "*****";
    if (isSalesCommissionPercent) return `${salesCommission.amount}%`;
    return convertAmount(salesCommission?.amount);
  }, [showCommission, salesCommission, convertAmount]);
  const moreInfo = [
    {
      icon: "Hashtag" as iconNameType,
      label: "products.details.articleNumber",
      value: `#${product.articleNumber}`,
    },
    {
      icon: "TableDocument" as iconNameType,
      label: "products.details.brand",
      value: hasBrand ? (
        <div className="flex flex-col items-start gap-1">
          <span>{brand?.name}</span>
          {!!brand?.logo?.url && (
            <Image
              src={brand?.logo?.url}
              alt={brand?.name}
              isDynamic
              className="inline-block rounded h-7"
            />
          )}
        </div>
      ) : (
        "--"
      ),
    },
    {
      icon: "Building" as iconNameType,
      label: "products.details.supplier",
      value: product.supplier?.name ?? "--",
    },
    {
      icon: "Building" as iconNameType,
      label: "products.details.supplierCode",
      value: product.supplier?.code ?? "--",
    },
    {
      icon: "Building" as iconNameType,
      label: "products.details.supplierArticleNumber",
      value: product.supplierArticleNumber ?? "--",
    },
    {
      icon: "Document" as iconNameType,
      label: "products.details.program",
      value: product.supplier?.program?.name ?? "--",
    },
    {
      icon: "Element3" as iconNameType,
      label: "products.details.productGroup",
      value: product.pimProductGroup?.name ?? "--",
    },
    {
      icon: "Element3" as iconNameType,
      label: "products.details.productFamily",
      value: (
        <Translate
          value={product.productFamily?.translates}
          defaultValue="--"
        />
      ),
    },
    {
      label: "products.details.salesPersonCommission",
      icon: "DollarCircle" as iconNameType,
      value: hasSalesCommission ? (
        <button
          type="button"
          onClick={toggleCommission}
          className="flex items-center gap-1"
        >
          <Icon
            name={showCommission ? "Eye" : "EyeSlash"}
            className="text-primary size-5"
          />
          {salesPersonCommissionValue}
        </button>
      ) : (
        "--"
      ),
    },
  ];
  return (
    <Fragment>
      <Accordion defaultActiveKey={["about-product", "more"]}>
        <Wrapper>
          <Accordion.Item eventKey="about-product">
            <Accordion.Toggle>
              <div className="space-y-2 flex-1 text-start">
                <h2 className="text-base text-dark">
                  <Text>products.details.aboutProductTitle</Text>
                </h2>
                <p className="text-secondary text-base font-medium">
                  {/* <Text>products.details.aboutProductText</Text> */}
                  <Translate
                    value={findAttribute(
                      product.attributeGroups,
                      "shortDescription"
                    )}
                    defaultValue="--"
                  />
                </p>
              </div>
            </Accordion.Toggle>
            <Accordion.Body>
              <p className="text-secondary text-base font-medium">
                <Translate
                  value={findAttribute(product.attributeGroups, "descriptions")}
                  defaultValue="--"
                />
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Wrapper>
        <Wrapper>
          <Accordion.Item eventKey="more">
            <Accordion.Toggle>
              <h2 className="text-base text-dark">
                <Text>products.details.moreInfo</Text>
              </h2>
            </Accordion.Toggle>
            <Accordion.Body>
              <table className="w-full">
                <tbody className="divide-y">
                  {moreInfo.map((e) => (
                    <tr key={e.label} className="align-top *:py-3">
                      <td className="text-secondary max-w-96">
                        <div className="w-fit">
                          <Icon
                            name={e.icon}
                            variant="Bold"
                            className="size-4 me-2"
                          />{" "}
                          <Text>{e.label}</Text>
                        </div>
                      </td>
                      <td className="text-gray-900 font-medium px-3">
                        {e.value}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Accordion.Body>
          </Accordion.Item>
        </Wrapper>
      </Accordion>
    </Fragment>
  );
}
