import { twMerge } from "tailwind-merge";
import { Icon } from "../components";
import { classNameProps } from "../types";

type Props = {
  productGroup: {
    name: string | null | undefined;
    code: string | null | undefined;
  } | null;
} & classNameProps;

export default function ProductGroupCard({ productGroup, className }: Props) {
  const hasCode = !!productGroup?.code;
  if (!productGroup) return null;
  return (
    <div className={twMerge("flex flex-center w-full gap-4", className)}>
      <Icon.Wrapper rounded>
        <Icon name="DocumentText" variant="Bold" />
      </Icon.Wrapper>
      <div className="flex-1">
        <h6 className="truncate">{productGroup.name}</h6>
        {hasCode && (
          <span className="block truncate text-secondary">
            #{productGroup.code}
          </span>
        )}
      </div>
    </div>
  );
}
