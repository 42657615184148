import { now } from "lodash";
import {
  createContext,
  createElement,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router";
import {
  BackButton,
  Button,
  Icon,
  Layout,
  Loading,
  NoItems,
  Tabs,
  Text,
  Wrapper,
} from "../../../components";
import { AvailabilityStatus, PimProductType } from "../../../enums";
import { withPermission } from "../../../hoc";
import { useAxios, usePermission, useToggle } from "../../../hooks";
import { isActiveAuction, isBetweenDate, randomUUID } from "../../../methods";
import { PimProduct, Product } from "../../../types";
import Attributes from "./Attributes";
import Auctions from "./Auctions";
import AvailabilityStatusTab from "./AvailabilityStatus";
import General from "./General";
import Inventory from "./Inventory";
import Items from "./Items";
import Media from "./Media";
import PrintHistory from "./PrintHistory";
import PrintTemplateDrawer from "./PrintTemplateDrawer";
import ProductInfo from "./ProductInfo";
import ProductPriceProvider from "./ProductPriceProvider";

type ContextType = {
  product: Product.Details;
  setProduct: Dispatch<SetStateAction<Product.Details | null>>;
  getProduct: () => void;
  updateProductLoading: boolean;
  updateProduct: () => Promise<void>;
  availabilityStatus: AvailabilityStatus;
  programAvailabilityStatuses: Product.AvailabilityStatusItem[];
  isStandard: boolean;
  isBundle: boolean;
  canEditPrice: boolean;
  hasPrice2: boolean;
  hasPrice1: boolean;
  isOverwritten: boolean;
  brand: PimProduct.SupplierProgramBrand | null;
  hasBrand: boolean;
  hasAuctions: boolean;
};

export const ProductDetailsContext = createContext({} as ContextType);

function ProductDetails() {
  const navigate = useNavigate();
  const { axios, loading } = useAxios();
  const { axios: updateProductAxios, loading: updateProductLoading } =
    useAxios();
  const { productId } = useParams();
  const hasEditPermission = usePermission("SC_OverwritePimProductPrice");
  const [data, setData] = useState<Product.Details | null>(null);
  const [programAvailabilityStatuses, setProgramAvailabilityStatuses] =
    useState<Product.AvailabilityStatusItem[] | null>(null);
  const [printRequestsKey, setPrintRequestsKey] = useState(randomUUID());
  const [shoePrintTemplate, togglePrintTemplate, hidePrintTemplate] =
    useToggle(false);
  const hasAuctions = !!data?.auctions?.filter(isActiveAuction)?.length;
  const hasData = !!data && programAvailabilityStatuses !== null;
  const hasLoading = loading.get;
  const isStandard = data?.pimProductType === PimProductType.Standard;
  const isBundle = data?.pimProductType === PimProductType.Bundle;
  const canEditPrice = isStandard && hasEditPermission;
  const vk2Amount = data?.uvpPrice?.amount ?? data?.vk2Price?.amount;
  const hasPrice2 = !!vk2Amount && data?.originalVk1Price?.amount !== vk2Amount;
  const hasPrice1 = !!data?.originalVk1Price;
  const brand = data?.supplier?.program?.brand ?? null;
  const hasBrand = !!brand;
  const availabilityStatus = useMemo(() => {
    const programStatuses = programAvailabilityStatuses ?? [];
    const programDefaultStatus = programStatuses.find((e) => e.isDefault);
    const programNotDefaultStatuses = programStatuses.filter(
      (e) => !e.isDefault
    );
    const productStatus = data?.availabilityStatus;
    const programStatus =
      programNotDefaultStatuses.find((e) =>
        isBetweenDate(now(), e.fromDate ?? 0, e.toDate ?? Infinity)
      ) ?? programDefaultStatus;
    const programIsBlocked =
      programStatus?.availabilityStatus === AvailabilityStatus.Blocked;
    const programIsInactive =
      programStatus?.availabilityStatus === AvailabilityStatus.Inactive;
    if (programIsBlocked) return AvailabilityStatus.Blocked;
    if (programIsInactive) return AvailabilityStatus.Inactive;
    return productStatus?.availabilityStatus ?? AvailabilityStatus.Active;
  }, [data?.availabilityStatus, programAvailabilityStatuses]);
  const isOverwritten = useMemo(() => {
    const parentHasOverwrittenPrice = !!data?.hasOverwrittenPrice;
    const itemHasOverwrittenPrice = !!data?.bundleItems?.some(
      (e) => e.hasOverwrittenPrice
    );
    const isEqual = data?.vk1Price?.amount === data?.originalVk1Price?.amount;
    const hasOverwritten = parentHasOverwrittenPrice || itemHasOverwrittenPrice;
    if (isStandard) return parentHasOverwrittenPrice && !isEqual;
    return hasOverwritten && !isEqual;
  }, [data, isStandard]);

  const reloadPrintRequests = () => {
    navigate({ search: `tab=print-history` });
    setPrintRequestsKey(randomUUID());
  };
  const initTabs = useMemo(
    () => [
      {
        label: "products.details.generalTab",
        id: "general",
        component: General,
        active: true,
      },
      {
        label: "products.details.itemsTab",
        id: "items",
        component: Items,
        active: isBundle,
      },
      {
        label: "products.details.attributesTab",
        id: "attributes",
        component: Attributes,
        active: true,
      },
      {
        label: "products.details.mediaTab",
        id: "media",
        component: Media,
        active: true,
      },
      {
        label: "products.details.inventoryTab",
        id: "inventory",
        component: Inventory,
        active: true,
      },
      {
        label: "products.details.printHistoryTab",
        id: "print-history",
        component: () => <PrintHistory key={printRequestsKey} />,
        active: true,
      },
      {
        label: "products.details.availabilityStatusTab",
        id: "availability-status",
        component: AvailabilityStatusTab,
        active: true,
      },
      // {
      //   label: "products.details.salesPersonCommissionTab",
      //   id: "commission",
      //   component: SalesPersonCommission,
      //   active: !isBundle,
      // },
      {
        label: "products.details.auctionsTab",
        id: "auctions",
        component: Auctions,
        active: true,
      },
    ],
    [isBundle, printRequestsKey]
  );
  const tabs = initTabs.filter((e) => e.active);
  const getData = () => {
    setData(null);
    const url = `/salesservice/api/salestoolspimproduct/${productId}`;
    axios.get(url).then(({ data }) => {
      setData(data);
    });
  };
  const getProgram = () => {
    const programId = data?.supplier?.program?.supplierProgramId;
    if (!programId) return;
    const url = `/salesservice/api/supplierprograms/${programId}/availability-status`;
    axios.get(url).then(({ data }) => {
      setProgramAvailabilityStatuses(data ?? []);
    });
  };
  const updateData = async () => {
    const url = `/salesservice/api/salestoolspimproduct/${productId}`;
    return await updateProductAxios.get(url).then(({ data }) => {
      setData((p) => ({
        ...p!,
        availabilityStatus: data.availabilityStatus,
        availabilityStatuses: data.availabilityStatuses,
      }));
    });
  };
  useEffect(getData, [productId]);
  useEffect(getProgram, [data?.supplier?.program?.supplierProgramId]);
  return (
    <Tabs activeKey={tabs[0]?.id}>
      <Layout>
        <Layout.Header>
          <BackButton to="/products" />
          {!hasLoading && (
            <Button
              type="button"
              variant="primary"
              light
              className="ms-auto"
              onClick={togglePrintTemplate}
            >
              <Icon name="Printer" variant="Bold" className="size-5" />{" "}
              <Text>products.details.printButton</Text>
            </Button>
          )}
        </Layout.Header>
        <Layout.Body className="space-y-4">
          {hasLoading ? (
            <Loading.Header />
          ) : !hasData ? (
            <NoItems />
          ) : (
            <ProductDetailsContext.Provider
              value={{
                product: data,
                setProduct: setData,
                getProduct: getData,
                updateProduct: updateData,
                updateProductLoading: updateProductLoading.get,
                availabilityStatus,
                programAvailabilityStatuses,
                isStandard,
                isBundle,
                canEditPrice,
                hasPrice2,
                hasPrice1,
                isOverwritten,
                brand,
                hasBrand,
                hasAuctions,
              }}
            >
              <ProductPriceProvider>
                <Wrapper>
                  <Wrapper.Body className="space-y-4">
                    <ProductInfo />
                    {/* <WithPermission
                      permissions={["PE_GetSalePriceCalculation"]}
                    >
                      <PriceSection />
                    </WithPermission> */}
                  </Wrapper.Body>
                  <Wrapper.Footer className="py-0">
                    <Tabs.ButtonGroup>
                      {tabs.map((e) => (
                        <Tabs.Button key={e.id} eventKey={e.id}>
                          <Text>{e.label}</Text>
                        </Tabs.Button>
                      ))}
                    </Tabs.ButtonGroup>
                  </Wrapper.Footer>
                </Wrapper>
                {tabs.map((e) => (
                  <Tabs.Item key={e.id} eventKey={e.id}>
                    {createElement(e.component)}
                  </Tabs.Item>
                ))}
                <PrintTemplateDrawer
                  isOpen={shoePrintTemplate}
                  toggle={hidePrintTemplate}
                  onSubmitted={reloadPrintRequests}
                />
              </ProductPriceProvider>
            </ProductDetailsContext.Provider>
          )}
        </Layout.Body>
      </Layout>
    </Tabs>
  );
}
export default withPermission(ProductDetails, [
  "PS_PimProductFullAccess",
  "PS_GetPimProduct",
]);
