import { Fragment } from "react";
import { Status, Text } from "../../../../components";
import { useConverters } from "../../../../hooks";
import { Product } from "../../../../types";

type Props = {
  availabilityStatus: Product.AvailabilityStatusItem;
  // onSubmitted: Dispatch<string>;
  // onRemoved: Dispatch<string>;
};

export default function AvailabilityStatusRow({
  availabilityStatus,
}: // onSubmitted,
// onRemoved,
Props) {
  const { convertDate } = useConverters();
  // const [showUpdateMenu, toggleUpdateMenu, hideUpdateMenu] = useToggle(false);
  // const [showRemoveMenu, toggleRemoveMenu, hideRemoveMenu] = useToggle(false);
  return (
    <Fragment>
      <div className="flex w-full items-center gap-2.5 rounded border p-2.5">
        <Status.Availability id={availabilityStatus.availabilityStatus} />
        <p className="flex-1 text-body-base font-normal text-gray-600">
          {availabilityStatus.isDefault ? (
            <Text>products.details.availabilityStatus.isDefault</Text>
          ) : availabilityStatus.validationPeriod ? (
            <Text
              fromDate={convertDate(availabilityStatus.fromDate) ?? "--"}
              toDate={convertDate(availabilityStatus.toDate) || "--"}
            >
              products.details.availabilityStatus.statusDate
            </Text>
          ) : null}
        </p>
        {/* <IconButton
          type="button"
          variant="primary"
          light
          className="p-2"
          onClick={toggleUpdateMenu}
        >
          <Icon name="Edit2" className="size-5" />
        </IconButton>
        {!availabilityStatus.isDefault && (
          <IconButton
            type="button"
            variant="danger"
            light
            className="p-2"
            onClick={toggleRemoveMenu}
          >
            <Icon name="Trash" variant="Bold" className="size-5" />
          </IconButton>
        )} */}
      </div>
      {/* <AvailabilityStatusForm
        isOpen={showUpdateMenu}
        toggle={hideUpdateMenu}
        availabilityStatus={availabilityStatus}
        onSubmitted={onSubmitted}
      />
      <RemoveConfirm
        isOpen={showRemoveMenu}
        toggle={hideRemoveMenu}
        id={availabilityStatus.id}
        onRemoved={onRemoved}
      /> */}
    </Fragment>
  );
}
