import { useContext } from "react";
import { ProductDetailsContext } from "..";
import { NoItems, Table, Text, Wrapper } from "../../../../components";
import AuctionRow from "./AuctionRow";

export default function Auctions() {
  const { product } = useContext(ProductDetailsContext);
  const hasAuctions = !!product.auctions?.length;
  return (
    <Wrapper>
      <Wrapper.Body>
        {hasAuctions ? (
          <Table.Wrapper>
            <Table>
              <thead>
                <tr>
                  <th className="text-start !ps-[4.5rem]">
                    <Text>products.details.auctions.auctions</Text>
                  </th>
                  <th className="text-center">
                    <Text>products.details.auctions.status</Text>
                  </th>
                  <th className="text-center">
                    <Text>products.details.auctions.activeDate</Text>
                  </th>
                  <th className="!text-center">
                    <Text>products.details.auctions.productInAuctionPrice</Text>
                  </th>
                </tr>
              </thead>
              <Table.Body>
                {product.auctions?.map((e) => (
                  <AuctionRow key={e.auctionId} auction={e} />
                ))}
              </Table.Body>
            </Table>
          </Table.Wrapper>
        ) : (
          <NoItems />
        )}
      </Wrapper.Body>
    </Wrapper>
  );
}
