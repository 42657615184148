import { cloneDeep } from "lodash";
import { useContext, useState } from "react";
import { OrderDetailsContext } from ".";
import { Button, Icon, Modal, Text, Toggler } from "../../../components";
import { useAxios, useToggle } from "../../../hooks";
import { cn } from "../../../methods";

export default function TaxFreeToggler() {
  const orderContext = useContext(OrderDetailsContext);
  const order = orderContext?.order;
  const setOrder = orderContext?.setOrder;
  //   const updateOrder = orderContext?.updateOrder;
  const updateOrderLoading = orderContext?.updateOrderLoading;
  const initTaxFree = !!order?.taxFree;
  const isPublished = !!orderContext?.isPublished;
  const { axios, loading } = useAxios();
  const [taxFree, setTaxFree] = useState(initTaxFree);
  const [showInfo, toggleInfo] = useToggle(false);
  const hasLoading = updateOrderLoading || loading.post;
  const handleChange = (value: boolean) => {
    setTaxFree(value);
    const url = `/salesservice/api/orders/${order?.id}/tax-free`;
    const body = { taxFree: value };
    axios
      .post(url, body)
      .then(() => {
        setOrder?.((p) => {
          if (!p) return p;
          const order = cloneDeep(p);
          order.taxFree = value;
          if (order.version !== null) {
            order.version += 1;
          }
          return order;
        });
        // updateOrder?.();
      })
      .catch(() => {
        setTaxFree(initTaxFree);
      });
  };
  return (
    <section className="flex items-center gap-2">
      <Toggler
        label="formControls.orderTaxFree"
        value={taxFree}
        setValue={handleChange}
        disabled={hasLoading || isPublished}
        className={cn(
          "flex-row-reverse gap-1.5",
          hasLoading && "loading bg-transparent"
        )}
      />
      <button type="button" onClick={toggleInfo}>
        <Icon name="InfoCircle" className="size-4 text-secondary" />
      </button>
      <Modal isOpen={showInfo} toggle={toggleInfo}>
        <Modal.Body>
          <div className="w-full max-h-96 bg-gray-50 text-dark text-base rounded-none overflow-auto px-4 py-5">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem quia
            cumque cupiditate culpa amet exercitationem at, provident vel minus
            ducimus sunt labore accusamus veniam quidem architecto perspiciatis
            ex expedita dolorem!
          </div>
        </Modal.Body>
        <Modal.Footer className="flex-center">
          <Button type="button" light onClick={toggleInfo}>
            <Text>button.back</Text>
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
}
