import { Fragment, ReactNode, useContext } from "react";
import { IncidentDetailsContext } from ".";
import { Icon, Status, Text, Types, Wrapper } from "../../../components";
import { IncidentChangeStatus, IncidentStatusInfo } from "../../../containers";
import { useConverters, usePermission, useToggle } from "../../../hooks";
import { iconNameType } from "../../../types";

type MoreInfoItem = {
  icon: iconNameType;
  label: string;
  value: ReactNode;
};

export default function HeaderInfo() {
  const { incident, getIncident } = useContext(IncidentDetailsContext);
  const { convertDate } = useConverters();
  const canEdit = usePermission("CMS_IncidentFullAccess", "CMS_UpdateIncident");
  const [showChangeStatus, toggleChangeStatus] = useToggle(false);
  const [showStatusInfo, toggleStatusInfo] = useToggle(false);

  const moreInfo: MoreInfoItem[] = [
    {
      icon: "Document",
      label: "incident.details.subjectName",
      value: incident?.subjectName ?? "--",
    },
    {
      icon: "Calendar",
      label: "incident.details.createdAt",
      value: convertDate(incident?.createdAt),
    },
    {
      icon: "LocationTick",
      label: "incident.details.store",
      value: incident?.branchName ?? "--",
    },
  ];
  return (
    <Fragment>
      <Wrapper className="lg:col-span-3">
        <Wrapper.Body className="flex flex-col gap-3 h-full justify-between">
          <section className="flex items-center gap-2">
            <Icon.Wrapper rounded>
              <Icon name="ReceiptText" variant="Bold" />
            </Icon.Wrapper>
            <div className="text-start">
              <h1 className="text-base text-dark">{incident?.title}</h1>
              <p className="text-sm text-secondary">
                #{incident?.ticketNumber}
              </p>
            </div>
          </section>
          <section className="flex flex-wrap items-center gap-5">
            {moreInfo.map((e) => (
              <p key={e.label} className="text-sm">
                <Icon name={e.icon} variant="Bulk" className="text-primary" />{" "}
                <span className="text-placeholder">
                  <Text>{e.label}</Text>:
                </span>{" "}
                <span className="text-secondary">{e.value}</span>
              </p>
            ))}
          </section>
          <section className="flex items-center flex-wrap gap-3">
            <Status.Incident
              status={incident?.status}
              statusReason={incident?.statusReason}
              className="py-1"
              onClick={canEdit ? toggleStatusInfo : undefined}
            />
            <Types.IncidentPriority id={incident?.priority} />
            <Types.IncidentOrigin id={incident?.origin} />
          </section>
        </Wrapper.Body>
      </Wrapper>
      <IncidentStatusInfo
        isOpen={showStatusInfo}
        toggle={toggleStatusInfo}
        incident={incident}
        onChangeStatus={toggleChangeStatus}
      />
      {canEdit && (
        <IncidentChangeStatus
          isOpen={showChangeStatus}
          toggle={toggleChangeStatus}
          incident={incident}
          onSubmitted={getIncident}
        />
      )}
    </Fragment>
  );
}
