import toast from "react-hot-toast";
import { CRMRecordType } from "../enums";
import useAxios from "./useAxios";

type Config = { recordId: string; crmRecordType: CRMRecordType };

export default function useCrmCheckRecordExistence() {
  const { axios, loading } = useAxios();
  const checkRecord = async ({ recordId, crmRecordType }: Config) => {
    const url = "/channelmanagerservice/api/incidents/checkrecordexistance";
    const config = { params: { recordId, crmRecordType } };
    return await axios
      .get(url, config)
      .then(({ data }) => {
        if (data) return Promise.resolve(data);
        const messages = {
          [CRMRecordType.Account]:
            "incident.checkRecordExistenceMessages.account",
          [CRMRecordType.Contact]:
            "incident.checkRecordExistenceMessages.contact",
          [CRMRecordType.SalesOrder]:
            "incident.checkRecordExistenceMessages.salesOrder",
        };
        const message = messages[crmRecordType];
        toast.error(message);
        return Promise.reject(data);
      })
      .catch(() => false);
  };
  return [checkRecord, loading.get] as const;
}
