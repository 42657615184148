import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router";
import { twMerge } from "tailwind-merge";
import { useDefaultSaleChannel } from "../hooks";
import { PermissionProvider, ProfileProvider } from "../providers";
import Menu from "./Menu";

export default function MainLayout() {
  const location = useLocation();
  const saleChannel = useDefaultSaleChannel();
  const hasKey = !!saleChannel?.code;
  const [isActive, setIsActive] = useState(false);
  const [isActiveXl, setIsActiveXl] = useState(true);
  const toggleActive = () => {
    setIsActive((p) => !p);
  };
  const toggleActiveXl = () => {
    setIsActiveXl((p) => !p);
  };
  useEffect(() => {
    setIsActive(false);
  }, [location.pathname]);
  return (
    <ProfileProvider>
      <PermissionProvider>
        <Menu
          isOpen={isActive}
          toggle={toggleActive}
          isOpenXl={isActiveXl}
          toggleXl={toggleActiveXl}
        />
        <main
          className={twMerge(
            "w-full min-h-screen pl-20 transition-[padding-left]",
            isActiveXl && "xl:pl-56"
          )}
        >
          {hasKey && <Outlet />}
        </main>
      </PermissionProvider>
    </ProfileProvider>
  );
}
