import {
  createContext,
  createElement,
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { SupplierDetailsContext } from "..";
import {
  Icon,
  Image,
  List,
  Loading,
  NoItems,
  SearchBox,
  Tabs,
  Text,
  Wrapper,
} from "../../../../components";
import { useAxios, useDefaultSaleChannel } from "../../../../hooks";
import { SupplierProgram } from "../../../../types";
import AvailabilityStatus from "./AvailabilityStatus";
import Configuration from "./Configuration";
import GeneralInfo from "./GeneralInfo";

type Params = SupplierProgram.Params;
type Items = SupplierProgram.Item[];
type Details = SupplierProgram.Details;
type Context = { program: Details | null };

export const ProgramDetailsContext = createContext<Context>({ program: null });

export default function Program() {
  const saleChannel = useDefaultSaleChannel();
  const { supplier } = useContext(SupplierDetailsContext);
  const { axios, loading } = useAxios();
  const { axios: programAxios, loading: programLoading } = useAxios();
  const [keyword, setKeyword] = useState<string | null>(null);
  const [programs, setPrograms] = useState<Items>([]);
  const [activeId, setActiveId] = useState("");
  const [program, setProgram] = useState<Details | null>(null);
  const hasPrograms = !!programs.length;
  const hasProgram = !!program;
  const hasBrand = !!program?.brand;
  const hasBrandLogo = !!program?.brand?.logo;

  const programsLoading = loading.get;
  const programDetailsLoading = programLoading.get;

  const filteredPrograms = useMemo(() => {
    if (!keyword) return programs;
    return programs.filter((e) =>
      [e.title, e.code]
        .map((e) => e.toUpperCase())
        .some((e) => e.includes(keyword.toUpperCase()))
    );
  }, [programs, keyword]);
  const tabs = [
    {
      label: "suppliers.programs.general",
      id: "0",
      component: GeneralInfo,
    },
    {
      label: "suppliers.programs.configuration",
      id: "1",
      component: Configuration,
    },
    {
      label: "suppliers.programs.availability",
      id: "2",
      component: AvailabilityStatus,
    },
  ];
  const getPrograms = () => {
    const supplierId = supplier?.supplierId;
    if (!supplierId) return;
    const url = "/salesservice/api/supplierprograms";
    const params: Params = { supplierId };
    const config = { params };
    setPrograms([]);
    axios.get<Items>(url, config).then(({ data }) => {
      setPrograms(data);
      setActiveId(data[0]?.id ?? "");
    });
  };
  const getProgram = () => {
    const channelCode = saleChannel?.code;
    if (!channelCode) return;
    if (!activeId) return;
    setProgram(null);
    const url = `/salesservice/api/supplierprograms/${activeId}/${channelCode}`;
    programAxios.get(url).then(({ data }) => {
      setProgram(data);
    });
  };
  useEffect(getPrograms, [supplier]);
  useEffect(getProgram, [activeId, saleChannel]);
  return (
    <section className="flex items-start gap-3">
      <Wrapper className="flex-1">
        <Wrapper.Header>
          <h6 className="text-base text-dark font-medium">
            <Text>suppliers.programs.title</Text>
          </h6>
        </Wrapper.Header>
        <Wrapper.Header>
          <SearchBox
            onSubmit={setKeyword}
            placeholder="suppliers.programs.searchBox"
            variant="gray"
          />
        </Wrapper.Header>
        <Wrapper.Body className="space-y-2.5">
          {programsLoading ? (
            <List loading={programsLoading} loadingLength={5} />
          ) : !hasPrograms ? (
            <NoItems />
          ) : (
            <List loading={programsLoading}>
              {filteredPrograms.map((e) => (
                <List.Item key={e.id} onClick={() => setActiveId(e.id)}>
                  <Icon.Wrapper>
                    <Icon
                      name="DocumentText"
                      variant="Bold"
                      className="size-6"
                    />
                  </Icon.Wrapper>
                  <h6 className="flex-1 text-base text-dark font-medium">
                    {e.title}
                  </h6>
                  <Icon.Wrapper
                    rounded
                    data-active={activeId === e.id}
                    className="opacity-0 transition-opacity data-active:opacity-100"
                  >
                    <Icon name="TickCircle" variant="Bold" className="size-5" />
                  </Icon.Wrapper>
                </List.Item>
              ))}
            </List>
          )}
        </Wrapper.Body>
      </Wrapper>
      <Tabs searchParams="program-tab" activeKey={tabs[0].id}>
        <section className="flex-[2] space-y-3">
          {programDetailsLoading ? (
            <Loading.SupplierProgram />
          ) : (
            hasProgram && (
              <Fragment>
                <Wrapper>
                  <Wrapper.Body className="flex items-start gap-4">
                    <div className="flex-1 space-y-px">
                      <h6 className="text-sm text-dark font-medium">
                        {program.title}
                      </h6>
                      <p className="text-xs text-secondary font-medium">
                        #{program.code}
                      </p>
                      <p className="text-xs text-secondary font-medium">
                        <Text version={program?.version ?? ""}>
                          suppliers.programs.version
                        </Text>
                      </p>
                    </div>
                    <div className="space-y-px">
                      {hasBrand && (
                        <p className="flex flex-col items-start text-sm text-gray-900 gap-1">
                          <span>{program?.brand?.name}</span>
                          {hasBrandLogo && (
                            <Image
                              src={program?.brand?.logo?.url}
                              alt={program?.brand?.name}
                              isDynamic
                              className="inline-block rounded h-7"
                            />
                          )}
                        </p>
                      )}
                    </div>
                  </Wrapper.Body>
                  <Wrapper.Footer className="py-0">
                    <Tabs.ButtonGroup>
                      {tabs.map((e) => (
                        <Tabs.Button key={e.id} eventKey={e.id}>
                          <Text>{e.label}</Text>
                        </Tabs.Button>
                      ))}
                    </Tabs.ButtonGroup>
                  </Wrapper.Footer>
                </Wrapper>
                <ProgramDetailsContext.Provider value={{ program }}>
                  {tabs.map((e) => (
                    <Tabs.Item key={e.id} eventKey={e.id}>
                      {createElement(e.component)}
                    </Tabs.Item>
                  ))}
                </ProgramDetailsContext.Provider>
              </Fragment>
            )
          )}
        </section>
      </Tabs>
    </section>
  );
}
