const pickup = {
  list: {
    title: "Pick up Request",
    findOrderTitle: "Find order",
    findOrderSearch: "Search order number, customer name, customer ID...",
    searchButton: "Search",
    publishedAt: "Published at",
    lastPublishedVersion: "Last published version",
    detailsButton: "Details",
  },
  details: {
    selectItemsToPickupButton: "Select items to pickup",
    submitRequestPickupButton: "Request to pickup selected items",
    cancelRequestPickupButton: "Cancel",
    lastUpdatedAt: "Last update at",
    lastUpdatedByName: "Last updated by",
    version: "Version",
    channelName: "Channel",
    totalPrice: "Total price",
    totalUpFrontPayment: "Total Upfront payment",
    deadline: "Deadline",
    noteButton: "Notes",
    lineItems: {
      lineItems: "Line items",
      quantity: "Quantity/Delivery",
      unitPrice: "Unit price",
      lineItemPrice: "Line item price",
      action: "Action",
      totalPrice: "Total price",
      vk2: "VK2",
      vk1: "VK1",
      requestToPickupButton: "Request to pickup",
      cancelProcessButton: "Cancel process",
      resentRequestButton: "Resend request",
      pickedUpByCustomer: "Picked up by customer",
      pickedUpAt: "At {{ date }}",
      canceledAt: "Canceled at {{ date }}",
      selectButton: "Select",
      unSelectButton: "Un select",
    },
  },
  orderNote: {
    title: "Order notes",
    deliveryDate: "Delivery date",
    deliveryDateValue: "KW {{ kw }}",
    orderNote: "Order note",
    customerNote: "Customer note",
  },
  lineItemNote: {
    title: "Line item notes",
    lineItemNote: "Line item note",
    customerNote: "Customer note",
    supplierNote: "Supplier note",
  },
  pickupConfirm: {
    title: "Pickup Confirmation",
    text: "Are you sure that customer wants to pickup this line item?",
    deliveryDateAlert: "This line item already has a desire delivery date",
    cancelButton: "Cancel",
    submitButton: "Request to pickup",
  },
  pickupInfo: {
    title: "Picked up info",
    date: "Picked up date",
    by: "Picked up by",
    closeButton: "Close",
  },
  submitRequestToPickup: {
    title: "Submit request",
    cancelButton: "Cancel",
    submitButton: "Request to pickup",
  },
};
export default pickup;
