const company = {
  selectAccountType: {
    title: "Kontotyp auswählen",
    companyLabel: "Einzelkunde",
    companyDescription: "Privatkonto für Kunden",
    personLabel: "Firmenkunde",
    personDescription: "Firmenkonto erstellen",
  },
  create: {
    title: "Neuer Firmenkunde",
    tab: {
      basicInfo: "Firmeninformationen",
      basicInfoDescription: "Name & ...",
      contactInfo: "Kontaktinformationen",
      contactInfoDescription: "E-Mail & Nummer",
      location: "Standort",
      locationDescription: "Adresse & ...",
      completed: "Abgeschlossen",
      completedDescription: "Endlich sind wir fertig! Los gehts...",
    },
    cancelBtn: "Abbrechen",
    backBtn: "Zurück",
    submitBtn: "Firma registrieren",
    nextBtn: "Weiter",
    successMessage: "Firma erfolgreich hinzugefügt",
    basicInfo: {
      companyName: "Firmenname",
      companyRegistrationNumber: "Registrierungshinweis (Handelsregister etc.)",
      eligibleForTaxDeduction: "Vorsteuer-Abzugsberechtigt",
      taxId: "Steuernummer",
    },
    contactInfo: {
      firstName: "Vorname",
      firstNamePlaceholder: "Vorname",
      lastName: "Nachname",
      lastNamePlaceholder: "Nachname",
      gender: "Geschlecht",
      genderPlaceholder: "Geschlecht",
      salutation: "Anrede",
      salutationPlaceholder: "Auswählen ...",
      salutationTitle: "Titel",
      salutationTitlePlaceholder: "Auswählen ...",
      emailAddress: "E-Mail-Adresse",
      emailAddressPlaceholder: "beispiel@test.com",
      position: "Position / Bezeichnung",
      positionPlaceholder: "Position / Bezeichnung ",
      mobileNumber: "Handynummer",
      mobileNumberPlaceholder: "111 22 33 444",
      phoneNumber: "Telefonnummer",
      phoneNumberPlaceholder: "111 22 33 444",
    },
    completed: {
      taxId: "Steuernummer",
      companyRegistrationNumber: "Handelsregister",
    },
    termsConditions: {
      title: "Allgemeine Geschäftsbedingungen",
      gdprAccepted: "DSGVO-Hinweise akzeptiert",
      companyRegistrationNumberApproved: "Handelsregister bestätigt",
      personPowerOfAttorneyApproved: "Vollmacht bestätigt",
      cancelBtn: "Abbrechen",
      submitBtn: "Firma registrieren",
    },
  },
  details: {
    cimNumber: "CIM-Nummer",
    taxId: "Steuernummer",
    companyRegistrationNumber: "Handelsregister",
    contactPersonTitle: "Ansprechpartner",
    tabs: {
      overview: "Übersicht",
      contactPerson: "Ansprechpartner",
      customerCommunicationChannels: "Kommunikationskanäle",
      offers: "Angebote",
      orders: "Bestellungen",
      incidents: "Vorfälle",
    },
    changeStatus: {
      changeBtn: "Ändern",
      title: "Allgemeine Geschäftsbedingungen",
      gdprAccepted: "DSGVO-Hinweise akzeptiert",
      companyRegistrationNumberApproved: "Handelsregister bestätigt",
      personPowerOfAttorneyApproved: "Vollmacht bestätigt",
      cancelBtn: "Abbrechen",
      submitBtn: "Firma registrieren",
      successMessage: "Firmenstatus erfolgreich aktualisiert.",
    },
    overview: {
      submitText: "Bitte Änderungen einreichen",
      submitBtn: "Änderungen einreichen",
      companyName: "Firmenname",
      eligibleForTaxDeduction: "Vorsteuer-Abzugsberechtigt",
      taxId: "Steuernummer",
      taxIdDescription: `You can find a customer's VAT ID on their business documents such as invoices, letterheads or offers, or you can ask the customer directly. Make sure that you enter the VAT ID completely and correctly into the system with the country code (e.g. "DE" for Germany + 9 digits). If any information is missing or if there are any uncertainties, please contact your supervisor or the accounting department. DE`,
      companyRegistrationNumber: "Handelsregister",
      emailAddress: "E-Mail-Adresse",
      countryCode: "Ländercode",
      mobileNumber: "Handynummer",
      phoneNumber: "Telefonnummer",
      editMessage: "Firma erfolgreich bearbeitet.",
    },
    contactPerson: {
      defaultPersonTitle: "Hauptkontakt",
      editBtn: "Bearbeiten",
      contactPersonsTitle: "Ansprechpartner",
      addPersonBtn: "Ansprechpartner hinzufügen",
      viewDetailsBtn: "Details anzeigen",
      position: "Postion / Bezeichnung",
      primary: "Primary DE",
      actions: {
        edit: "Edit contact person DE",
        changeToPrimary: "Change to primary DE",
        remove: "Remove contact person DE",
      },
      form: {
        formTitle: "Ansprechpartner hinzufügen",
        firstName: "Vorname",
        firstNamePlaceholder: "Vorname",
        lastName: "Nachname",
        lastNamePlaceholder: "Nachname",
        gender: "Geschlecht",
        genderPlaceholder: "Geschlecht",
        salutation: "Anrede",
        salutationPlaceholder: "Auswählen ...",
        salutationTitle: "Titel",
        salutationTitlePlaceholder: "Auswählen ...",
        emailAddress: "E-Mail-Adresse",
        emailAddressPlaceholder: "beispiel@test.com",
        position: "Position DE",
        positionPlaceholder: "Position, Rolle im Unternehmen",
        countryCode: "Ländercode",
        mobileNumber: "Handynummer",
        mobileNumberPlaceholder: "111 22 33 444",
        phoneNumber: "Telefonnummer",
        phoneNumberPlaceholder: "111 22 33 444",
        editDefaultPersonMessage:
          "Hauptkontakt wurde erfolgreich aktualisiert.",
        editPersonMessage: "Person erfolgreich aktualisiert.",
        addPersonMessage: "Person erfolgreich hinzugefügt.",
        cancelBtn: "Abbrechen",
        submitPerson: "Person registrieren",
        submitChanges: "Änderungen einreichen",
      },
      changeToPrimary: {
        title: "Change to primary contact person DE",
        text: "Are you sure you want to change this contact person to primary? DE",
        cancelBtn: "Cancel DE",
        submit: "Submit change DE",
        submitMessage: "Contact person has been changed successfully. DE",
      },
      remove: {
        title: "Ansprechpartner entfernen",
        text: "Sind Sie sicher, dass Sie diesen Ansprechpartner entfernen möchten?",
        cancelBtn: "Abbrechen",
        removeBtn: "Entfernen",
        removeMessage: "Ansprechpartner erfolgreich gelöscht.",
      },
    },
    communicationChannels: {
      phone: "Telefon",
      application: "In-App-Benachrichtigung",
      socialMedia: "WhatsApp",
      email: "E-Mail",
      sms: "SMS",
      post: "Post",
      newsletter: "Newsletter",
      bulkEmail: "E-Mail Verteiler",
    },
    offer: {
      form: {
        contactPerson: "Ansprechpartner",
        contactPersonPlaceholder: "Auswählen ...",
        changeBtn: "Ändern",
        selectContactPersonTitle: "Firmenansprechpartner auswählen",
        chooseContactPersonBtn: "Auswählen",
      },
    },
  },
};
export default company;
