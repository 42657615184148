import {
  createContext,
  Dispatch,
  Fragment,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { CompanyContext } from "..";
import { Button, Icon, Table, Text, Wrapper } from "../../../../components";
import { useAxios, useToggle } from "../../../../hooks";
import { Company } from "../../../../types";
import ContactPersonForm from "./ContactPersonForm";
import PersonRow from "./PersonRow";

type Context = {
  persons: Company.Person[];
  setPersons: Dispatch<SetStateAction<Company.Person[]>>;
  getPersons: () => void;
};
export const ContactPersonsContext = createContext({} as Context);

export default function ContactPerson() {
  const { axios, loading } = useAxios();
  const { companyData } = useContext(CompanyContext);
  const [persons, setPersons] = useState<Company.Person[]>([]);
  const [showAddPerson, toggleAddPerson] = useToggle(false);
  const sortedPersons = persons.sort((a) => (a.isPrimary ? -1 : 1));
  const getPersons = () => {
    const url = `/accountservice/api/customers/${companyData.id}/as-company/company-contact-persons`;
    axios.get(url).then(({ data }) => {
      setPersons(data);
    });
  };
  useEffect(getPersons, []);
  return (
    <Fragment>
      {/* <Wrapper>
        <Wrapper.Header>
          <h6 className="text-lg text-dark">
            <Text>company.details.contactPerson.defaultPersonTitle</Text>
          </h6>
        </Wrapper.Header>
        <Wrapper.Body className="space-y-4">
          <section className="flex items-start gap-2">
            <span className="leading-6">
              <Icon name="ProfileCircle" className="text-primary" />
            </span>
            <div>
              <h6 className="text-base text-dark">
                {primaryContactPerson?.firstName}{" "}
                {primaryContactPerson?.lastName}
              </h6>
              <p className="text-secondary text-sm">
                {primaryContactPerson?.emailAddress} |{" "}
                {showCallNumber(primaryContactPerson?.mobileNumber)}
              </p>
              {hasPosition && (
                <p className="text-secondary text-sm">
                  <Text>company.details.contactPerson.position</Text>:{" "}
                  {primaryContactPerson?.position}
                </p>
              )}
            </div>
          </section>
          <Button
            type="button"
            variant="primary"
            light
            onClick={toggleEditDefaultPerson}
          >
            <Icon name="Edit2" />{" "}
            <Text>company.details.contactPerson.editBtn</Text>
          </Button>
        </Wrapper.Body>
      </Wrapper> */}
      <section className="flex items-center justify-between">
        <h6 className="text-base text-dark">
          <Text>company.details.contactPerson.contactPersonsTitle</Text>
        </h6>
        <Button type="button" variant="primary" onClick={toggleAddPerson}>
          <Icon name="Add" />{" "}
          <Text>company.details.contactPerson.addPersonBtn</Text>
        </Button>
      </section>
      <ContactPersonsContext.Provider
        value={{ persons, setPersons, getPersons }}
      >
        <Wrapper>
          <Wrapper.Body>
            <Table.Wrapper>
              <Table>
                <Table.Body loading={loading.get}>
                  {sortedPersons.map((e) => (
                    <PersonRow key={e.id} person={e} onSubmitted={getPersons} />
                  ))}
                </Table.Body>
              </Table>
            </Table.Wrapper>
          </Wrapper.Body>
        </Wrapper>
        <ContactPersonForm isOpen={showAddPerson} toggle={toggleAddPerson} />
      </ContactPersonsContext.Provider>
    </Fragment>
  );
}
