import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";
import Text from "./_Text";

type RadioButtonProps = ComponentProps<"button"> & {
  isActive?: boolean;
  label: string;
  textProps?: Omit<ComponentProps<typeof Text>, "children">;
};
export default function RadioButton({
  onClick,
  className,
  isActive,
  label,
  type = "button",
  textProps = {},
  children,
  ...props
}: RadioButtonProps) {
  return (
    <button
      type={type}
      onClick={onClick}
      className={twMerge("w-full flex items-center py-3 gap-2", className)}
      {...props}
    >
      <div
        data-active={isActive}
        className="relative size-5 rounded-full bg-gray-100 border border-gray-100 data-active:border-primary transition-colors"
      >
        <span
          data-active={isActive}
          className="absolute inset-1 bg-primary rounded-full scale-0 data-active:scale-100 transition-transform"
        ></span>
      </div>
      <span className="flex-1 text-start truncate">
        <Text {...textProps}>{label}</Text>
      </span>
      {children}
    </button>
  );
}
