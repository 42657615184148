import { useMemo } from "react";
import { Badge, Icon, Text } from "../../../../components";
import { useConverters } from "../../../../hooks";
import { isActiveAuction } from "../../../../methods";
import { PimProduct } from "../../../../types";

type Props = {
  auction: PimProduct.AuctionItem;
};
export default function AuctionRow({ auction }: Props) {
  const { convertDate, convertAmount } = useConverters();
  const isActive = useMemo(() => {
    return isActiveAuction(auction);
  }, [auction]);
  return (
    <tr>
      <td>
        <div className="flex items-center gap-4">
          <Icon.Wrapper>
            <Icon name="ReceiptDisscount" className="!size-6" />
          </Icon.Wrapper>
          <h6 className="text-dark text-base font-medium">{auction.title}</h6>
        </div>
      </td>
      <td className="text-center">
        {isActive ? (
          <Badge variant="success" className="text-xs font-bold">
            <Text>products.details.auctions.active</Text>
          </Badge>
        ) : (
          <Badge
            variant="secondary"
            className="bg-gray-50 text-[#A1A4AC] bg-opacity-100 text-xs font-bold"
          >
            <Text>products.details.auctions.inactive</Text>
          </Badge>
        )}
      </td>
      <td>
        <div className="w-fit flex flex-col items-stretch mx-auto gap-0.5">
          <p className="text-gray-900 text-xs font-medium">
            <Text date={convertDate(auction.startDate) ?? ""}>
              products.details.auctions.fromDate
            </Text>
          </p>
          <p className="text-gray-900 text-xs font-medium">
            <Text date={convertDate(auction.endDate) ?? ""}>
              products.details.auctions.toDate
            </Text>
          </p>
        </div>
      </td>
      <td className="!text-center">
        <p className="text-dark text-sm font-medium">
          {convertAmount(auction.price?.amount)}
        </p>
      </td>
    </tr>
  );
}
