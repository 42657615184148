import { cloneDeep } from "lodash";
import {
  Fragment,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import toast from "react-hot-toast";
import { SearchProductContext } from ".";
import { LineItemsContext } from "..";
import { OfferDetailsContext } from "../..";
import {
  Button,
  CheckBox,
  Counter,
  Drawer,
  Form,
  Icon,
  Image,
  InputGroup,
  List,
  Loading,
  RadioButton,
  Text,
  Types,
  Wrapper,
} from "../../../../../components";
import { pimProductAttributeKey, rules } from "../../../../../constants";
import {
  AmountCalculationType,
  LineItemType,
  PimProductType,
} from "../../../../../enums";
import {
  useAxios,
  useConverters,
  useFindAttribute,
  useLanguage,
  useToggle,
} from "../../../../../hooks";
import { cleanText, isActiveAuction } from "../../../../../methods";
import { LineItem, PimProduct, iconNameType } from "../../../../../types";
import { OrderDetailsContext } from "../../../../Orders/Details";
import { noteSuggestion } from "../notes";

type ListItem = {
  label: string;
  icon: iconNameType;
  value: ReactNode;
};
export default function ManualForm() {
  const [lng] = useLanguage();
  const findAttribute = useFindAttribute();
  const formRef = useRef<HTMLFormElement>(null);
  const { convertAmount } = useConverters();
  const offerContext = useContext(OfferDetailsContext);
  const orderContext = useContext(OrderDetailsContext);
  const { updateLineItems, updateLineItemsLoading } =
    useContext(LineItemsContext);
  const { parentId, productId, clearProduct, toggle, pimProductType } =
    useContext(SearchProductContext);
  const { axios, loading } = useAxios();
  const [data, setData] = useState<LineItem.Create | null>(null);
  const [pimProduct, setPimProduct] = useState<PimProduct.Details | null>(null);
  const [useAuctions, toggleUseAuctions] = useToggle(false);
  const [showCommission, toggleCommission] = useToggle(false);

  const offer = offerContext?.offer;
  const order = orderContext?.order;

  const updateOffer = offerContext?.updateOffer;
  const updateOrder = orderContext?.updateOrder;
  const updateOfferLoading = offerContext?.updateOfferLoading;
  const updateOrderLoading = orderContext?.updateOrderLoading;

  const isOffer = !!offer;
  const isOrder = !!order;
  const auctions = pimProduct?.auctions?.filter(isActiveAuction);
  const hasAuctions = !!auctions?.length;

  const brand = pimProduct?.supplier?.program?.brand;
  const hasBrand = !!brand;
  const hasBrandLogo = !!brand?.logo;
  const pimProductGroup = pimProduct?.pimProductGroup;
  const hasProductGroup = !!pimProductGroup;
  const salesCommission = pimProduct?.salesPersonCommission;
  const hasSalesCommission = salesCommission;
  const salesPersonCommissionValue = useMemo(() => {
    const isSalesCommissionPercent =
      salesCommission?.calculationType === AmountCalculationType.Percent;
    if (!showCommission) return "*****";
    if (isSalesCommissionPercent) return `${salesCommission.amount}%`;
    return convertAmount(salesCommission?.amount);
  }, [showCommission, salesCommission, convertAmount]);
  const lineItemType =
    pimProductType === PimProductType.Bundle
      ? LineItemType.PimBundle
      : LineItemType.PimProduct;

  const hasLoading = [
    loading.post,
    updateOfferLoading,
    updateOrderLoading,
    updateLineItemsLoading,
  ].some(Boolean);
  const infoItems: ListItem[] = [
    {
      label: "global.articleNumber",
      icon: "Hashtag",
      value: "#" + pimProduct?.articleNumber,
    },
    {
      label: "global.supplierName",
      icon: "Building",
      value: pimProduct?.supplier?.name,
    },
    {
      label: "global.supplierArticleNumber",
      icon: "Building",
      value: pimProduct?.supplierArticleNumber ?? "--",
    },
    {
      label: "global.programName",
      icon: "Document",
      value: pimProduct?.supplier?.program?.name,
    },
    {
      label: "global.brandName",
      icon: "TableDocument",
      value: hasBrand ? (
        <div className="flex flex-col items-end gap-1">
          <span>{brand?.name}</span>
          {hasBrandLogo && (
            <Image
              src={brand?.logo?.url}
              alt={brand?.name}
              isDynamic
              className="inline-block rounded h-7"
            />
          )}
        </div>
      ) : (
        "--"
      ),
    },
    {
      label: "global.lineItemProductGroupTitle",
      icon: "TableDocument",
      value: hasProductGroup
        ? `${pimProductGroup.name} (#${pimProductGroup.code})`
        : "--",
    },
    {
      label: "global.lineItemSalesPersonCommissionTitle",
      icon: "DollarCircle",
      value: hasSalesCommission ? (
        <button
          type="button"
          onClick={toggleCommission}
          className="w-fit min-w-16 flex items-center justify-between gap-1 text-end ms-auto"
        >
          <Icon
            name={showCommission ? "Eye" : "EyeSlash"}
            className="text-primary size-5"
          />
          {salesPersonCommissionValue}
        </button>
      ) : (
        "--"
      ),
    },
  ];
  const priceItems: ListItem[] = [
    // {
    //   label: "global.lineItemProductTitle",
    //   icon: "Hashtag",
    //   value: data?.product?.title ?? "--",
    // },

    {
      label: "global.lineItemNormalPrice",
      icon: "DollarSquare",
      value: convertAmount(
        pimProduct?.uvpPrice?.amount ?? pimProduct?.vk2Price?.amount
      ),
    },
    {
      label: "global.lineItemSalesPrice",
      icon: "DollarSquare",
      value: convertAmount(pimProduct?.vk1Price?.amount),
    },
  ];
  const getPimProduct = async (id: string) => {
    const url = `/salesservice/api/salestoolspimproduct/${id}`;
    return await axios
      .get(url)
      .then((res) => res.data as PimProduct.Details)
      .catch(() => null);
  };
  const handleActiveAction = (auctionId: string) => {
    if (!useAuctions) return false;
    return data?.product.auction?.auctionId === auctionId;
  };
  const handleSetAuction = (auctionId: string) => {
    return () => {
      const auction = pimProduct?.auctions?.find(
        (e) => e.auctionId === auctionId
      );
      if (!auction) return;
      setData((p) => {
        if (!p) return p;
        const data = cloneDeep(p);
        data.product.auction = auction;
        return data;
      });
    };
  };
  const findTranslate = (
    e: PimProduct.Details | null,
    code: keyof typeof pimProductAttributeKey
  ) => {
    const translates = findAttribute(e?.attributeGroups, code);
    const translate = translates?.find(
      (e) => e.language === lng || !e.language
    );
    // return cleanText(translate?.labelTitle || translates?.[0]?.labelTitle) || ""
    return cleanText(translate?.labelTitle) || "";
  };
  const getInitData = () => {
    getPimProduct(productId).then((pimProduct) => {
      if (!pimProduct) return;
      const supplier = pimProduct.supplier ?? null;
      const hasSupplier = !!supplier;
      const program = pimProduct.supplier?.program ?? null;
      const hasProgram = !!program;
      const productGroup = pimProduct.pimProductGroup ?? null;
      const hasProductGroup = !!productGroup;
      const initData: LineItem.Create = {
        parentId: parentId,
        offerId: offer?.id,
        orderId: order?.id,
        title: findTranslate(pimProduct, "title") || pimProduct.originalName,
        description: findTranslate(pimProduct, "descriptions"),
        product: {
          title: findTranslate(pimProduct, "title") || pimProduct.originalName,
          originalName: pimProduct.originalName,
          articleNumber: pimProduct.articleNumber,
          shortDescription: findTranslate(pimProduct, "shortDescription"),
          pimProductId: pimProduct.id,
          pimProductSalesPersonCommission: pimProduct.salesPersonCommission,
          auction: null,
        },
        productGroup: hasProductGroup
          ? {
              code: productGroup?.code ?? null,
              title: productGroup?.name ?? null,
              pimProductGroupId: productGroup?.pimProductGroupId ?? null,
            }
          : null,
        supplier: hasSupplier
          ? {
              code: supplier?.code ?? null,
              iln: supplier?.iln ?? null,
              name: supplier?.name ?? null,
              supplierArticleNumber: pimProduct.supplierArticleNumber ?? null,
              supplierId: supplier?.supplierId ?? null,
            }
          : null,
        supplierProgram: hasProgram
          ? {
              code: program?.programCode ?? null,
              iwofurnCode: program?.iwofurnCode ?? null,
              name: program?.name ?? null,
              supplierProgramId: program?.supplierProgramId ?? null,
              zrNumber: program?.zrNumber ?? null,
            }
          : null,
        lineItemNote: null,
        supplierNote: null,
        customerNote: null,
        ean: null,
        quantity: 1,
        shippingMethodCode: null,
        lineItemType: lineItemType,
      };
      setPimProduct(pimProduct);
      setData(initData);
    });
  };
  const handleSetValue = (key: keyof LineItem.Create) => {
    return (value: unknown) => {
      setData((p) => ({ ...p!, [key]: value }));
    };
  };
  const handleSubmit = () => {
    formRef.current?.requestSubmit();
  };
  const submit = () => {
    if (!data) return;
    const url = [
      "/salesservice",
      "api",
      isOffer && "offerlineitem",
      isOrder && "orderlineitems",
      pimProductType === PimProductType.Standard && "pim-product",
      pimProductType === PimProductType.Bundle && "pim-bundle-product",
    ]
      .filter(Boolean)
      .join("/");

    const body = cloneDeep(data);
    body.description ||= null;
    body.lineItemNote ||= null;
    body.customerNote ||= null;
    body.supplierNote ||= null;
    if (!useAuctions) {
      body.product.auction = null;
    }
    axios.post(url, body).then(() => {
      (updateOffer ?? updateOrder)?.().then(() => {
        updateLineItems().then(() => {
          toast.success("toast.success.addLineItem");
          toggle();
        });
      });
    });
  };
  useEffect(getInitData, []);
  return (
    <Fragment>
      <Drawer.Header className="flex items-center text-start gap-4 border-b-0">
        <h6 className="flex-1 text-dark text-base">
          <Text>drawerTitles.addProduct</Text>
        </h6>
        <button type="button" className="text-warning" onClick={clearProduct}>
          <Icon name="ArrowForward" variant="Bold" /> <Text>button.change</Text>
        </button>
      </Drawer.Header>
      <Drawer.Body>
        {loading.get ? (
          <Loading.PimProductDetails />
        ) : (
          <Form ref={formRef} onSubmit={submit} className="space-y-5">
            <Wrapper>
              <Wrapper.Body className="flex items-center gap-4 pb-4">
                <Image
                  src={pimProduct?.avatar?.thumbnailUrl}
                  alt={pimProduct?.originalName}
                  className="w-32 h-20 object-cover rounded shadow"
                  isDynamic
                />
                <div className="flex-1 space-y-0.5">
                  <h6 className="block truncate text-dark text-base text-justify">
                    {pimProduct?.originalName}
                  </h6>
                  {/* <p className="block truncate text-secondary text-sm">
                    <Text>global.articleNumber</Text>: #
                    {pimProduct?.articleNumber}
                  </p> */}
                  <Types.LineItem id={lineItemType} />
                </div>
              </Wrapper.Body>
            </Wrapper>
            <Wrapper>
              <Wrapper.Header>
                <h6 className="text-sm font-medium text-dark">
                  <Text>global.lineItemInfoTitle</Text>
                </h6>
              </Wrapper.Header>
              <Wrapper.Body>
                <table className="w-full text-sm font-medium [&_td]:py-3">
                  <tbody className="divide-y divide-dashed">
                    {infoItems.map((e) => (
                      <tr key={e.label}>
                        <td className="text-secondary text-start align-top">
                          <Icon name={e.icon} variant="Bold" />{" "}
                          <Text>{e.label}</Text>
                        </td>
                        <td className="text-gray-900 text-end">{e.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Wrapper.Body>
            </Wrapper>
            <Wrapper>
              <Wrapper.Header>
                <h6 className="text-sm font-medium text-dark">
                  <Text>global.lineItemPriceTitle</Text>
                </h6>
              </Wrapper.Header>
              <Wrapper.Body className="space-y-2">
                <table className="w-full text-sm font-medium [&_td]:py-3">
                  <tbody className="divide-y divide-dashed">
                    {priceItems.map((e) => (
                      <tr key={e.label}>
                        <td className="text-secondary text-start">
                          <Icon name={e.icon} variant="Bold" />{" "}
                          <Text>{e.label}</Text>
                        </td>
                        <td className="text-gray-900 text-end">{e.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {hasAuctions && (
                  <Fragment>
                    <CheckBox
                      label="button.useAuctions"
                      value={useAuctions}
                      setValue={toggleUseAuctions}
                    />
                    <List>
                      {auctions.map((e) => (
                        <List.Item key={e.auctionId} className="py-0">
                          <RadioButton
                            label={e.title}
                            disabled={!useAuctions}
                            isActive={handleActiveAction(e.auctionId)}
                            onClick={handleSetAuction(e.auctionId)}
                          >
                            <span className="text-sm font-medium text-gray-900">
                              {convertAmount(e.price?.amount)}
                            </span>
                          </RadioButton>
                        </List.Item>
                      ))}
                    </List>
                  </Fragment>
                )}
              </Wrapper.Body>
            </Wrapper>
            <Wrapper>
              <Wrapper.Body className="space-y-5">
                <InputGroup
                  label="formControls.lineItemTitle"
                  value={data?.title}
                  setValue={handleSetValue("title")}
                  rules={rules.required}
                  className="w-auto flex-1"
                />
                <div className="grid grid-cols-3">
                  <Counter
                    label="formControls.quantity"
                    value={data?.quantity}
                    setValue={handleSetValue("quantity")}
                    className="w-auto basis-1/3"
                    type="decimal"
                    min={0}
                    rules={rules.quantity}
                  />
                </div>
                <InputGroup
                  as="textarea"
                  label="formControls.description"
                  value={data?.description}
                  setValue={handleSetValue("description")}
                  suggestionData={noteSuggestion}
                  snippet
                />
                <InputGroup
                  as="textarea"
                  label="formControls.lineItemNote"
                  value={data?.lineItemNote}
                  setValue={handleSetValue("lineItemNote")}
                  suggestionData={noteSuggestion}
                  snippet
                />
                <InputGroup
                  as="textarea"
                  label="formControls.lineItemCustomerNote"
                  value={data?.customerNote}
                  setValue={handleSetValue("customerNote")}
                  suggestionData={noteSuggestion}
                  snippet
                />
                <InputGroup
                  as="textarea"
                  label="formControls.lineItemSupplierNote"
                  value={data?.supplierNote}
                  setValue={handleSetValue("supplierNote")}
                  suggestionData={noteSuggestion}
                  snippet
                />
                <button type="submit" hidden disabled={hasLoading} />
              </Wrapper.Body>
            </Wrapper>
          </Form>
        )}
      </Drawer.Body>
      <Drawer.Footer className="flex items-center justify-end gap-4">
        <Button type="button" variant="danger" onClick={clearProduct}>
          <Text>button.cancel</Text>
        </Button>
        <Button
          type="button"
          variant="primary"
          disabled={loading.get}
          loading={hasLoading}
          onClick={handleSubmit}
        >
          <Text>button.addProduct</Text>
        </Button>
      </Drawer.Footer>
    </Fragment>
  );
}
