import { Fragment, ReactNode, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import noItemsIcon from "../assets/icons/no items.svg";
import { Button, Icon, Image, Layout, Text, Wrapper } from "../components";
import { useAxios, useGetProfile, useLogout, useSelector } from "../hooks";
import { isEmptyValue } from "../methods";
import { setPermissions } from "../redux/permissions";

type props = { children: ReactNode };
export default function ProfileProvider({ children }: props) {
  const logout = useLogout();
  const [getProfile, profileLoading] = useGetProfile();
  const dispatch = useDispatch();
  const { axios, loading } = useAxios();

  const profile = useSelector((s) => s.profile);
  const permissions = useSelector((s) => s.permissions);

  const hasProfile = !isEmptyValue(profile) && !!permissions.length;
  const hasLoading = loading.get || profileLoading;

  const getPermissions = useCallback(() => {
    const url = "/accountservice/api/users/permissions";
    axios.get(url).then(({ data }) => {
      dispatch(setPermissions(data.permissions ?? []));
    });
  }, []);

  useEffect(() => {
    getPermissions();
    getProfile();
  }, [getProfile, getPermissions]);

  if (hasLoading)
    return (
      <Layout>
        <Layout.Header></Layout.Header>
        <Layout.Body className="py-10">
          <Wrapper className="max-w-screen-sm mx-auto">
            <Wrapper.Body className="text-center space-y-4">
              <Icon name="Profile" size={150} />
              <div className="w-full h-2 bg-gray-300 rounded-full overflow-hidden max-w-sm mx-auto">
                <div className="h-full bg-dark transition-all rounded-full min-w-[5%] max-w-[90%] animate-progress" />
              </div>
            </Wrapper.Body>
          </Wrapper>
        </Layout.Body>
      </Layout>
    );
  if (!hasProfile)
    return (
      <Layout className="flex flex-col">
        <Layout.Header></Layout.Header>
        <Layout.Body className="flex-1 flex flex-col">
          <Wrapper className="max-w-screen-sm m-auto">
            <Wrapper.Body className="text-center space-y-4">
              <Image
                className="block w-52 mx-auto max-w-full"
                src={noItemsIcon}
                alt="No item found"
              />
              <p className="text-4xl text-[#B5B5C3] font-semibold">
                <Text>global.userNotFoundTitle</Text>
              </p>
              <p className="text-base text-[#B5B5C3] font-medium">
                <Text>global.userNotFoundText</Text>
              </p>
              <Button type="button" onClick={logout}>
                <Text>global.logout</Text>
              </Button>
            </Wrapper.Body>
          </Wrapper>
        </Layout.Body>
      </Layout>
    );
  return <Fragment>{children}</Fragment>;
}
