import { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { OrderDetailsContext } from "..";
import {
  Button,
  Icon,
  Pagination,
  Table,
  Text,
  WithPermission,
  Wrapper,
} from "../../../../components";
import { config } from "../../../../constants";
import { CRMRecordType, CustomerType } from "../../../../enums";
import { withPermission } from "../../../../hoc";
import { useAxios, useCrmCheckRecordExistence } from "../../../../hooks";
import { Incident, WithPaging } from "../../../../types";
import IncidentRow from "./IncidentRow";

function Incidents() {
  const navigate = useNavigate();
  const [checkRecordExistence, isChecking] = useCrmCheckRecordExistence();
  const orderContext = useContext(OrderDetailsContext);
  const order = orderContext?.order;
  const isPublished = orderContext?.isPublished;
  const customer = orderContext?.customer;
  const hasCustomer = !!customer;
  const { axios, loading } = useAxios();
  const [data, setData] = useState<WithPaging<Incident.Item>>({});
  const [params, setParams] = useState<Incident.Params>({
    pageNumber: 1,
    pageSize: config.pageSize,
    orderId: order?.id,
  });
  const handleGoToCreateIncident = async () => {
    if (!customer?.id || !order?.id) return;
    const isCompany = customer.customerType === CustomerType.Company;
    const customerExistFetch = checkRecordExistence({
      recordId: customer.id,
      crmRecordType: isCompany ? CRMRecordType.Account : CRMRecordType.Contact,
    });
    const orderExistFetch = checkRecordExistence({
      recordId: order?.id,
      crmRecordType: CRMRecordType.SalesOrder,
    });
    const [customerExist, orderExist] = await Promise.all([
      customerExistFetch,
      orderExistFetch,
    ]);
    if (!customerExist || !orderExist) return;
    navigate("/incidents/new", { state: { order, customer } });
  };
  const handleSetParams = (key: keyof Incident.Params) => {
    const isPageNumber = key === "pageNumber";
    return (val: any) => {
      setParams((p) => ({
        ...p,
        [key]: val,
        ...(!isPageNumber && { pageNumber: 1 }),
      }));
    };
  };
  const getData = () => {
    const url = "/channelmanagerservice/api/incidents";
    const config = { params };
    axios.get(url, config).then(({ data }) => {
      setData(data);
    });
  };
  useEffect(getData, [params]);
  return (
    <Fragment>
      {isPublished && hasCustomer && (
        <WithPermission
          permissions={["CMS_IncidentFullAccess", "CMS_CreateIncident"]}
        >
          <section className="flex items-center gap-4">
            {/* <SearchBox
            placeholder="incident.list.searchPlaceholder"
            onSubmit={console.log}
            className="w-auto flex-1"
          />
          <Button type="button" variant="primary" light>
            <Icon name="Scan" variant="Bold" size="1.25rem" />
          </Button>
          <Button type="button" variant="primary" light>
            <Icon name="Sort" size="1.25rem" />
          </Button>
          <Button type="button" variant="primary" light>
            <Icon name="Filter" variant="Bold" size="1.25rem" />
          </Button> */}
            <Button
              type="button"
              variant="primary"
              className="ms-auto"
              onClick={handleGoToCreateIncident}
              loading={isChecking}
            >
              <Icon name="Add" /> <Text>incident.list.createNewBtn</Text>
            </Button>
          </section>
        </WithPermission>
      )}
      <Wrapper>
        <Wrapper.Body>
          <Table.Wrapper>
            <Table>
              <Table.Body loading={loading.get}>
                {data.items?.map((e) => (
                  <IncidentRow
                    key={e.crmIncidentId}
                    incident={e}
                    onActionEnd={getData}
                  />
                ))}
              </Table.Body>
            </Table>
          </Table.Wrapper>
        </Wrapper.Body>
      </Wrapper>
      <Pagination
        pageNumber={params?.pageNumber ?? 0}
        totalItems={data?.totalItems ?? 0}
        totalPages={data?.totalPages ?? 0}
        setActivePage={handleSetParams("pageNumber")}
      />
    </Fragment>
  );
}
export default withPermission(Incidents, [
  "CMS_IncidentFullAccess",
  "CMS_GetIncident",
]);
