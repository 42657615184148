import { cloneDeep } from "lodash";
import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { IncidentDetailsContext } from ".";
import {
  Alert,
  Button,
  Drawer,
  Form,
  Icon,
  InputGroup,
  Select,
  Text,
} from "../../../components";
import { rules } from "../../../constants";
import { ChooseOrder, IncidentOrderCard } from "../../../containers";
import { SelectedOrder } from "../../../containers/_ChooseOrder";
import { useAxios, useToggle } from "../../../hooks";
import { togglePropsType } from "../../../types";

type Props = togglePropsType;

export default function ChangeIncidentStep({ isOpen, toggle }: Props) {
  const { incident, stages, activeStageIndex, getIncident, updateLoading } =
    useContext(IncidentDetailsContext);
  const { axios, loading } = useAxios();
  const [data, setData] = useState(incident);
  const [showOrderList, toggleOrderList, hideOrderList] = useToggle(false);
  const handleSetValue = (key: keyof typeof data) => {
    return (value: any) => {
      setData((p) => ({ ...p, [key]: value }));
    };
  };
  const currentStage = stages[activeStageIndex];
  const nextStage = stages[activeStageIndex + 1] ?? null;
  const nextStageId = nextStage?.id;
  const isFirstStage = currentStage?.priority === 1;
  const isSecondStage = currentStage?.priority === 2;
  const isThirdStage = currentStage?.priority === 3;
  const initBody = useMemo(
    () => ({
      customerId: incident?.customer?.customerId,
      orderId: incident?.orderId,
      assignedId: incident?.assignedId,
      activeStageId: nextStageId,
      // activeStageId: stages[0].id,
      assignedType: incident?.assignedType,
      orderLineId: incident?.orderLineId,
      branchId: incident?.branchId,
      subjectId: incident?.subjectId,
      title: incident?.title,
      priority: incident?.priority,
      origin: incident?.origin,
      customerMood: incident?.customerMood,
      customerType: incident?.customer?.customerType,
      documents: incident?.documents,
      description: incident.description,
      technicalInspectionNeeded: incident.technicalInspectionNeeded,
    }),
    [incident, nextStageId]
  );
  const nextStageLoading = useMemo(() => {
    if (!isSecondStage) return false;
    const isEqual =
      data.technicalInspectionNeeded === incident.technicalInspectionNeeded;
    const hasLoading = loading.update || updateLoading;
    return !isEqual && hasLoading;
  }, [data, incident, loading.update, isSecondStage, updateLoading]);
  const buttonLoading = useMemo(() => {
    const isEqual =
      data.technicalInspectionNeeded === incident.technicalInspectionNeeded;
    const hasLoading = loading.update || updateLoading;
    if (isSecondStage) return isEqual && hasLoading;
    return hasLoading;
  }, [data, incident, loading.update, isSecondStage, updateLoading]);
  const alert = useMemo(() => {
    const hasOrder = !!data.orderId;
    if (isFirstStage && !hasOrder)
      return {
        title: "incident.changeStep.assignToOrderTitle",
        desc: "incident.changeStep.assignToOrderText",
      };
    return null;
  }, [data.orderId, isFirstStage]);
  const hasAlert = !!alert;
  const hasOrder = data.orderId && data.orderName;
  const handleSetOrder = (order: SelectedOrder | null) => {
    setData((p) => {
      const data = cloneDeep(p);
      if (!order) {
        data.orderId = null;
        data.orderName = null;
        return data;
      }
      data.orderId = order.id;
      data.orderName = order.name;
      return data;
    });
  };
  const submit = () => {
    if (!incident) return;
    const url = `/channelmanagerservice/api/incidents/${incident.crmIncidentId}`;
    const body = cloneDeep(initBody);
    body.description = data.description;
    body.orderId = data.orderId;
    axios.put(url, body).then(() => {
      getIncident().then(() => {
        toggle();
      });
    });
  };
  const handleChangeTechnicalInspectionNeeded = (value: boolean) => {
    if (!incident) return;
    const url = `/channelmanagerservice/api/incidents/${incident.crmIncidentId}`;
    const body = cloneDeep(initBody);
    body.activeStageId = currentStage.id;
    body.technicalInspectionNeeded = value;
    handleSetValue("technicalInspectionNeeded")(value);
    axios
      .put(url, body)
      .then(() => {
        getIncident();
      })
      .catch(() => {
        handleSetValue("technicalInspectionNeeded")(
          !!initBody.technicalInspectionNeeded
        );
      });
  };
  useEffect(() => {
    if (isOpen) setData(incident);
  }, [isOpen, incident]);
  return (
    <Fragment>
      <Drawer as={Form} onSubmit={submit} isOpen={isOpen} toggle={toggle}>
        <Drawer.Menu>
          <Drawer.Header className="flex-center flex-col text-center">
            <h6 className="text-base text-dark font-medium">
              <Text>incident.changeStep.title</Text>
            </h6>
            <p className="text-sm text-dark font-normal">{incident.title}</p>
          </Drawer.Header>
          <Drawer.Header className="flex items-center text-start gap-4">
            <div className="flex-1 space-y-1">
              <h5 className="text-base text-dark font-medium">
                <Text>incident.changeStep.currentStep</Text>
              </h5>
              <div className="w-full bg-gray-50 rounded py-2.5 px-5 space-y-4">
                <h6 className="text-sm text-dark font-medium">
                  {currentStage?.title}
                </h6>
                <p className="text-xs text-placeholder font-medium opacity-0">
                  --
                </p>
              </div>
            </div>
            <div className="h-[4.5rem] flex-center self-end">
              <Icon
                name="ArrowSquareRight"
                variant="Bold"
                className="text-placeholder size-5"
              />
            </div>
            <div className="flex-1 space-y-1">
              <h5 className="text-base text-dark font-medium">
                <Text>incident.changeStep.nextStep</Text>
              </h5>
              {nextStageLoading ? (
                <div className="flex-1 h-[4.5rem] loading"></div>
              ) : (
                <div className="w-full bg-gray-50 rounded py-2.5 px-5 space-y-4">
                  <h6 className="text-sm text-dark font-medium truncate">
                    {nextStage?.title}
                  </h6>
                  <p className="text-xs text-placeholder font-medium opacity-0 truncate">
                    --
                  </p>
                </div>
              )}
            </div>
          </Drawer.Header>
          <Drawer.Body>
            {isFirstStage && (
              <Fragment>
                {!!hasOrder ? (
                  <IncidentOrderCard
                    order={{
                      id: data.orderId as string,
                      name: data.orderName as string,
                    }}
                    onClear={handleSetOrder}
                    // disabled={!isEnabled}
                  />
                ) : (
                  <InputGroup
                    label="incident.form.attachmentOrder"
                    value={null}
                    placeholder="incident.form.attachmentOrderPlaceholder"
                    rules={rules.required}
                    onClick={toggleOrderList}
                    readOnly
                    // disabled={!isEnabled}
                    append={
                      <span className="input-group-text text-primary">
                        <Icon name="ReceiptText" variant="Bold" size={22} />
                      </span>
                    }
                  />
                )}
              </Fragment>
            )}
            {isSecondStage && (
              <Select
                label="incident.changeStep.technicalInspectionNeeded"
                value={data.technicalInspectionNeeded}
                setValue={handleChangeTechnicalInspectionNeeded}
                items={[
                  {
                    name: "incident.changeStep.technicalInspectionNeededYes",
                    id: true,
                  },
                  {
                    name: "incident.changeStep.technicalInspectionNeededNo",
                    id: false,
                  },
                ]}
                rules={rules.required}
              />
            )}
            {isThirdStage && (
              <InputGroup
                as="textarea"
                label="incident.changeStep.description"
                value={data.description}
                setValue={handleSetValue("description")}
              />
            )}
          </Drawer.Body>
          {hasAlert && (
            <Drawer.Footer className="border-t-0">
              <Alert variant="warning" light>
                <Alert.Title>
                  <Text>{alert.title}</Text>
                </Alert.Title>
                <Alert.Text className="leading-5">
                  <Text>{alert.desc}</Text>
                </Alert.Text>
              </Alert>
            </Drawer.Footer>
          )}
          <Drawer.Footer className="flex items-center justify-end gap-4">
            <Button
              type="button"
              variant="danger"
              disabled={buttonLoading}
              onClick={toggle}
            >
              <Text>incident.changeStep.cancelButton</Text>
            </Button>
            <Button
              type="submit"
              variant="primary"
              loading={buttonLoading}
              disabled={hasAlert}
            >
              <Text>incident.changeStep.goToNextStepButton</Text>
            </Button>
          </Drawer.Footer>
        </Drawer.Menu>
      </Drawer>
      <ChooseOrder
        crmCheckRecordExistence
        isOpen={showOrderList}
        toggle={hideOrderList}
        onSelect={handleSetOrder}
        params={{ customerId: data.customer?.customerId, isPublished: true }}
      />
    </Fragment>
  );
}
