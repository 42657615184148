import { cloneDeep } from "lodash";
import {
  Dispatch,
  Fragment,
  SetStateAction,
  createContext,
  createElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router";
import {
  BackButton,
  Button,
  Icon,
  Layout,
  Loading,
  Status,
  Tabs,
  Text,
  Wrapper,
} from "../../../components";
import { CustomerCard, OfferForm, RejectOfferMenu } from "../../../containers";
import { CustomerType, OfferStatus } from "../../../enums";
import { withPermission } from "../../../hoc";
import {
  useAxios,
  useConverters,
  usePermission,
  useToggle,
} from "../../../hooks";
import { cn } from "../../../methods";
import {
  Company,
  Customer,
  LineItem,
  Offer,
  iconNameType,
} from "../../../types";
import AcceptOffer from "./AcceptOffer";
import Billing from "./Billing";
import CompletedInfo from "./CompletedInfo";
import Documents from "./Documents";
import LineItems from "./LineItems";
import Logistic from "./Logistic";
import Notes from "./Notes";

type MoreInfoItem = {
  icon: iconNameType;
  label: string;
  value: string | null | undefined;
  fullWidth?: boolean;
  bold?: boolean;
};
type OfferData = Offer.Details;
type OfferDetailsContextType = {
  offer: OfferData;
  setOffer: Dispatch<SetStateAction<OfferData | null>>;
  updateOffer: () => Promise<OfferData | null>;
  updateOfferLoading: boolean;
  lineItems: LineItem.Item[];
  setLineItems: Dispatch<SetStateAction<LineItem.Item[]>>;
  updateLineItems: () => Promise<LineItem.Item[]>;
  getLineItemsLoading: boolean;
  updateLineItemsLoading: boolean;
  customer: Customer.Details | null;
  isEnabled: boolean;
  isCompany: boolean;
  hasTotalAmount: boolean;
  hasDownPayment: boolean;
  setUnsignedDocuments: Dispatch<SetStateAction<boolean>>;
};
export const OfferDetailsContext = createContext<
  undefined | OfferDetailsContextType
>(undefined);

function OfferDetails() {
  const canEditOffer = usePermission(
    "SC_SaleServiceOfferFullAccess",
    "SC_UpdateSaleServiceOffer"
  );
  const canAcceptOffer = usePermission(
    "SC_SaleServiceOfferFullAccess",
    "SC_MakeSaleServiceOrderFromSaleServiceOffer"
  );
  const { offerId } = useParams();
  const { axios, loading } = useAxios();
  const { axios: customerAxios, loading: customerLoading } = useAxios();
  const { convertDate, convertAmount } = useConverters();
  const [offer, setOffer] = useState<OfferData | null>(null);
  const [customer, setCustomer] = useState<
    (Customer.Details & Company.Details) | null
  >(null);
  const [lineItems, setLineItems] = useState<LineItem.Item[]>([]);
  // REMOVE
  const [unsignedDocuments, setUnsignedDocuments] = useState(false);

  const [showEditOffer, toggleEditOffer] = useToggle(false);
  const [showRejectOffer, toggleRejectOffer] = useToggle(false);
  const [showAcceptOffer, toggleAcceptOffer] = useToggle(false);
  const isCompany = offer?.customer?.customerType === CustomerType.Company;

  const hasData = !!offer;
  const hasLineItems = !!lineItems.length;
  const offerLoading = !!loading.get && !offer;
  const updateOfferLoading = !!loading.get && !!offer;
  const getLineItemsLoading = !!loading.get && !lineItems.length;
  const updateLineItemsLoading = !!loading.get && !!lineItems.length;
  const isEnabled = offer?.status === OfferStatus.Active;
  const isDownPaymentMoreThanTotalAmount =
    (offer?.downPaymentAmount ?? 0) > (offer?.totalAmount ?? 0);

  const hasTotalAmount = useMemo(() => {
    return !!offer?.totalAmount;
  }, [offer?.totalAmount]);
  const hasDownPayment = useMemo(() => {
    return [
      !!lineItems.length && !offer?.totalAmount,
      offer?.downPaymentAmount,
      offer?.downPaymentDueDate,
      offer?.downPaymentReason,
    ].some(Boolean);
  }, [offer, lineItems]);
  const lineItemsHasShippingMethod = useMemo(() => {
    const hasShipping = lineItems.every((e) => Boolean(e.shippingMethodCode));
    return hasLineItems && hasShipping;
  }, [hasLineItems, lineItems]);
  const lineItemsHasShippingDate = useMemo(() => {
    const hasShippingDate = lineItems.every((e) =>
      Boolean(e.desiredDeliveryDate)
    );
    return hasLineItems && hasShippingDate;
  }, [hasLineItems, lineItems]);

  const moreInfo: MoreInfoItem[] = [
    {
      icon: "Calendar",
      label: "global.lastUpdatedAt",
      value: convertDate(offer?.lastUpdatedAt || offer?.createdAt),
    },
    {
      icon: "DollarSquare",
      label: "global.totalPrice",
      value: convertAmount(offer?.totalAmount),
      fullWidth: true,
      bold: true,
    },
  ];
  const tabs = [
    {
      label: "tabs.lineItems",
      id: "0",
      component: LineItems,
    },
    {
      label: "tabs.notes",
      id: "4",
      component: Notes,
    },
    {
      label: "tabs.logistic",
      id: "1",
      component: Logistic,
    },
    {
      label: "tabs.billing",
      id: "2",
      component: Billing,
    },
    {
      label: "tabs.offerDocuments",
      id: "3",
      component: Documents,
    },
  ];
  const getOffer = useCallback(async () => {
    const url = `/salesservice/api/offer/${offerId}`;
    return await axios
      .get(url)
      .then(({ data }) => {
        setOffer(data);
        return data;
      })
      .catch(() => null);
  }, [offerId]);
  const getCustomer = () => {
    const id = offer?.customer?.customerId;
    const isCompany = offer?.customer?.customerType === CustomerType.Company;
    if (!id) return;
    const url = isCompany
      ? `/accountservice/api/customers/${id}/as-company`
      : `/accountservice/api/customers/${id}`;
    customerAxios.get(url).then(({ data }) => {
      setCustomer(data);
    });
  };

  const getUnsignedDocument = () => {
    const url = `/salesservice/api/offerdocuments/${offerId}/unsigned-mandatory-document`;
    axios.get(url).then(({ data }) => {
      setUnsignedDocuments(!!data);
    });
  };
  const getLineItems = useCallback(async () => {
    const url = "/salesservice/api/offerlineitem";
    const config = { params: { offerId: offerId } };
    return await axios
      .get(url, config)
      .then(({ data }) => {
        setLineItems(data);
        return data;
      })
      .catch(() => []);
  }, [offerId]);
  const clearDownPaymentInfo = () => {
    const totalAmount = offer?.totalAmount ?? 0;
    const downPayment = offer?.downPaymentAmount ?? 0;
    const canClear = [offer, lineItems.length, !totalAmount, downPayment].every(
      Boolean
    );
    if (!canClear) return;
    const url = `/salesservice/api/offer/${offerId}/down-payment`;
    const body = {
      downPayment: 0,
      downPaymentDueDate: null,
      downPaymentReason: null,
    };
    axios.post(url, body).then(() => {
      setOffer?.((p) => {
        const data = cloneDeep(p)!;
        data.downPaymentAmount = body.downPayment;
        data.downPaymentDueDate = body.downPaymentDueDate;
        data.downPaymentReason = body.downPaymentReason;
        return data;
      });
    });
  };
  useEffect(() => {
    getOffer();
  }, [getOffer]);
  useEffect(getCustomer, [
    offer?.customer?.customerType,
    offer?.customer?.customerId,
  ]);
  useEffect(() => {
    getLineItems();
  }, [getLineItems]);
  useEffect(getUnsignedDocument, [offerId]);
  useEffect(clearDownPaymentInfo, [offer, lineItems]);
  return (
    <Fragment>
      <Layout>
        <Layout.Header>
          <BackButton to="/offers" className="mr-auto" />
          {isEnabled && !offerLoading && (
            <Fragment>
              {canEditOffer && (
                <Button variant="danger" onClick={toggleRejectOffer}>
                  <Text>button.rejectOffer</Text>
                </Button>
              )}
              {canAcceptOffer && (
                <Button variant="success" onClick={toggleAcceptOffer}>
                  <Text>button.acceptOffer</Text>
                </Button>
              )}
            </Fragment>
          )}
        </Layout.Header>
        <Layout.Body className="grid grid-cols-1 lg:grid-cols-5 gap-3 [&>*]:col-span-full">
          {offerLoading ? (
            <Loading.Header />
          ) : (
            <Fragment>
              <Wrapper className="lg:!col-span-3">
                <Wrapper.Body className="flex flex-wrap items-start gap-4">
                  <div className="flex-1 flex flex-col gap-3">
                    <section className="flex items-center gap-2">
                      <Icon.Wrapper rounded>
                        <Icon name="DiscountShape" variant="Bold" />
                      </Icon.Wrapper>
                      <button
                        type="button"
                        className="text-start"
                        onClick={toggleEditOffer}
                      >
                        <h1 className="flex items-center gap-2 text-base text-dark">
                          <span>{offer?.title}</span>
                          {canEditOffer && (
                            <Icon
                              name="Edit2"
                              size="1em"
                              className="text-primary"
                            />
                          )}
                          <Status.Offer
                            id={offer?.status}
                            className="px-3 text-xs py-[0.375rem]"
                          />
                        </h1>
                        <p className="text-sm text-secondary">
                          #{offer?.number}
                        </p>
                      </button>
                    </section>
                    <section className="flex flex-wrap items-center gap-x-3 gap-y-2">
                      {moreInfo.map((e) => (
                        <p
                          key={e.label}
                          className={cn(
                            "text-sm",
                            e.fullWidth && "basis-full",
                            e.bold && "text-dark font-semibold"
                          )}
                        >
                          <Icon
                            name={e.icon}
                            variant="Bulk"
                            className="text-primary"
                          />{" "}
                          <span className="text-placeholder">
                            <Text>{e.label}</Text>:
                          </span>{" "}
                          <span
                            className={
                              e.bold ? "text-inherit" : "text-secondary"
                            }
                          >
                            {e.value}
                          </span>
                        </p>
                      ))}
                    </section>
                    {/* <section className="flex items-center flex-wrap gap-3 mt-auto">
                      <span className="bg-gray-50 px-2 py-1 rounded-md">
                        <Icon
                          name="DollarSquare"
                          variant="Bold"
                          size={22}
                          className="text-dark"
                        />{" "}
                        <Text>global.totalPrice</Text>:{" "}
                        {convertAmount(offer?.totalAmount)}
                      </span>
                      <Status.Offer id={offer?.status} />
                    </section> */}
                  </div>
                  {hasData && isEnabled && (
                    <CompletedInfo
                      offer={offer}
                      signedDocuments={!unsignedDocuments}
                      lineItemsHasShippingMethod={lineItemsHasShippingMethod}
                      lineItemsHasShippingDate={lineItemsHasShippingDate}
                      hasDownPayment={hasDownPayment}
                      isDownPaymentMoreThanTotalAmount={
                        isDownPaymentMoreThanTotalAmount
                      }
                    />
                  )}
                </Wrapper.Body>
              </Wrapper>
              {customerLoading.get ? (
                <div className="lg:!col-span-2 loading" />
              ) : (
                <CustomerCard customer={customer} className="lg:!col-span-2" />
              )}
              <Tabs activeKey={tabs[0].id}>
                <Wrapper className="col-span-full">
                  <Wrapper.Body className="py-0">
                    <Tabs.ButtonGroup>
                      {tabs.map((e) => (
                        <Tabs.Button key={e.id} eventKey={e.id}>
                          <Text>{e.label}</Text>
                        </Tabs.Button>
                      ))}
                    </Tabs.ButtonGroup>
                  </Wrapper.Body>
                </Wrapper>
                {hasData && (
                  <Fragment>
                    <OfferDetailsContext.Provider
                      value={{
                        offer,
                        setOffer,
                        updateOffer: getOffer,
                        updateOfferLoading,
                        lineItems,
                        setLineItems,
                        updateLineItems: getLineItems,
                        getLineItemsLoading,
                        updateLineItemsLoading,
                        customer,
                        isEnabled: isEnabled && canEditOffer,
                        isCompany,
                        hasTotalAmount,
                        hasDownPayment,
                        setUnsignedDocuments,
                      }}
                    >
                      {tabs.map((e) => (
                        <Tabs.Item key={e.id} eventKey={e.id}>
                          {createElement(e.component)}
                        </Tabs.Item>
                      ))}
                      {canAcceptOffer && (
                        <AcceptOffer
                          isOpen={showAcceptOffer}
                          toggle={toggleAcceptOffer}
                          signedDocuments={!unsignedDocuments}
                          lineItemsHasShippingMethod={
                            lineItemsHasShippingMethod
                          }
                          lineItemsHasShippingDate={lineItemsHasShippingDate}
                          hasDownPayment={hasDownPayment}
                          isDownPaymentMoreThanTotalAmount={
                            isDownPaymentMoreThanTotalAmount
                          }
                        />
                      )}
                    </OfferDetailsContext.Provider>
                    {canEditOffer && (
                      <RejectOfferMenu
                        isOpen={showRejectOffer}
                        toggle={toggleRejectOffer}
                        offerData={offer}
                        onSubmitted={getOffer}
                      />
                    )}
                  </Fragment>
                )}
              </Tabs>
            </Fragment>
          )}
        </Layout.Body>
      </Layout>
      {!loading.get && hasData && canEditOffer && (
        <Fragment>
          {isCompany ? (
            <OfferForm
              isOpen={showEditOffer}
              toggle={toggleEditOffer}
              offer={offer}
              company={customer as Company.Details}
              onSubmitted={getOffer}
            />
          ) : (
            <OfferForm
              isOpen={showEditOffer}
              toggle={toggleEditOffer}
              offer={offer}
              customer={customer as Customer.Details}
              onSubmitted={getOffer}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
export default withPermission(OfferDetails, [
  "SC_SaleServiceOfferFullAccess",
  "SC_GetSaleServiceOffer",
]);
