import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { IncidentDetailsContext } from "..";
import {
  Button,
  Icon,
  Loading,
  NoItems,
  SearchBox,
  Text,
  Wrapper,
} from "../../../../components";
import { CRMActivityType } from "../../../../enums";
import { useAxios, useToggle } from "../../../../hooks";
import { cleanText } from "../../../../methods";
import { Incident } from "../../../../types";
import ActivityRow from "./ActivityRow";
import ActivityForm from "./CreateActivity/ActivityForm";
import SelectActivityType from "./CreateActivity/SelectActivityType";

export default function Activities() {
  const { axios, loading } = useAxios();
  const { incident } = useContext(IncidentDetailsContext);
  const stagesState = useMemo(() => {
    const stages = incident.stages ?? [];
    const mapStages = stages.map((e) => [e.isActive, e.isProcessed].join("-"));
    const result = mapStages.join(",");
    return result;
  }, [incident.stages]);

  const [keyword, setKeyword] = useState<string | null>(null);
  const [data, setData] = useState<Incident.TimeLineItem[]>([]);
  const timeLines = useMemo(() => {
    if (!keyword) return data;
    return data.filter((e) =>
      [e.content, e.subject]
        .map((str) => cleanText(str)?.toUpperCase() ?? "")
        .some((key) => key.includes(keyword.toUpperCase()))
    );
  }, [data, keyword]);
  const [showSelectType, toggleSelectType] = useToggle(false);
  const [selectedType, setSelectedType] = useState<CRMActivityType | null>(
    null
  );
  const hasData = !!timeLines.length;
  const isSelectedType = selectedType !== null;
  const clearSelectedType = () => {
    setSelectedType(null);
  };
  const getData = () => {
    const url = `/channelmanagerservice/api/activities/timeline/${incident.crmIncidentId}`;
    axios.get(url).then(({ data }) => {
      setData(data?.reverse() ?? []);
    });
  };
  useEffect(getData, [stagesState, incident.crmIncidentId]);
  return (
    <Fragment>
      <section className="flex items-center gap-4">
        <SearchBox className="w-auto basis-1/3" onSubmit={setKeyword} />
        {/* <Button light onClick={() => "toggleFilterMenu"}>
          <Icon name="Filter" variant="Bold" size={20} />
        </Button> */}
        <Button className="ml-auto" onClick={toggleSelectType}>
          <Icon name="Add" size={20} className="mr-1" />
          <Text>incident.activities.addNewButton</Text>
        </Button>
      </section>
      <Wrapper>
        <Wrapper.Body className="space-y-4">
          {loading.get ? (
            <Loading.IncidentActivity />
          ) : hasData ? (
            timeLines.map((e) => <ActivityRow key={e.id} activity={e} />)
          ) : (
            <NoItems />
          )}
        </Wrapper.Body>
      </Wrapper>
      <SelectActivityType
        isOpen={showSelectType}
        toggle={toggleSelectType}
        onSelect={setSelectedType}
      />
      <ActivityForm
        isOpen={isSelectedType}
        toggle={clearSelectedType}
        activityType={selectedType}
        onSubmitted={getData}
      />
    </Fragment>
  );
}
