import { cloneDeep } from "lodash";
import { useContext, useEffect, useState } from "react";
import { CompanyContext } from "..";
import {
  Button,
  Drawer,
  Loading,
  Pagination,
  Text,
} from "../../../../components";
import { config } from "../../../../constants";
import { useAxios } from "../../../../hooks";
import { Order, WithPaging } from "../../../../types";
import ChooseOrderItem from "./ChooseOrderItem";

interface ChooseOrderProps {
  isOpen?: boolean;
  toggle: () => void;
  orders?: any;
  setOrders?: any;
  deliveryDate: string;
}

export default function ChooseOrder({
  isOpen = false,
  toggle,
  // orders,
  setOrders,
  deliveryDate,
}: ChooseOrderProps) {
  const { axios, loading } = useAxios();
  const { companyData } = useContext(CompanyContext);
  const [orderList, setOrderList] = useState<WithPaging<Order.Item>>({});
  const [selectedOrders, setSelectedOrders] = useState<Order.Item[]>([]);
  const ids = selectedOrders?.map((e) => e.id);
  const deliveryDateISO = deliveryDate
    ? new Date(deliveryDate).toISOString()
    : new Date().toISOString();
  const [params, setParams] = useState<Order.Params>({
    pageNumber: 1,
    pageSize: config.pageSize,
    customerId: companyData.id,
    channelCode: null,
  });
  const handleSelectOrder = (id: string) => {
    setSelectedOrders((p) => {
      const orders = cloneDeep(p);
      const selectedOrder = orderList.items?.find((e) => e.id === id);
      const index = orders.findIndex((e) => e.id === id);
      const isSelected = index !== -1;
      isSelected && orders.splice(index, 1);
      !isSelected && !!selectedOrder && orders.push(selectedOrder);
      return orders;
    });
  };
  const handleSetParams = (key: string) => {
    const isPageNumber = key !== "pageNumber";
    return (val: any) => {
      setParams((p) => ({
        ...p,
        [key]: val,
        ...(!isPageNumber && { pageNumber: 1 }),
      }));
    };
  };
  const getData = () => {
    const url = `/salesservice/api/orders`;
    const config = { params };
    axios.get(url, config).then(({ data }) => {
      setOrderList(data);
    });
  };

  useEffect(() => {
    if (isOpen) {
      getData();
    }
  }, [isOpen]);

  const onChooseHandler = () => {
    setOrders(selectedOrders);
    toggle();
  };
  return (
    <Drawer isOpen={isOpen} toggle={toggle} className="z-[31]">
      <Drawer.Menu>
        <Drawer.Header>
          <Text>commissions.chooseOrder</Text>
        </Drawer.Header>
        <Drawer.Body className="divide-y divide-dashed space-y-4">
          {loading.get ? (
            <Loading.Inline />
          ) : (
            <>
              {orderList.items?.map((order) => (
                <ChooseOrderItem
                  key={order.id}
                  order={order}
                  isSelected={ids.includes(order.id)}
                  deliveryDateISO={deliveryDateISO}
                  deliveryDate={deliveryDate}
                  onSelect={handleSelectOrder}
                />
              ))}
            </>
          )}
        </Drawer.Body>
        <Drawer.Footer>
          <Pagination
            pageNumber={params?.pageNumber ?? 0}
            totalItems={orderList?.totalItems ?? 0}
            totalPages={orderList?.totalPages ?? 0}
            setActivePage={handleSetParams("pageNumber")}
          />
        </Drawer.Footer>
        <Drawer.Footer className="flex items-center justify-center gap-4">
          <Button type="button" light onClick={onChooseHandler}>
            <Text>commissions.choose</Text>
          </Button>
          {/* <Button type="button" variant="primary">
            <Text>commissions.createCommissionGroup</Text>
          </Button> */}
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
