import {
  Dispatch,
  SetStateAction,
  createContext,
  useMemo,
  useState,
} from "react";
import { Drawer } from "../../../../../components";
import { PimProductType } from "../../../../../enums";
import { togglePropsType } from "../../../../../types";
import ManualForm from "./ManualForm";
import PimProducts from "./PimProducts";

type SearchProductProps = togglePropsType & {
  parentId: string | null;
  pimProductType: PimProductType;
};
type SearchProductContextType = {
  productId: string;
  setProductId: Dispatch<SetStateAction<string>>;
  clearProduct: () => void;
  parentId: string | null;
  toggle: () => void;
  pimProductType: PimProductType;
};

export const SearchProductContext = createContext(
  {} as SearchProductContextType
);

export default function SearchProduct({
  isOpen,
  toggle,
  parentId,
  pimProductType,
}: SearchProductProps) {
  const [productId, setProductId] = useState("");
  const ActiveTab = useMemo(() => {
    if (!productId) return PimProducts;
    return ManualForm;
  }, [productId]);
  const clearProduct = () => {
    setProductId("");
  };
  const handleToggle = () => {
    clearProduct();
    toggle();
  };

  return (
    <Drawer isOpen={isOpen} toggle={handleToggle} size="lg">
      <Drawer.Menu className={productId ? "bg-[#F5F7F9]" : undefined}>
        {isOpen && (
          <SearchProductContext.Provider
            value={{
              productId,
              setProductId,
              clearProduct,
              parentId,
              toggle: handleToggle,
              pimProductType,
            }}
          >
            <ActiveTab />
          </SearchProductContext.Provider>
        )}
      </Drawer.Menu>
    </Drawer>
  );
}
