import { cloneDeep } from "lodash";
import { Fragment, useContext, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { twMerge } from "tailwind-merge";
import { OfferDetailsContext } from "..";
import {
  Badge,
  Button,
  Icon,
  Table,
  Text,
  Types,
  Wrapper,
} from "../../../../components";
import { SetDesireDeliveryDate } from "../../../../containers";
import { LineItemType } from "../../../../enums";
import { useAxios } from "../../../../hooks";
import { calendarWeekToTime } from "../../../../methods";
import { CalendarWeek, LineItem } from "../../../../types";
import { OrderDetailsContext } from "../../../Orders/Details";
import LogisticAction from "./LogisticAction";

export default function LineItems() {
  const offerContext = useContext(OfferDetailsContext);
  const orderContext = useContext(OrderDetailsContext);

  const lineItems = useMemo(() => {
    const result = offerContext?.lineItems || orderContext?.lineItems || [];
    return result.filter((e) => !e.isCanceled);
  }, [offerContext?.lineItems, orderContext?.lineItems]);
  const lineItemsLoading =
    offerContext?.getLineItemsLoading ||
    orderContext?.getLineItemsLoading ||
    offerContext?.updateLineItemsLoading ||
    orderContext?.updateLineItemsLoading ||
    false;
  const getLineItems =
    offerContext?.updateLineItems ||
    orderContext?.updateLineItems ||
    (() => Promise.resolve([]));
  const setLineItems =
    offerContext?.setLineItems || orderContext?.setLineItems || (() => {});

  const offer = offerContext?.offer;
  const order = orderContext?.order;
  const isOffer = !!offer;
  const isOrder = !!order;
  const isEnabled = offerContext?.isEnabled ?? orderContext?.isEnabled;
  const offerDesiredDeliveryDate = offer?.desiredDeliveryCalendarWeek;
  const orderDesiredDeliveryDate = order?.desiredDeliveryCalendarWeek;
  const parentDesiredDeliveryDate =
    offerDesiredDeliveryDate || orderDesiredDeliveryDate;

  const { axios, loading } = useAxios();
  const [actionId, setActionId] = useState("");
  const [calendarId, setCalendarId] = useState("");

  const filteredLineItems = lineItems.filter((e) => !!e.desiredDeliveryDate);

  const clearActionId = () => {
    setActionId("");
  };

  const clearCalendarId = () => {
    setCalendarId("");
  };
  const calendarWeekIsValid = (calendarWeek: CalendarWeek | null) => {
    const parentDesiredDeliveryTime = calendarWeekToTime(
      parentDesiredDeliveryDate?.year,
      parentDesiredDeliveryDate?.calendarWeekNumber
    );
    const lineItemTime = calendarWeekToTime(
      calendarWeek?.year,
      calendarWeek?.calendarWeekNumber
    );
    if (!parentDesiredDeliveryTime || !lineItemTime) return true;
    return lineItemTime <= parentDesiredDeliveryTime;
  };

  const removeDate = (id: string) => {
    const url = [
      "/salesservice",
      "api",
      isOffer && "offerlineitem",
      isOrder && "orderlineitems",
      isOffer && "desired-delivery-date",
      isOrder && "desired-deliveri-date",
    ]
      .filter(Boolean)
      .join("/");
    const body = {
      desiredDeliveryDate: null,
      [isOffer ? "offerLineItemIds" : "orderLineItemIds"]: [id],
      desiredDeliveryCalendarWeek: null,
    };
    axios.post(url, body).then(() => {
      getLineItems().then(() => {
        toast.success("toast.success.lineItemDesiredDeliveryDate");
      });
    });
  };
  const handleSubmitted = (lineItem: LineItem.Item | null | undefined) => {
    setLineItems((p) => {
      const lineItems = cloneDeep(p);
      const lineItemIndex = lineItems.findIndex((e) => e.id === lineItem?.id);
      if (lineItem) lineItems[lineItemIndex] = lineItem;
      return lineItems;
    });
  };

  return (
    <Wrapper>
      <Wrapper.Header>
        <h6 className="text-dark text-base">
          <Text>global.lineItemsTitle</Text>
        </h6>
      </Wrapper.Header>
      <Wrapper.Body>
        <Table.Wrapper>
          <Table>
            <Table.Body loading={lineItemsLoading || loading.post}>
              {filteredLineItems.map((e) => {
                const parentId = e.parentId;
                const isSubLineItem = !!parentId;
                const isBundle = e.lineItemType === LineItemType.ManualBundle;
                const shippingMethod = e.shippingMethodCode;
                const hasShippingMethod = !!shippingMethod;
                const articleNumber = e.product?.articleNumber;
                const hasArticleNumber = !!articleNumber;
                const isValid = calendarWeekIsValid(
                  e.desiredDeliveryCalendarWeek
                );
                return (
                  <Fragment key={e.id}>
                    <tr>
                      <td>
                        <div className="flex items-center gap-4">
                          <div className="flex-1 flex items-center text-start gap-4">
                            <div className="flex-center size-11">
                              <Icon.Wrapper
                                variant="primary"
                                className={twMerge(
                                  isBundle && "bg-opacity-100 text-white",
                                  isSubLineItem && "bg-opacity-100 p-1"
                                )}
                              >
                                {isBundle ? (
                                  <Icon
                                    name="Dropbox"
                                    variant="Bold"
                                    size={isSubLineItem ? 24 : 28}
                                  />
                                ) : (
                                  <Icon
                                    name={isBundle ? "Dropbox" : "Box1"}
                                    variant={isSubLineItem ? "Bold" : "Linear"}
                                    size={isSubLineItem ? 0 : 28}
                                    className={isSubLineItem ? "opacity-0" : ""}
                                  />
                                )}
                              </Icon.Wrapper>
                            </div>
                            <div className="space-y-1">
                              <h6 className="text-base font-normal max-w-72 whitespace-normal line-clamp-2">
                                {e.title}
                              </h6>
                              {hasArticleNumber && (
                                <p className="block text-secondary">
                                  #{articleNumber}
                                </p>
                              )}
                              <p className="space-x-1 !mt-2">
                                <Types.LineItem id={e.lineItemType} />
                                {hasShippingMethod && (
                                  <Badge
                                    variant="dark"
                                    className="p-1 text-xs leading-none"
                                  >
                                    <Icon name="TruckFast" variant="Bulk" />{" "}
                                    {shippingMethod}
                                  </Badge>
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td
                        className={twMerge(
                          "text-sm",
                          isValid ? "text-gray-900" : "text-warning underline"
                        )}
                      >
                        <Icon name="CalendarTick" variant="Bold" />{" "}
                        <Text>global.calendarWeek</Text>{" "}
                        {e.desiredDeliveryCalendarWeek?.calendarWeekNumber}
                        {!isValid && (
                          <Fragment>
                            {" - "}
                            <Text>global.lineItemCalendarWeekNotValid</Text>
                          </Fragment>
                        )}
                      </td>
                      {isEnabled && (
                        <td data-sticky>
                          <Button
                            light
                            onClick={() => setActionId(e.id)}
                            className="btn-sm"
                          >
                            <Icon name="More" size={18} className="rotate-90" />
                          </Button>
                        </td>
                      )}
                    </tr>
                    {isEnabled && (
                      <Fragment>
                        <LogisticAction
                          isOpen={e.id === actionId}
                          toggle={clearActionId}
                          label={e.title}
                          number={e.product?.articleNumber}
                          onEdit={() => setCalendarId(e.id)}
                          onRemove={() => removeDate(e.id)}
                        />
                        <SetDesireDeliveryDate
                          isOpen={e.id === calendarId}
                          toggle={clearCalendarId}
                          lineItem={e}
                          onSubmitted={(e) => handleSubmitted(e.lineItem)}
                        />
                      </Fragment>
                    )}
                  </Fragment>
                );
              })}
            </Table.Body>
          </Table>
        </Table.Wrapper>
      </Wrapper.Body>
    </Wrapper>
  );
}
