import { Fragment } from "react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { useConverters } from "../hooks";
import { isActiveAuction } from "../methods";
import { Product } from "../types";
import Icon from "./_Icon";
import Image from "./_Image";
import Text from "./_Text";
import Types from "./_Types";

type ProductCardProps = {
  product: Product.Item;
  isGrid: boolean;
};

export default function ProductCard({ product, isGrid }: ProductCardProps) {
  const { convertAmount } = useConverters();
  const hasAuctions = !!product.auctions?.filter(isActiveAuction)?.length;
  const hasBrandLogo = !!product.brandLogo;
  const hasBrandName = !!product.brandName;
  const vk2Amount = product.uvpPrice?.amount ?? product.vk2Price?.amount;
  const vk1Amount = product.vk1Price?.amount;
  const hasPrice = [vk2Amount, vk1Amount].some(Boolean);
  const hasPrice2 = !!vk2Amount && vk1Amount !== vk2Amount;

  return (
    <Link
      to={product.id}
      className={twMerge(
        "group relative w-full flex bg-white shadow rounded gap-2 p-2 hover:shadow-xl transition-shadow",
        isGrid ? "flex-col" : "flex-row"
      )}
    >
      <div
        className={twMerge(
          "relative aspect-image rounded overflow-hidden",
          isGrid ? "w-full" : "h-28"
        )}
      >
        <Image
          src={product.avatarThumbnailUrl}
          alt={product.originalName}
          className="size-full object-cover group-hover:scale-110 transition-transform"
          isDynamic
        />
        {hasAuctions && (
          <div className="absolute w-full flex-center bottom-0 left-0 bg-warning py-px">
            <Icon
              name="ReceiptDisscount"
              variant="Bulk"
              className="size-3 text-white"
            />
          </div>
        )}
      </div>
      <div className="flex-1 flex flex-col gap-1">
        <h6 className="w-full text-dark text-base font-medium break-words">
          {product.originalName}
        </h6>
        <p className="text-sm text-secondary font-medium w-full">
          #{product.articleNumber}
        </p>
        {!isGrid && (
          <Fragment>
            <p className="text-sm text-secondary font-medium w-full">
              <Text>products.list.productGroup</Text>:{" "}
              {product.pimProductGroupName}
            </p>
            <p className="text-sm text-secondary font-medium w-full mt-auto space-x-2">
              {hasBrandLogo && (
                <Image
                  src={product.brandLogo}
                  alt={product.brandName}
                  isDynamic
                  className="inline-block rounded h-7"
                />
              )}
              {hasBrandName && <span>{product.brandName}</span>}
              <Types.PimProduct id={product.pimProductType} />
            </p>
          </Fragment>
        )}
      </div>
      {hasPrice && (
        <div
          className={twMerge(
            "flex items-center gap-3 text-sm text-[#A1A4AC] font-semibold pt-1",
            !isGrid && "flex-col self-center gap-1 px-4"
          )}
        >
          <p className="text-primary">{convertAmount(vk1Amount) ?? "--"}</p>
          {hasPrice2 && (
            <p className="line-through">{convertAmount(vk2Amount) ?? "--"}</p>
          )}
        </div>
      )}
      {hasAuctions && !isGrid && (
        <div className="absolute size-6 flex-center top-2 right-2 bg-warning rounded-full">
          <Icon
            name="ReceiptDisscount"
            variant="Bulk"
            className="size-4 text-white"
          />
        </div>
      )}
    </Link>
  );
}
