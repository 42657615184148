const incident = {
  list: {
    searchPlaceholder: "Namen oder CIM suchen...",
    createNewBtn: "Neuen Servicefall erstellen",
    assignedTo: "Zugewiesen an",
    chooseEmployeeBtn: "Auswählen",
    incidentDetails: "Details",
  },
  selectType: {
    selectIncidentTypeTitle: "Vorfalltyp auswählen",
    backBtn: "Zurück",
  },
  info: {
    incidentDetailsTitle: "Servicefall Typ Details",
    editButton: "Bearbeiten",
    infoTitle: "Informationen",
    customer: "Kunde",
    created: "Erstellt",
    assignedEmployee: "Zugewiesener Mitarbeiter",
    store: "Geschäft",
    priority: "Priorität",
    origin: "Herkunft",
    customerMood: "Kundenstimmung",
    topicTitle: "Thema",
    description: "Beschreibung",
  },
  statusInfo: {
    statusInfoTitle: "Vorfallstatus",
    changeStatusBtn: "Status ändern",
    closeBtn: "Schließen",
  },
  changeStatus: {
    changeStatusTitle: "Vorfallstatus ändern",
    description: "Beschreibung hinzufügen",
    descriptionPlaceholder: "Eine Notiz dazu hinzufügen",
    cancelBtn: "Abbrechen",
    submitChangeBtn: "Änderung bestätigen",
    successMessage: "Vorfallstatus erfolgreich aktualisiert.",
  },
  tabTitles: {
    basicInfo: "Basisinformationen",
    incidentInfo: "Vorfallinformationen",
    otherInfo: "Weitere Informationen",
    completed: "Abgeschlossen",
  },
  tabDescriptions: {
    basicInfo: "Typ, Kunde, Geschäft",
    incidentInfo: "Bestellung, Titel, ...",
    otherInfo: "Priorität, Herkunft, ...",
    completed: "Woah, wir sind hier",
  },
  form: {
    createNewIncidentTitle: "Neuen Vorfall erstellen",
    editIncidentTitle: "Vorfall bearbeiten",
    customer: "Kunde",
    customerPlaceholder: "Auswählen",
    changeCustomerBtn: "Ändern",
    store: "Geschäft",
    changeStoreBtn: "Ändern",
    assignToMe: "Mir zuweisen",
    assignToOthers: "Anderer Person zuweisen",
    assignToDepartment: "Abteilung zuweisen",
    noAssign: "Ohne Zuweisung",
    assignedEmployeePlaceholder: "Auswählen",
    title: "Titel",
    titlePlaceholder: "Vorfalltitel eingeben",
    priority: "Priorität",
    priorityPlaceholder: "Auswählen",
    origin: "Herkunft",
    originPlaceholder: "Auswählen",
    topicDescription: "Themenbeschreibung",
    topicDescriptionPlaceholder: "Eine Notiz zum Thema hinzufügen",
    attachmentOrder: "Angehängte Bestellung",
    attachmentOrderPlaceholder: "Auswählen",
    changeOrderBtn: "Ändern",
    attachLineItem: "Ausgewählteser Artikel",
    attachLineItemBtn: "Betroffenen Artikel auswählen",
    customerMood: "Kundenstimmung",
    description: "Beschreibung",
    descriptionPlaceholder: "Eine Notiz zum Vorfall hinzufügen",
    cancelBtn: "Abbrechen",
    backBtn: "Zurück",
    submitBtn: "Bestätigen",
    nextBtnBtn: "Weiter",
    submitChangeText: "Bitte Ihre Änderungen einreichen",
    submitChangeBtn: "Änderung bestätigen",
    incidentTypeMessage: "Bitte einen Vorfalltypen wählen",
    customerMoodMessage: "Kundenstimmung ist erforderlich",
    createSuccessMessage: "Vorfall erfolgreich hinzugefügt.",
    updateSuccessMessage: "Vorfall erfolgreich aktualisiert.",
    assignEmployeeMessage: "Mitarbeiter erfolgreich zugewiesen.",
    assignedEmployee: "Zugewiesener Mitarbeiter",
  },
  chooseCustomer: {
    title: "Kunden auswählen",
    chooseBtn: "Auswählen",
  },
  chooseEmployee: {
    title: "Mitarbeiter auswählen",
    search: "Suchen",
    chooseBtn: "Auswählen",
  },
  chooseOrder: {
    title: "Bestellung auswählen",
    search: "Suchen",
    chooseBtn: "Auswählen",
    clearBtn: "Löschen",
  },
  chooseLineItem: {
    title: "Artikel auswählen",
    search: "Suche nach Namen",
    chooseBtn: "Auswählen",
  },
  filter: {
    title: "Filter",
    resetFilterBtn: "Filter zurücksetzen",
    customer: "Kunde",
    customerPlaceholder: "Kunden auswählen",
    assignedOrder: "Zugewiesene Bestellung",
    assignedOrderPlaceholder: "Bestellung auswählen",
    store: "Geschäft",
    storePlaceholder: "Geschäft auswählen",
    assignedEmployee: "Zugewiesener Mitarbeiter",
    assignedEmployeePlaceholder: "Mitarbeiter auswählen",
    assignToMe: "Mir zuweisen",
    assignToOthers: "Anderen zuweisen",
    noAssign: "Nicht zuweisen",
    incidentStatus: "Vorfallstatus",
    cancelBtn: "Abbrechen",
    submitBtn: "Filter bestätigen",
  },
  details: {
    goToNextStepButton: "Zum nächsten Schritt...",
    finalButtonButton: "Abschliessen",
    subjectName: "Betreff",
    createdAt: "Erstellt am:",
    store: "Filiale",
    generalTab: "Informationen",
    activitiesTab: "Zeitverlauf",
    documentsTab: "Dokumente",
    alertTitle: "Vorfallstatus ist {{ status }}",
    alertText: "Sie müssen den Status ändern, um erneut bearbeiten zu können",
  },
  activities: {
    addNewButton: "Neu hinzufügen",
    createNewActivityTitle: "Neue Aktivität",
    chooseActivityType: "Aktivitätstyp auswählen",
    chooseActivityTypeBackBtn: "Zurück",
    activityDescription: "Beschreibung",
    activityModifiedBy: "geändert von",
    active: "Aktiv",
    moreDetailsBtn: "Mehr Details",
    lessDetailsBtn: "Weniger Details",
    deleteActivityTitle: "Aktivität löschen",
    deleteActivityCancelBtn: "Abbrechen",
    deleteActivityDeleteBtn: "Aktivität löschen",
    cancelActivityTitle: "Aktivität abbrechen",
    cancelActivityCancelBtn: "Abbrechen",
    cancelActivityDeleteBtn: "Aktivität abbrechen",
    completeActivityTitle: "Aktivität abschließen",
    completeActivityCancelBtn: "Abbrechen",
    completeActivityDeleteBtn: "Aktivität abschließen",
    info: {
      infoTitle: "Information",
      descriptionTitle: "Beschreibung",
      createdAt: "Erstellt am:",
      duration: "Dauer",
      dueDate: "bis (Datum)",
      priority: "Priorität",
      status: "Status",
      assignedName: "Zugewiesen an",
    },
    createNew: {
      drawerTitle: "Neue Aktivität erstellen",
      subject: "Betreff",
      subjectPlaceholder: "Titel eingeben",
      description: "Beschreibung",
      descriptionPlaceholder: "Weitere Notizen",
      priority: "Priorität",
      duration: "Dauer",
      dueDate: "zu erledigen bis",
      assignEmployee: "Aufgabe zugewiesen an",
      successMessage: "Aufgabe wurde erstellt",
      cancelButton: "Löschen",
      submitButton: "Aktivität starten",
    },
  },
  changeStep: {
    title: "Change incident Step DE",
    currentStep: "Current step DE",
    nextStep: "Next step DE",
    technicalInspectionNeeded: "Technical inspection needed DE",
    technicalInspectionNeededYes: "Yes DE",
    technicalInspectionNeededNo: "No DE",
    description: "Description DE",
    assignToOrderTitle: "Assign to order DE",
    assignToOrderText: "You must assign this incident to order first. DE",
    cancelButton: "Cancel DE",
    goToNextStepButton: "Go to next Step DE",
  },
  checkRecordExistenceMessages: {
    account: "Company is not synced in CRM DE",
    contact: "Customer is not synced in CRM DE",
    salesOrder: "Order is not synced in CRM DE",
  },
};
export default incident;
