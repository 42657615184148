import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { OfferDetailsContext } from "..";
import { Button, Icon, Loading, Modal, Text } from "../../../../components";
import { useAxios } from "../../../../hooks";
import { LineItem, ShippingMethod, togglePropsType } from "../../../../types";
import { OrderDetailsContext } from "../../../Orders/Details";

type Props = togglePropsType;
export default function SelAllLineItemsShippingMethod({
  isOpen,
  toggle,
}: Props) {
  const offerContext = useContext(OfferDetailsContext);
  const orderContext = useContext(OrderDetailsContext);

  const offer = offerContext?.offer;
  const order = orderContext?.order;
  const updateOffer = offerContext?.updateOffer;
  const updateOrder = orderContext?.updateOrder;
  const updateOfferLoading = offerContext?.updateOfferLoading;
  const updateOrderLoading = orderContext?.updateOrderLoading;
  const isOffer = !!offer;
  const isOrder = !!order;
  const updateHeader = updateOffer || updateOrder;
  const updateHeaderLoading = updateOfferLoading || updateOrderLoading;
  const setLineItems =
    offerContext?.setLineItems || orderContext?.setLineItems || (() => {});

  const { axios, loading } = useAxios();
  const [code, setCode] = useState<string | null>();
  const [shippingMethods, setShippingMethods] = useState<ShippingMethod[]>([]);

  const getLoading = !code && loading.get;
  const hasLoading = [
    loading.update,
    !!code && loading.get,
    updateHeaderLoading,
  ].some(Boolean);

  const getAllLineItems = async () => {
    const url = [
      "/salesservice",
      "api",
      isOffer && "offerlineitem",
      isOrder && "orderlineitems",
    ]
      .filter(Boolean)
      .join("/");
    const config = {
      params: {
        offerId: offer?.id,
        orderId: order?.id,
        isCanceled: isOrder ? false : undefined,
      },
    };
    return await axios
      .get(url, config)
      .then((res) => res.data as LineItem.Item[])
      .catch(() => null);
  };
  const getShippingMethods = () => {
    if (!isOpen) return;
    const url = "/productservice/api/shippingmethods";
    const config = { cache: true };
    axios.get(url, config).then(({ data }) => {
      setShippingMethods(data);
    });
  };
  const submitLineItemShippingMethod = async (
    lineItem: LineItem.Item,
    code: string
  ) => {
    const url = [
      "/salesservice",
      "api",
      isOffer && "offerlineitem",
      isOrder && "orderlineitems",
      lineItem.id,
    ]
      .filter(Boolean)
      .join("/");
    const body: LineItem.Edit = {
      offerId: lineItem.offerId,
      orderId: lineItem.orderId,
      offerLineItemId: lineItem.id,
      orderLineItemId: lineItem.id,
      title: lineItem.title,
      lineItemNote: lineItem.lineItemNote,
      supplierNote: lineItem.supplierNote,
      customerNote: lineItem.customerNote,
      ean: lineItem.ean,
      description: lineItem.description,
      configurationText: lineItem.configurationText,
      lineItemType: lineItem.lineItemType,
      shippingMethodCode: code,
    };
    return axios
      .put(url, body)
      .then(() => null)
      .catch(() => null);
  };
  const submittedShippingMethod = () => {
    const message = "toast.success.setShippingMethodToAllLineItems";
    toast.success(message);
    setCode(null);
    toggle();
  };
  const submitShippingMethods = async () => {
    const lineItems = await getAllLineItems();
    const filteredLineItems = lineItems?.filter(
      (e) => !e.isCanceled && !e.parentId && e.shippingMethodCode !== code
    );
    if (!filteredLineItems?.length || !code) return submittedShippingMethod();
    const allPromise = Promise.all(
      filteredLineItems?.map((e) => submitLineItemShippingMethod(e, code))
    );
    allPromise.then(() => {
      updateHeader?.().then(() => {
        submittedShippingMethod();
        setLineItems((p) => p.map((e) => ({ ...e, shippingMethodCode: code })));
      });
    });
  };
  useEffect(getShippingMethods, [isOpen]);
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Modal.Header>
        <Text>modalTitles.setShippingMethodToAllLineItems</Text>
      </Modal.Header>
      <Modal.Body className="space-y-4">
        <h6 className="text-secondary text-sm">
          <Text>global.shippingMethodsText</Text>:
        </h6>
        {getLoading ? (
          <Loading.ShippingMethod />
        ) : (
          <div className="flex flex-wrap items-center gap-2">
            {shippingMethods.map((e) => {
              const isActive = code === e.code;
              return (
                <Button
                  key={e.code}
                  type="button"
                  className="text-sm px-2 py-1 rounded-md"
                  variant={isActive ? "primary" : "secondary"}
                  light={!isActive}
                  disabled={hasLoading}
                  onClick={() => setCode(e.code)}
                >
                  <Icon name="TruckFast" variant="Bulk" /> {e.title}
                </Button>
              );
            })}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="flex items-center justify-end gap-4">
        <Button type="button" variant="danger" onClick={toggle}>
          <Text>button.cancel</Text>
        </Button>
        <Button
          type="button"
          variant="primary"
          onClick={submitShippingMethods}
          disabled={!code}
          loading={hasLoading}
        >
          <Text>button.submit</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
