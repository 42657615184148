import { cloneDeep } from "lodash";
import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router";
import { OfferDetailsContext } from ".";
import {
  Alert,
  Button,
  CheckBox,
  Drawer,
  Form,
  Icon,
  InputGroup,
  Text,
  Toggler,
} from "../../../components";
import { SecondSalesPersonCard } from "../../../containers";
import { useAxios, useToggle } from "../../../hooks";
import { Order, togglePropsType } from "../../../types";
import AddSecondSalesPerson from "./AddSecondSalesPerson";
type Data = Order.Create;
type AcceptOfferProps = togglePropsType & {
  signedDocuments: boolean;
  lineItemsHasShippingMethod: boolean;
  lineItemsHasShippingDate: boolean;
  hasDownPayment: boolean;
  isDownPaymentMoreThanTotalAmount: boolean;
};
export default function AcceptOffer({
  isOpen,
  toggle,
  signedDocuments,
  lineItemsHasShippingMethod,
  lineItemsHasShippingDate,
  hasDownPayment,
  isDownPaymentMoreThanTotalAmount,
}: AcceptOfferProps) {
  const navigate = useNavigate();
  const { axios, loading } = useAxios();
  const offerContext = useContext(OfferDetailsContext);
  const offer = offerContext?.offer;
  const [enabledDeadLine, toggleEnabledDeadLine] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [data, setData] = useState<Data>({});
  const [showAddSecondPerson, toggleAddSecondPerson] = useToggle(false);
  const secondSalesPerson = data.secondSalesPerson;
  const hasSecondSalesPerson = !!secondSalesPerson;
  const alert = useMemo(() => {
    const hasAddress = !!offer?.billingAddress && !!offer?.shippingAddress;
    const hasDocument = !!signedDocuments;
    if (!hasAddress)
      return {
        title: "alertTitles.addressRequired",
        desc: "alertDescriptions.addressRequired",
      };
    if (!hasDocument)
      return {
        title: "alertTitles.documentRequired",
        desc: "alertDescriptions.documentRequired",
      };
    if (!hasDownPayment)
      return {
        title: "alertTitles.paymentConditionRequired",
        desc: "alertDescriptions.paymentConditionRequired",
      };
    if (isDownPaymentMoreThanTotalAmount)
      return {
        title: "alertTitles.downPaymentMoreThanTotalAmount",
        desc: "alertDescriptions.downPaymentMoreThanTotalAmount",
      };
    if (!lineItemsHasShippingMethod)
      return {
        title: "alertTitles.allOfferLineItemMustHaveShippingMethod",
        desc: "alertDescriptions.allOfferLineItemMustHaveShippingMethod",
      };
    if (!lineItemsHasShippingDate)
      return {
        title: "alertTitles.allOfferLineItemMustHaveShippingDate",
        desc: "alertDescriptions.allOfferLineItemMustHaveShippingDate",
      };
    return null;
  }, [
    offer,
    signedDocuments,
    lineItemsHasShippingMethod,
    lineItemsHasShippingDate,
    hasDownPayment,
    isDownPaymentMoreThanTotalAmount,
  ]);
  const resetData = () => {
    if (isOpen) return;
    setConfirm(false);
  };
  const clearSecondSalesPerson = () => [
    setData((p) => ({ ...p, secondSalesPerson: null })),
  ];
  const handleSetValue = (key: keyof Data) => {
    return (value: any) => setData((p) => ({ ...p, [key]: value }));
  };
  const submit = () => {
    const url = "/salesservice/api/orders";
    const body = cloneDeep(data);
    body.offerId = offer?.id;
    body.title ||= null;
    body.orderNote ||= null;
    body.secondSalesPerson ||= null;
    body.taxFree ??= false;
    if (!enabledDeadLine) {
      body.customerConfirmationDeadline = null;
    }
    axios.post(url, body).then(({ data: orderId }) => {
      navigate(`/orders/${orderId}`);
      toast.success("toast.success.acceptOffer");
    });
  };
  useEffect(resetData, [isOpen]);
  return (
    <Fragment>
      <Drawer as={Form} onSubmit={submit} isOpen={isOpen} toggle={toggle}>
        <Drawer.Menu>
          <Drawer.Header>
            <h6>
              <Icon
                name="TickCircle"
                variant="Bold"
                size={16}
                className="text-success"
              />{" "}
              <Text>drawerTitles.acceptOffer</Text>
            </h6>
          </Drawer.Header>
          <Drawer.Header className="space-y-1">
            <Icon.Wrapper className="mx-auto">
              <Icon name="DiscountShape" variant="Bold" />
            </Icon.Wrapper>
            <h6>{offer?.title}</h6>
            <p className="text-secondary">#{offer?.number}</p>
          </Drawer.Header>
          <Drawer.Body className="space-y-4">
            <InputGroup
              label="formControls.orderTitle"
              value={data.title}
              setValue={handleSetValue("title")}
              // rules={rules.required}
            />
            <InputGroup
              as="textarea"
              label="formControls.orderNote"
              value={data.orderNote}
              setValue={handleSetValue("orderNote")}
              // rules={rules.required}
            />
            {/* <CheckBox
              label="formControls.orderEnableDeadline"
              value={enabledDeadLine}
              setValue={toggleEnabledDeadLine}
            />
            <InputGroup
              label="formControls.orderDeadline"
              value={data.customerConfirmationDeadline}
              setValue={handleSetValue("customerConfirmationDeadline")}
              type="date"
              minDate={now}
              disabled={!enabledDeadLine}
              rules={enabledDeadLine ? rules.required : undefined}
            /> */}
            {hasSecondSalesPerson ? (
              <SecondSalesPersonCard
                salesPerson={secondSalesPerson}
                onClear={clearSecondSalesPerson}
              />
            ) : (
              <section className="border rounded p-2 space-y-4">
                <h6 className="text-base text-dark">
                  <Text>formControls.orderSecondSalesPerson</Text>
                </h6>
                <Button
                  type="button"
                  variant="primary"
                  light
                  onClick={toggleAddSecondPerson}
                >
                  <Icon name="Add" />{" "}
                  <Text>button.addOrderSecondSalesPerson</Text>
                </Button>
              </section>
            )}
            <Toggler
              label="formControls.orderTaxFree"
              value={!!data.taxFree}
              setValue={handleSetValue("taxFree")}
            />
          </Drawer.Body>
          <Drawer.Footer className="space-y-4">
            {!!alert ? (
              <Alert variant="warning" light>
                <Alert.Title>
                  <Text>{alert.title}</Text>
                </Alert.Title>
                <Alert.Text className="leading-5">
                  <Text>{alert.desc}</Text>
                </Alert.Text>
              </Alert>
            ) : (
              <CheckBox
                value={confirm}
                setValue={setConfirm}
                label="confirms.acceptOffer"
              />
            )}
            <div className="flex items-center justify-end gap-4">
              <Button variant="danger" onClick={toggle}>
                <Text>button.cancelForNow</Text>
              </Button>
              <Button
                variant="success"
                type="submit"
                disabled={!confirm}
                loading={loading.post}
              >
                <Text>button.submitOffer</Text>
              </Button>
            </div>
          </Drawer.Footer>
        </Drawer.Menu>
      </Drawer>
      <AddSecondSalesPerson
        isOpen={showAddSecondPerson}
        toggle={toggleAddSecondPerson}
        onSubmit={handleSetValue("secondSalesPerson")}
      />
    </Fragment>
  );
}
