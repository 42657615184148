import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";
import Loading from "./_Loading";

type listProps = {
  loading?: boolean;
  loadingLength?: number;
} & ComponentProps<"ul">;
type listItemProps = { disabled?: boolean } & Omit<
  ComponentProps<"li">,
  "disabled"
>;
function List({
  loading = false,
  loadingLength,
  className,
  children,
  ...props
}: listProps) {
  return (
    <ul className={twMerge("", className)} {...props}>
      {loading ? <Loading.List length={loadingLength} /> : children}
    </ul>
  );
}
function ListItem({
  className,
  children,
  disabled,
  onClick,
  ...props
}: listItemProps) {
  const clickable = !!onClick;
  return (
    <li
      className={twMerge(
        "flex items-center w-full text-start gap-4 py-3 border-b border-dashed border-gray-100 last:border-none transition-opacity",
        clickable && "cursor-pointer select-none",
        disabled && "cursor-not-allowed opacity-50",
        className
      )}
      onClick={disabled ? undefined : onClick}
      {...props}
    >
      {children}
    </li>
  );
}
List.Item = ListItem;
export default List;
