import { useContext, useState } from "react";
import { OfferDetailsContext } from "..";
import { Icon, Text, Toggler, Wrapper } from "../../../../components";
import { useAxios } from "../../../../hooks";
import { OrderDetailsContext } from "../../../Orders/Details";

export default function IsFinance() {
  const { axios, loading } = useAxios();
  const offerContext = useContext(OfferDetailsContext);
  const orderContext = useContext(OrderDetailsContext);
  const offer = offerContext?.offer;
  const order = orderContext?.order;
  const updateOffer = offerContext?.updateOffer;
  const updateOrder = orderContext?.updateOrder;
  const updateOfferLoading = offerContext?.updateOfferLoading;
  const updateOrderLoading = orderContext?.updateOrderLoading;
  const isOffer = !!offer;
  const isOrder = !!order;
  const isEnabled = offerContext?.isEnabled ?? orderContext?.isEnabled;
  const initIsFinanced = offer?.isFinanced ?? order?.isFinanced ?? false;
  const disabled = !isEnabled || loading.post;
  const hasLoading = [
    loading.post,
    updateOfferLoading,
    updateOrderLoading,
  ].some(Boolean);
  const [isFinanced, setIsFinanced] = useState(initIsFinanced);
  const handleChange = (value: boolean) => {
    const url = [
      "/salesservice",
      "api",
      isOffer && "offer",
      isOrder && "orders",
      offer?.id || order?.id,
      "is-financed",
    ]
      .filter(Boolean)
      .join("/");
    const body = { isFinanced: value };
    setIsFinanced(value);
    axios
      .post(url, body)
      .then(() => {
        updateOffer?.();
        updateOrder?.();
      })
      .catch(() => {
        setIsFinanced(initIsFinanced);
      });
  };
  return (
    <Wrapper className="lg:!col-span-2 self-start">
      <Wrapper.Body className="space-y-2">
        <Toggler
          className={hasLoading ? "loading bg-transparent" : undefined}
          label="formControls.financing"
          value={isFinanced}
          setValue={handleChange}
          disabled={disabled}
        />
        <p className="text-sm font-medium text-[#A1A4AC]">
          <Icon name="InfoCircle" />{" "}
          {isOffer && <Text>global.offerFinancingInfo</Text>}
          {isOrder && <Text>global.orderFinancingInfo</Text>}
        </p>
      </Wrapper.Body>
    </Wrapper>
  );
}
