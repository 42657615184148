import { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { CustomerContext } from "..";
import {
  Button,
  Drawer,
  Icon,
  Loading,
  NoItems,
  Text,
} from "../../../../components";
import { config } from "../../../../constants";
import { SalesStatus } from "../../../../enums";
import { useAxios, useConverters } from "../../../../hooks";
import { Order } from "../../../../types";

interface ChooseOrderProps {
  isOpen?: boolean;
  toggle: () => void;
  getCommissionsData: () => void;
  orders?: any;
  setOrders?: any;
  id?: string;
  deliveryDate: string;
}

export default function ChooseOrderEdit({
  isOpen = false,
  toggle,
  orders,
  getCommissionsData,
  setOrders,
  id,
  deliveryDate,
}: ChooseOrderProps) {
  const { axios, loading } = useAxios();
  const { customerData } = useContext(CustomerContext);
  const { convertAmount, convertDate } = useConverters();
  const [orderList, setOrderList] = useState<any>();
  const [ids, setIds] = useState<any>([]);
  const [params, setParams] = useState<Order.Params>({
    customerId: customerData.id,
    pageNumber: 1,
    pageSize: config.pageSize,
    status: SalesStatus.None,
    channelCode: null,
  });
  const getData = () => {
    const url = `salesservice/api/orders`;
    const config = { params };
    axios.get(url, config).then(({ data }: any) => {
      const ids = orders?.map((order: any) => order.orderId);

      const list = data?.items?.filter((order: any) => !ids.includes(order.id));
      setOrderList(list);
    });
  };

  useEffect(() => {
    if (isOpen) {
      getData();
    } else {
      setIds([]);
    }
  }, [isOpen]);

  const onChooseHandler = () => {
    const url = `salesservice/api/commissiongroups/${id}/order`;
    const body = { orderIds: ids };
    axios.post(url, body).then(() => {
      const message = "commissions.updateCommissionSuccessfuly";
      toast.success(message);
      getCommissionsData();
      const newOrders = [
        ...orders,
        ...orderList?.filter((order: any) => ids.includes(order.id)),
      ];
      setOrders(newOrders);
      toggle();
      //   toggleEdit();
    });
  };

  return (
    <Drawer isOpen={isOpen} toggle={toggle} className="z-[31]">
      <Drawer.Menu>
        <Drawer.Header>
          <Text>commissions.chooseOrder</Text>
        </Drawer.Header>
        <Drawer.Body className="space-y-4">
          {loading.get ? (
            <Loading.Inline />
          ) : (
            <>
              {orderList?.length ? (
                <>
                  {orderList?.map((order: any) => {
                    const isSelected = ids.includes(order.id);
                    const dDate = deliveryDate
                      ? new Date(deliveryDate).toISOString()
                      : new Date().toISOString();
                    const disableDate = dDate <= order?.desiredDeliveryDate;
                    return (
                      <div
                        key={order?.id}
                        className={`flex space-x-2 border justify-between rounded-md p-2 cursor-pointer ${
                          isSelected ? "bg-light" : " "
                        } ${
                          disableDate
                            ? "opacity-70 cursor-not-allowed"
                            : "cursor-pointer hover:bg-light "
                        }`}
                        onClick={() => {
                          if (!disableDate) {
                            if (!isSelected) {
                              const cloneIds = [...ids];
                              cloneIds.push(order.id);
                              setIds(cloneIds);
                            } else {
                              const cloneIds = ids?.filter(
                                (id: string) => id !== order.id
                              );
                              setIds(cloneIds);
                            }
                          }
                        }}
                      >
                        <div className="flex space-x-2 ">
                          <Icon
                            name="ReceiptText"
                            className="text-primary"
                            variant="Bold"
                          />
                          <div className="flex flex-col space-y-2">
                            <p className="text-sm font-medium">
                              {order?.title}
                            </p>
                            <p className="text-xs text-secondary">
                              #{order.number}
                            </p>
                            <span className="flex items-center gap-1">
                              <p className="text-xs text-gray-600 space-x-1">
                                <Icon
                                  name="LocationTick"
                                  className="text-danger text-sm"
                                />
                                <span>{order?.store?.storeName}</span>
                              </p>
                            </span>
                            <p className="text-xs flex items-center">
                              <Icon
                                name="Calendar"
                                className="mr-2 text-primary"
                                variant="Bulk"
                              />
                              <span className="text-gray-400 mr-2">
                                <Text>commissions.delivaryDate</Text>
                              </span>
                              <span className="text-gray-500">
                                {convertDate(order?.desiredDeliveryDate)}
                              </span>
                            </p>
                          </div>
                        </div>
                        <p className="text-sm text-gray-500">
                          {convertAmount(order.totalAmount)}
                        </p>
                      </div>
                    );
                  })}
                </>
              ) : (
                <NoItems />
              )}
            </>
          )}
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-end gap-4">
          <Button type="button" variant="danger" onClick={toggle}>
            <Text>button.cancel</Text>
          </Button>
          <Button
            type="button"
            onClick={onChooseHandler}
            loading={loading.post}
            disabled={!ids?.length}
          >
            <Text>commissions.assign</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
