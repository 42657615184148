type DateTime = string | number | Date;
type DateInput = {
  start: DateTime | null;
  end: DateTime | null;
};

function toNumber(date: DateTime) {
  if (typeof date === "number") return date;
  return new Date(date).getTime();
}

export function isBetweenDate(
  now: DateTime,
  fromDate: DateTime,
  toDate: DateTime
) {
  return (
    (!fromDate || toNumber(now) >= toNumber(fromDate)) &&
    (!toDate || toNumber(now) <= toNumber(toDate))
  );
}

export default function datesHasConflict(range1: DateInput, range2: DateInput) {
  const range1StartEffective = !!range1.start ? toNumber(range1.start) : 0;
  const range1EndEffective = !!range1.end ? toNumber(range1.end) : Infinity;
  const range2StartEffective = !!range2.start ? toNumber(range2.start) : 0;
  const range2EndEffective = !!range2.end ? toNumber(range2.end) : Infinity;

  const range1StartInRange2 = isBetweenDate(
    range1StartEffective,
    range2StartEffective,
    range2EndEffective
  );
  const range1EndInRange2 = isBetweenDate(
    range1EndEffective,
    range2StartEffective,
    range2EndEffective
  );
  const range2StartInRange1 = isBetweenDate(
    range2StartEffective,
    range1StartEffective,
    range1EndEffective
  );
  const range2EndInRange1 = isBetweenDate(
    range2EndEffective,
    range1StartEffective,
    range1EndEffective
  );

  return (
    range1StartInRange2 ||
    range1EndInRange2 ||
    range2StartInRange1 ||
    range2EndInRange1
  );
}
