import { cloneDeep } from "lodash";
import {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import toast from "react-hot-toast";
import { twMerge } from "tailwind-merge";
import { LineItemsContext } from "..";
import { OfferDetailsContext } from "../..";
import {
  Accordion,
  Alert,
  Button,
  Drawer,
  Icon,
  Image,
  Loading,
  Status,
  Text,
  Types,
  Wrapper,
} from "../../../../../components";
import {
  ErpStatusHistory,
  PriceEngineViewer,
  SetDesireDeliveryDate,
} from "../../../../../containers";
import { LineItemType } from "../../../../../enums";
import { useAxios, useConverters, useToggle } from "../../../../../hooks";
import { cn } from "../../../../../methods";
import {
  LineItem,
  Program,
  ShippingMethod,
  togglePropsType,
} from "../../../../../types";
import { OrderDetailsContext } from "../../../../Orders/Details";
import Media from "./Media";
import ProductInventory from "./ProductInventory";

type LineItemInfoProps = togglePropsType & {
  lineItem: LineItem.Item;
  onEdit: (key: keyof LineItem.Item) => void;
  onAddSubLineItem: () => void;
  onRemove: () => void;
  onCancel: () => void;
  canCancel: boolean;
  canRemove: boolean;
};

export default function LineItemInfo({
  isOpen,
  toggle,
  lineItem,
  onEdit,
  onAddSubLineItem,
  onRemove,
  onCancel,
  canCancel,
  canRemove,
}: LineItemInfoProps) {
  const statusHistoryVariantRef = useRef<"salesStatuses" | "purchaseStatuses">(
    "salesStatuses"
  );
  const { axios, loading } = useAxios();
  const { axios: brandAxios, loading: brandLoading } = useAxios();
  const offerContext = useContext(OfferDetailsContext);
  const orderContext = useContext(OrderDetailsContext);
  const { lineItems } = useContext(LineItemsContext);
  const { convertNumber } = useConverters();
  const [brand, setBrand] = useState<Program.Brand | null>(null);

  const offer = offerContext?.offer;
  const order = orderContext?.order;

  const isOffer = !!offer;
  const isOrder = !!order;
  const updateOffer = offerContext?.updateOffer;
  const updateOrder = orderContext?.updateOrder;
  const updateOfferLoading = offerContext?.updateOfferLoading;
  const updateOrderLoading = orderContext?.updateOrderLoading;
  const isCanceled = !!lineItem.isCanceled;
  const parent = lineItems.find((e) => e.id === lineItem.parentId);
  const isBundle = lineItem.lineItemType === LineItemType.ManualBundle;
  const isPimBundleItem = lineItem.lineItemType === LineItemType.PimBundleItem;
  const parentIsBundle = parent?.lineItemType === LineItemType.ManualBundle;
  const hasBrandLogo = brand?.logo?.url;
  const isSellOffProduct = [
    LineItemType.SellOffProduct,
    LineItemType.SellOffBundle,
    LineItemType.SellOffBundleItem,
  ].includes(lineItem.lineItemType);
  const isEnabled = offerContext?.isEnabled ?? orderContext?.isEnabled;
  const isOrderPublished = !!orderContext?.isPublished;
  const canUpdate = useMemo(() => {
    if (isOffer) return !isPimBundleItem && isEnabled;
    return !isOrder;
  }, [isOffer, isOrder, isEnabled, isPimBundleItem]);
  const showOrderPublishedAlert = [
    !canCancel,
    !canRemove,
    !isCanceled,
    isOrderPublished,
  ].every(Boolean);
  const shippingMethodLoading = [
    loading.get,
    loading.update,
    updateOfferLoading,
    updateOrderLoading,
  ].some(Boolean);
  const deliveryDateLoading = [loading.post].some(Boolean);
  const { setLineItems } = useContext(LineItemsContext);
  const [showDatePicker, toggleDatePicker] = useToggle(false);
  const [showStatusHistory, toggleStatusHistory] = useToggle(false);
  const [shippingMethods, setShippingMethods] = useState<ShippingMethod[]>([]);
  const hasDeliveryDate = !!lineItem.desiredDeliveryDate;
  const isSubLineItem = !!lineItem.parentId;
  const productGroup = lineItem.productGroup;
  const hasProductGroup = !!productGroup;
  const canUpdateQuantity =
    isEnabled && !isCanceled && !isPimBundleItem && !isSellOffProduct;
  const canUpdatePrice = useMemo(() => {
    if (!isEnabled) return false;
    if (isCanceled) return false;
    if (isBundle) return false;
    if (isSellOffProduct) return false;
    return true;
  }, [isEnabled, isCanceled, isBundle, isSellOffProduct]);
  const handleOnEdit = (key: keyof LineItem.Item) => {
    toggle();
    onEdit(key);
  };
  const handleOnAddSubLineItem = () => {
    onAddSubLineItem();
    toggle();
  };
  const handleOnRemove = () => {
    onRemove();
    toggle();
  };
  const handleOnCancel = () => {
    onCancel();
    toggle();
  };
  const handleToggleHistory = (key: typeof statusHistoryVariantRef.current) => {
    if (!isOrderPublished) return undefined;
    return () => {
      statusHistoryVariantRef.current = key;
      toggleStatusHistory();
    };
  };
  const getShippingMethods = () => {
    if (!isOpen) return;
    const url = "/productservice/api/shippingmethods";
    const config = { cache: true };
    axios.get(url, config).then(({ data }) => {
      setShippingMethods(data);
    });
  };
  const submitOfferLienItemShippingMethod = (code: string) => {
    if (lineItem.shippingMethodCode === code) return;
    const url = `/salesservice/api/offerlineitem/${lineItem.id}`;
    const body: LineItem.Edit = {
      offerLineItemId: lineItem.id,
      offerId: lineItem.offerId,
      title: lineItem.title,
      lineItemNote: lineItem.lineItemNote,
      supplierNote: lineItem.supplierNote,
      customerNote: lineItem.customerNote,
      ean: lineItem.ean,
      description: lineItem.description,
      configurationText: lineItem.configurationText,
      lineItemType: lineItem.lineItemType,
      shippingMethodCode: code,
    };
    const isSubLineItem = !!lineItem.parentId;
    axios.put(url, body).then(() => {
      updateOffer?.().then(() => {
        const message = isSubLineItem
          ? "toast.success.editSubLineItem"
          : "toast.success.editLineItem";
        toast.success(message);
        setLineItems((p) => {
          const lineItems = cloneDeep(p);
          lineItems.forEach((e, i) => {
            const needChange = [e.id, e.parentId].includes(lineItem.id);
            if (needChange) {
              lineItems[i].shippingMethodCode = code;
            }
          });
          return lineItems;
        });
      });
    });
  };
  const submitOrderLienItemShippingMethod = (code: string) => {
    const url = `/salesservice/api/orderlineitems/${lineItem.id}`;
    const body: LineItem.Edit = {
      orderId: lineItem.orderId,
      orderLineItemId: lineItem.id,
      title: lineItem.title,
      lineItemNote: lineItem.lineItemNote,
      supplierNote: lineItem.supplierNote,
      customerNote: lineItem.customerNote,
      ean: lineItem.ean,
      description: lineItem.description,
      configurationText: lineItem.configurationText,
      lineItemType: lineItem.lineItemType,
      shippingMethodCode: code,
    };
    axios.put(url, body).then(() => {
      updateOrder?.().then(() => {
        const message = isSubLineItem
          ? "toast.success.editSubLineItem"
          : "toast.success.editLineItem";
        toast.success(message);
        setLineItems((p) => {
          const lineItems = cloneDeep(p);
          lineItems.forEach((e, i) => {
            const needChange = [e.id, e.parentId].includes(lineItem.id);
            if (needChange) {
              lineItems[i].shippingMethodCode = code;
            }
          });
          return lineItems;
        });
      });
    });
  };
  const getBrand = () => {
    const id = lineItem.supplierProgram?.supplierProgramId;
    if (!isOpen || !id) return;
    const url = `/salesservice/api/supplierprograms/${id}/brand-by-programid`;
    const config = { cache: true };
    brandAxios.get(url, config).then(({ data }) => {
      setBrand(data);
    });
  };
  const submitShippingMethod = (code: string) => {
    if (code === lineItem.shippingMethodCode) return;
    isOffer && submitOfferLienItemShippingMethod(code);
    isOrder && submitOrderLienItemShippingMethod(code);
  };

  useEffect(getShippingMethods, [isOpen]);
  useEffect(getBrand, [isOpen, lineItem.supplierProgram]);
  return (
    <Fragment>
      <Drawer isOpen={isOpen} toggle={toggle}>
        <Drawer.Menu className="bg-[#F5F7F9]">
          <Drawer.Header className="flex items-center gap-2 text-start">
            <h6 className="flex-1 text-dark text-base">
              <Text>
                {isBundle
                  ? "drawerTitles.bundle"
                  : isSubLineItem
                  ? "drawerTitles.subLineItem"
                  : "drawerTitles.lineItem"}
              </Text>
            </h6>
            {isEnabled && !isCanceled && (
              <Fragment>
                {isBundle && (
                  <Button
                    type="button"
                    variant="primary"
                    onClick={handleOnAddSubLineItem}
                  >
                    <Icon variant="Bold" name="BoxAdd" />{" "}
                    <Text>button.addSubLineItem</Text>
                  </Button>
                )}
                {canCancel && (
                  <Button
                    type="button"
                    variant="danger"
                    onClick={handleOnCancel}
                  >
                    <Icon variant="Bold" name="CloseCircle" />{" "}
                    <Text>button.cancelOrderLineItem</Text>
                  </Button>
                )}
                {canRemove && (
                  <Button
                    type="button"
                    variant="danger"
                    onClick={handleOnRemove}
                  >
                    <Icon variant="Bold" name="Trash" />
                  </Button>
                )}
              </Fragment>
            )}
          </Drawer.Header>
          {showOrderPublishedAlert && (
            <Drawer.Header className="border-b-0">
              <div className="bg-white">
                <Alert variant="danger" light>
                  <Alert.Text className="text-start">
                    <Text>errorCodes.SS8056</Text>
                  </Alert.Text>
                </Alert>
              </div>
            </Drawer.Header>
          )}
          <Drawer.Body className="space-y-3">
            <Accordion defaultActiveKey={["info"]}>
              {isCanceled && (
                <Alert icon="Danger" variant="danger" light>
                  <Alert.Title>
                    <Text>alertTitles.canceledOrderLineItem</Text>
                  </Alert.Title>
                  <Alert.Text>
                    <Text>alertDescriptions.canceledOrderLineItem</Text>
                  </Alert.Text>
                </Alert>
              )}
              <Wrapper>
                <Wrapper.Body className="flex-center text-start gap-4">
                  {!isBundle && (
                    <Icon.Wrapper variant="primary">
                      <Icon name="Box1" variant="Bold" size={28} />
                    </Icon.Wrapper>
                  )}
                  <div className="flex-1 flex flex-col items-start gap-1">
                    <span className="block">{lineItem.title}</span>
                    <div className="flex items-center flex-wrap gap-2">
                      {!isBundle && (
                        <Types.LineItem id={lineItem.lineItemType} />
                      )}
                    </div>
                  </div>
                  {canUpdate && (
                    <button
                      type="button"
                      className="text-primary self-start text-sm"
                      onClick={() => handleOnEdit("title")}
                    >
                      <Icon name="Edit2" />
                    </button>
                  )}
                </Wrapper.Body>
              </Wrapper>
              <Wrapper>
                <Accordion.Item eventKey="info">
                  <Accordion.Toggle className="flex-row-reverse">
                    <h6 className="text-sm text-dark flex-1 text-start">
                      <Text>global.infoTitle</Text>
                    </h6>
                  </Accordion.Toggle>
                  <Accordion.Body className="text-start gap-4 py-1">
                    <table className="w-full text-sm font-medium [&_td]:py-3">
                      <tbody className="divide-y divide-dashed">
                        {!isBundle && (
                          <tr>
                            <td className="text-secondary text-start whitespace-nowrap">
                              <Icon name="Hashtag" variant="Bold" />{" "}
                              <Text>global.lineItemProductTitle</Text>
                            </td>
                            <td className="text-gray-900 text-end">
                              {lineItem.product?.title ?? "--"}
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td className="text-secondary text-start whitespace-nowrap">
                            <Icon name="Hashtag" variant="Bold" />{" "}
                            <Text>global.articleNumber</Text>
                          </td>
                          <td className="text-gray-900 text-end">
                            {lineItem.product?.articleNumber}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-secondary text-start whitespace-nowrap">
                            <Icon name="Building" variant="Bold" />{" "}
                            <Text>global.supplierName</Text>
                          </td>
                          <td className="text-gray-900 text-end">
                            {lineItem.supplier?.name ?? "--"}
                          </td>
                        </tr>
                        {!isBundle && (
                          <tr>
                            <td className="text-secondary text-start whitespace-nowrap">
                              <Icon name="Building" variant="Bold" />{" "}
                              <Text>global.supplierArticleNumber</Text>
                            </td>
                            <td className="text-gray-900 text-end">
                              {lineItem.supplier?.supplierArticleNumber ?? "--"}
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td className="text-secondary text-start whitespace-nowrap">
                            <Icon name="Document" variant="Bold" />{" "}
                            <Text>global.programName</Text>
                          </td>
                          <td className="text-gray-900 text-end">
                            {lineItem.supplierProgram?.name ?? "--"}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-secondary text-start whitespace-nowrap">
                            <Icon name="Document" variant="Bold" />{" "}
                            <Text>global.brandName</Text>
                          </td>
                          <td className="text-gray-900 text-end">
                            {hasBrandLogo && (
                              <Image
                                src={brand?.logo?.url}
                                alt={brand?.name}
                                className="inline-block rounded h-7"
                              />
                            )}{" "}
                            <span
                              className={cn(
                                "inline-block",
                                brandLoading.get && "loading w-10 h-4"
                              )}
                            >
                              {brand?.name ?? "--"}
                            </span>
                          </td>
                        </tr>
                        {!isBundle && (
                          <tr>
                            <td className="text-secondary text-start whitespace-nowrap">
                              <Icon name="TableDocument" variant="Bold" />{" "}
                              <Text>global.lineItemProductGroupTitle</Text>
                            </td>
                            <td className="text-gray-900 text-end">
                              {hasProductGroup
                                ? `${productGroup.title} (#${productGroup.code})`
                                : "--"}
                            </td>
                          </tr>
                        )}
                        {isOrder && (
                          <Fragment>
                            <tr>
                              <td
                                colSpan={2}
                                className="text-secondary text-start whitespace-nowrap"
                              >
                                <Icon name="TableDocument" variant="Bold" />{" "}
                                <Text>global.lineItemStatusInfo</Text>
                              </td>
                            </tr>
                            <tr className="!border-t-0">
                              <td colSpan={2} className="!pt-0">
                                <div className="w-full flex flex-wrap items-center gap-2">
                                  <Status.Sales
                                    statuses={lineItem.salesStatuses}
                                    title="page.lineItems.statusTitle.sales"
                                    className={twMerge(
                                      "p-[0.375rem] text-xs leading-none",
                                      isOrderPublished && "cursor-pointer"
                                    )}
                                    isOrderPublished={isOrderPublished}
                                    onClick={handleToggleHistory(
                                      "salesStatuses"
                                    )}
                                  />
                                  {isOrderPublished && (
                                    <Status.Purchase
                                      statuses={lineItem.purchaseStatuses}
                                      title="page.lineItems.statusTitle.purchase"
                                      className={twMerge(
                                        "p-[0.375rem] text-xs leading-none",
                                        isOrderPublished && "cursor-pointer"
                                      )}
                                      isOrderPublished
                                      onClick={handleToggleHistory(
                                        "purchaseStatuses"
                                      )}
                                    />
                                  )}
                                </div>
                              </td>
                            </tr>
                          </Fragment>
                        )}
                      </tbody>
                    </table>
                  </Accordion.Body>
                </Accordion.Item>
              </Wrapper>
              <Wrapper>
                <Accordion.Item>
                  <Accordion.Toggle className="flex-row-reverse">
                    <h6 className="text-sm text-dark flex-1 text-start">
                      <Text>global.logisticsTitle</Text>
                    </h6>
                  </Accordion.Toggle>
                  <Accordion.Body>
                    <Wrapper.Body className="pt-0 space-y-4">
                      <h6 className="text-dark text-base">
                        <Text>global.shippingMethodsText</Text>:
                      </h6>
                      {shippingMethodLoading ? (
                        <Loading.ShippingMethod />
                      ) : (
                        <div className="flex flex-wrap items-center gap-2">
                          {shippingMethods.map((e) => {
                            const isActive =
                              lineItem.shippingMethodCode === e.code;
                            return (
                              <Button
                                key={e.code}
                                type="button"
                                className="text-sm px-2 py-1 rounded-md"
                                variant={isActive ? "primary" : "secondary"}
                                light={!isActive}
                                disabled={
                                  !isEnabled || isCanceled || isSubLineItem
                                }
                                onClick={() => submitShippingMethod(e.code)}
                              >
                                <Icon name="TruckFast" variant="Bulk" />{" "}
                                {e.title}
                              </Button>
                            );
                          })}
                        </div>
                      )}
                    </Wrapper.Body>
                    <Wrapper.Footer className="flex items-center justify-between gap-4">
                      <h6 className="flex-1 text-dark text-sm">
                        <Text>global.desireDeliveryDateTitle</Text>
                      </h6>
                      {hasDeliveryDate ? (
                        <button
                          type="button"
                          data-loading={deliveryDateLoading}
                          className="text-sm text-start text-dark data-loading:animate-pulse data-loading:cursor-wait"
                          onClick={toggleDatePicker}
                          disabled={!isEnabled || isCanceled || isSubLineItem}
                        >
                          <Text>global.calendarWeek</Text>{" "}
                          {lineItem.desiredDeliveryCalendarWeek
                            ?.calendarWeekNumber ?? "--"}{" "}
                          <Icon
                            name="Edit2"
                            size="1.2em"
                            className="text-primary"
                          />
                        </button>
                      ) : (
                        <Button
                          type="button"
                          light
                          className="btn-sm"
                          onClick={toggleDatePicker}
                          loading={deliveryDateLoading}
                          disabled={!isEnabled || isCanceled || isSubLineItem}
                        >
                          <Icon name="Calendar" variant="Bold" />{" "}
                          <Text>button.setLineItemDesireDeliveryDate</Text>
                        </Button>
                      )}
                      {isEnabled && !isCanceled && (
                        <SetDesireDeliveryDate
                          isOpen={showDatePicker}
                          toggle={toggleDatePicker}
                          lineItem={lineItem}
                        />
                      )}
                    </Wrapper.Footer>
                  </Accordion.Body>
                </Accordion.Item>
              </Wrapper>
              {!isBundle && isEnabled && !isCanceled && (
                <ProductInventory force={isOpen} lineItem={lineItem} />
              )}
              <Wrapper>
                <Wrapper.Body className="flex items-center gap-4">
                  <h6 className="flex-1 text-sm text-dark">
                    <Text>formControls.quantity</Text>
                  </h6>
                  <p className="text-base text-secondary">
                    x{convertNumber(lineItem.quantity)}
                  </p>
                  {canUpdateQuantity && (
                    <button
                      type="button"
                      className="text-primary text-sm"
                      onClick={() => handleOnEdit("quantity")}
                    >
                      <Icon name="Edit2" />
                    </button>
                  )}
                </Wrapper.Body>
              </Wrapper>
              <Wrapper>
                <Accordion.Item>
                  <div className="flex items-center">
                    <Accordion.Toggle className="flex-1 flex-row-reverse">
                      <h6 className="flex-1 text-sm text-dark text-start">
                        <Text>global.price</Text>
                      </h6>
                    </Accordion.Toggle>
                    {canUpdatePrice && (
                      <button
                        type="button"
                        className="text-primary text-sm"
                        onClick={() => handleOnEdit("originalPrice")}
                      >
                        <Icon name="Edit2" />
                      </button>
                    )}
                  </div>
                  <Accordion.Body>
                    <PriceEngineViewer
                      data={{
                        originalPrice: lineItem.originalPrice,
                        productPrice: lineItem.unitPrice,
                        discounts: lineItem.discounts,
                        finalPrice: lineItem.unitPriceWithDiscount,
                      }}
                      quantity={lineItem.quantity}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Wrapper>
              <Wrapper>
                <Accordion.Item>
                  <div className="flex items-center">
                    <Accordion.Toggle className="flex-1 flex-row-reverse">
                      <h6 className="flex-1 text-sm text-dark text-start">
                        <Text>formControls.description</Text>
                      </h6>
                    </Accordion.Toggle>
                    {canUpdate && (
                      <button
                        type="button"
                        className="text-primary text-sm"
                        onClick={() => handleOnEdit("description")}
                      >
                        <Icon name="Edit2" />
                      </button>
                    )}
                  </div>
                  <Accordion.Body>
                    <p className="text-sm text-gray-900">
                      {lineItem.description}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Wrapper>
              <Wrapper>
                <Accordion.Item>
                  <div className="flex items-center">
                    <Accordion.Toggle className="flex-1 flex-row-reverse">
                      <h6 className="flex-1 text-sm text-dark text-start">
                        <Text>formControls.lineItemNote</Text>
                      </h6>
                    </Accordion.Toggle>
                    {canUpdate && (
                      <button
                        type="button"
                        className="text-primary text-sm"
                        onClick={() => handleOnEdit("lineItemNote")}
                      >
                        <Icon name="Edit2" />
                      </button>
                    )}
                  </div>
                  <Accordion.Body>
                    <p className="text-sm text-gray-900">
                      {lineItem.lineItemNote ?? "--"}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Wrapper>
              <Wrapper>
                <Accordion.Item>
                  <div className="flex items-center">
                    <Accordion.Toggle className="flex-1 flex-row-reverse">
                      <h6 className="flex-1 text-sm text-dark text-start">
                        <Text>formControls.lineItemCustomerNote</Text>
                      </h6>
                    </Accordion.Toggle>
                    {canUpdate && (
                      <button
                        type="button"
                        className="text-primary text-sm"
                        onClick={() => handleOnEdit("customerNote")}
                      >
                        <Icon name="Edit2" />
                      </button>
                    )}
                  </div>
                  <Accordion.Body>
                    <p className="text-sm text-gray-900">
                      {lineItem.customerNote ?? "--"}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Wrapper>
              <Wrapper>
                <Accordion.Item>
                  <div className="flex items-center">
                    <Accordion.Toggle className="flex-1 flex-row-reverse">
                      <h6 className="flex-1 text-sm text-dark text-start">
                        <Text>formControls.lineItemSupplierNote</Text>
                      </h6>
                    </Accordion.Toggle>
                    {canUpdate && (
                      <button
                        type="button"
                        className="text-primary text-sm"
                        onClick={() => handleOnEdit("supplierNote")}
                      >
                        <Icon name="Edit2" />
                      </button>
                    )}
                  </div>
                  <Accordion.Body>
                    <p className="text-sm text-gray-900">
                      {lineItem.supplierNote ?? "--"}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Wrapper>
              {/* {(isBundle || parentIsBundle) && ( */}
              <Wrapper>
                <Accordion.Item>
                  <div className="flex items-center">
                    <Accordion.Toggle className="flex-1 flex-row-reverse">
                      <h6 className="flex-1 text-sm text-dark text-start">
                        <Text>formControls.lineItemConfigurationText</Text>
                      </h6>
                    </Accordion.Toggle>
                    {canUpdate && (
                      <button
                        type="button"
                        className="text-primary text-sm"
                        onClick={() => handleOnEdit("configurationText")}
                      >
                        <Icon name="Edit2" />
                      </button>
                    )}
                  </div>
                  <Accordion.Body>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: lineItem.configurationText ?? "--",
                      }}
                      className="text-sm text-gray-900"
                    ></div>
                  </Accordion.Body>
                </Accordion.Item>
              </Wrapper>
              {/* )} */}
              {!isBundle && (
                <Media
                  force={isOpen}
                  lineItem={lineItem}
                  isEnabled={isEnabled}
                />
              )}
            </Accordion>
          </Drawer.Body>
        </Drawer.Menu>
      </Drawer>
      <ErpStatusHistory
        isOpen={showStatusHistory}
        toggle={toggleStatusHistory}
        variant={statusHistoryVariantRef.current}
        lineItem={lineItem}
      />
    </Fragment>
  );
}
