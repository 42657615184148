import { useContext, useMemo } from "react";
import { ProgramDetailsContext } from "..";
import { Icon, Image, Text, Wrapper } from "../../../../../components";
import { useToggle } from "../../../../../hooks";

export default function GeneralInfo() {
  const { program } = useContext(ProgramDetailsContext);
  const programCode = program?.code;
  const brand = program?.brand;
  const hasBrand = !!brand;
  const productGroup = program?.pimProductGroup;
  const hasProductGroup = !!productGroup;
  const salesPersonProvision = program?.salesPersonProvision;
  const hasSalesPersonProvision = salesPersonProvision;
  const [showSalesProvision, toggleSalesProvision] = useToggle(false);
  const salesPersonProvisionValue = useMemo(() => {
    if (!salesPersonProvision) return "--";
    if (!showSalesProvision) return "*****";
    return `${salesPersonProvision}%`;
  }, [showSalesProvision, salesPersonProvision]);
  const generalItem = [
    { label: "suppliers.programGeneral.programTitle", value: program?.title },
    {
      label: "suppliers.programGeneral.code",
      value: programCode ? "#" + program?.code : "--",
    },
    {
      label: "suppliers.programGeneral.iwofurnCode",
      value: program?.iwofurnCode || "--",
    },
    { label: "suppliers.programGeneral.zrn", value: program?.zrNumber || "--" },
    {
      label: "suppliers.programGeneral.salesPersonProvision",
      value: hasSalesPersonProvision ? (
        <button
          type="button"
          onClick={toggleSalesProvision}
          className="flex items-center gap-1"
        >
          <Icon
            name={showSalesProvision ? "Eye" : "EyeSlash"}
            className="text-primary size-5"
          />
          {salesPersonProvisionValue}
        </button>
      ) : (
        "--"
      ),
    },
    {
      label: "suppliers.programGeneral.brand",
      value: hasBrand ? (
        <span className="flex flex-col items-start gap-1">
          <span>{brand.name}</span>
          {!!brand.logo?.url && (
            <Image
              src={brand.logo?.url}
              alt={brand.name}
              isDynamic
              className="inline-block rounded h-7"
            />
          )}
        </span>
      ) : (
        "--"
      ),
    },
    {
      label: "suppliers.programGeneral.productGroup",
      value: hasProductGroup
        ? `${productGroup.name} (${productGroup.code})`
        : "--",
    },
    {
      label: "suppliers.programGeneral.description",
      value: program?.shortDescription || "--",
    },
  ];
  return (
    <Wrapper>
      <Wrapper.Header className="border-b-0">
        <h2 className="text-dark text-sm font-medium">
          <Text>suppliers.programGeneral.title</Text>
        </h2>
      </Wrapper.Header>
      <Wrapper.Body>
        <ul className="w-full divide-y">
          {generalItem.map((e) => (
            <li
              key={e.label}
              className="flex items-start gap-5 py-3 first:pt-0 last:pb-0"
            >
              <h6 className="flex-1 text-secondary text-sm font-normal">
                <Text>{e.label}</Text>
              </h6>
              <p className="flex-[2] text-gray-900 text-sm font-normal">
                {e.value}
              </p>
            </li>
          ))}
        </ul>
      </Wrapper.Body>
    </Wrapper>
  );
}
