import { cloneDeep } from "lodash";
import {
  ComponentProps,
  Dispatch,
  Fragment,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useInView } from "react-intersection-observer";
import { config } from "../constants";
import { useAxios, useToggle, useTranslate } from "../hooks";
import Button from "./_Button";
import Icon from "./_Icon";
import InputGroup from "./_InputGroup";
import Loading from "./_Loading";
import Modal from "./_Modal";
import RadioButton from "./_RadioButton";
import SearchBox from "./_SearchBox";
import Text from "./_Text";

type Item<T> = { name: string; id: T };
type Props<T> = {
  //   radioButtonProps?: Optional<ComponentProps<typeof RadioButton>>;
  url?: string | null;
  params?: { [key: string]: unknown };
  renderItems: (data: any[]) => Item<T>[];
  value?: Item<T> | null;
  setValue?: Dispatch<Item<T> | null>;
  all?: boolean;
} & Omit<
  ComponentProps<typeof InputGroup>,
  "onClick" | "append" | "readOnly" | "type" | "setValue" | "value"
>;
export default function AsyncSelect<T>({
  label,
  value,
  setValue = () => {},
  url,
  params: propsParams = {},
  renderItems,
  rules,
  prepend,
  placeholder,
  className,
  disabled,
  //   radioButtonProps = {},
  all = false,
}: Props<T>) {
  const initParams = {
    pageSize: config.pageSize,
    pageNumber: 1,
    keyword: null,
  };
  const translate = useTranslate();
  const [ref, inView] = useInView();
  const { axios, loading } = useAxios();
  const [isOpen, toggle] = useToggle(false);
  const [totalPages, setTotalPages] = useState(0);
  const [params, setParams] = useState(initParams);
  const [data, setData] = useState<any[][]>([]);
  const dataHandler = useMemo(() => {
    let items = cloneDeep(renderItems(data.flat().filter(Boolean)));
    if (value?.id) {
      items = items.filter((e) => e.id !== value.id);
      items.unshift(value);
    }
    if (all) items.unshift({ name: "global.allFilterItem", id: null as T });
    return items;
  }, [data, renderItems, value, all]);
  const hasData = !!dataHandler.length || loading.get;
  const activeItemName = useMemo(() => {
    if (!!all && !value?.id) return translate("global.allFilterItem");
    return value?.name;
  }, [translate, value, all]);
  const handleSetParams = (key: keyof typeof params) => {
    const isPageNumber = key === "pageNumber";
    return (value: any) => {
      setParams((p) => ({
        ...p,
        [key]: value || null,
        ...(!isPageNumber && { pageNumber: 1 }),
      }));
    };
  };
  const handleToggle = () => {
    setParams(initParams);
    toggle();
  };
  const handleClick = (item: Item<T> | null) => {
    return () => {
      const isEqual = item?.id === value?.id;
      const isNull = !item?.id;
      if (isNull) setValue(null);
      if (!isEqual) setValue(item);
      handleToggle();
    };
  };
  const handleGoToNextPage = () => {
    const can = params.pageNumber < totalPages && inView && !loading.get;
    can &&
      setParams((p) => {
        const params = cloneDeep(p);
        params.pageNumber += 1;
        params.pageNumber = Math.min(params.pageNumber, totalPages);
        return params;
      });
  };
  const getData = () => {
    if (!isOpen) return;
    if (!url) return;
    const page = params.pageNumber;
    const isFirstPage = page === 1;
    const config = { params: { ...params, ...propsParams } };
    isFirstPage && setData([]);
    axios.get(url, config).then((res) => {
      setData((p) => {
        const data = cloneDeep(p);
        data[page - 1] = res.data.items;
        return data;
      });
      setTotalPages(res.data?.totalPages ?? null);
    });
  };
  useEffect(handleGoToNextPage, [
    inView,
    totalPages,
    loading.get,
    params.pageNumber,
  ]);
  useEffect(getData, [params, isOpen]);
  return (
    <Fragment>
      <InputGroup
        label={label}
        value={activeItemName}
        rules={rules}
        prepend={prepend}
        placeholder={placeholder}
        readOnly
        disabled={disabled}
        onClick={handleToggle}
        className={className}
        append={
          <span className="input-group-text text-primary">
            <Icon name="ArrowDown2" size={18} />
          </span>
        }
      />
      <Modal isOpen={isOpen} toggle={handleToggle} modalClassName="z-40">
        {!!label && (
          <Modal.Header>
            <Text>{label}</Text>
          </Modal.Header>
        )}
        <Modal.Header>
          <SearchBox
            variant="gray"
            className="w-full"
            onSubmit={handleSetParams("keyword")}
          />
        </Modal.Header>
        <Modal.Body className="h-72 divide-y divide-gray-100 overflow-auto flex-auto">
          {dataHandler.map((e) => (
            <RadioButton
              key={String(e.id)}
              label={e.name}
              isActive={e.id === (value?.id ?? null)}
              onClick={handleClick(e)}
              //   {...radioButtonProps}
            />
          ))}
          {!hasData && (
            <p className="text-base text-center max-w-full">
              <Text>global.noItems</Text>
            </p>
          )}
          {loading.get ? (
            <Loading.Radios />
          ) : (
            <div ref={ref} className="w-full h-20"></div>
          )}
        </Modal.Body>
        <Modal.Footer className="flex-center">
          <Button onClick={handleToggle} light>
            <Text>Back</Text>
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}
