import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Params } from ".";
import {
  AsyncSelect,
  Button,
  Drawer,
  Form,
  Icon,
  InputGroup,
  Select,
  Text,
} from "../../../components";
import { useAxios, useFindTranslate } from "../../../hooks";
import {
  Brand,
  Product,
  ProductFamily,
  Program,
  Supplier,
  togglePropsType,
} from "../../../types";

type MenuItem = { name: string; id: string };
type FilterProps = togglePropsType & {
  result: Product.WithAzureSearch;
  params: Params;
  handleSetParams: Dispatch<SetStateAction<Params>>;
  loading: boolean;
  onReset: () => void;
};

export default function Filter({
  isOpen,
  toggle,
  result,
  params: initParams,
  handleSetParams: submit,
  loading,
  onReset,
}: FilterProps) {
  const findTranslate = useFindTranslate();
  const { axios: productFamiliesAxios, loading: productFamiliesLoading } =
    useAxios();
  const { axios: programAxios, loading: programLoading } = useAxios();
  const [params, setParams] = useState(initParams);
  const [productFamilies, setProductFamilies] = useState<MenuItem[]>([]);
  const [programs, setPrograms] = useState<MenuItem[]>([]);
  const hasSupplierId = !!params.supplier?.id;
  const handleSetParams = (
    key: keyof typeof params,
    variant: "dropdown" | "input-group"
  ) => {
    const isSupplier = key === "supplier";
    const isPrice = key === "priceFrom" || key === "priceTo";
    const isDropdown = variant === "dropdown";
    return (value: any) => {
      const handler = (p: Params) => ({
        ...p,
        pageNumber: 1,
        ...(isSupplier && { programId: null }),
        [key]: isPrice ? value || null : value,
      });
      isDropdown && submit(handler);
      setParams(handler);
    };
  };
  const getProductFamilies = () => {
    const url = "/productservice/api/pimproductfamilies";
    productFamiliesAxios.get<ProductFamily.Item[]>(url).then(({ data }) => {
      setProductFamilies(
        data.map((e) => ({
          name: findTranslate(e.translates),
          id: e.pimProductFamilyId,
        }))
      );
    });
  };
  const getPrograms = () => {
    if (!hasSupplierId) return;
    const url = "/salesservice/api/supplierprograms";
    const config = { params: { supplierId: params.supplier?.id } };
    programAxios.get<Program.Item[]>(url, config).then(({ data }) => {
      setPrograms(data.map((e) => ({ name: e.title, id: e.id })));
    });
  };
  const handleReset = () => {
    onReset();
    // toggle();
  };
  const handleSubmit = () => {
    submit(params);
    toggle();
  };
  useEffect(() => {
    if (isOpen) setParams(initParams);
  }, [isOpen, initParams]);
  return (
    <Drawer as={Form} isOpen={isOpen} toggle={toggle} onSubmit={handleSubmit}>
      <Drawer.Menu>
        <Drawer.Header className="flex items-center gap-4">
          <h6 className="text-dark text-base text-start flex-1">
            <Text>products.filter.filterTitle</Text>
          </h6>
          <button
            type="button"
            className="text-warning text-sm self-center"
            onClick={handleReset}
            disabled={loading}
          >
            <Icon
              name="ArrowForwardSquare"
              variant="Bold"
              size={25}
              className="text-warning"
            />{" "}
            <Text>products.filter.resetFilterBtn</Text>
          </button>
        </Drawer.Header>
        <Drawer.Body className="space-y-4">
          <Select
            label="products.filter.productFamily"
            value={params.pimProductFamilyId ?? null}
            setValue={handleSetParams("pimProductFamilyId", "dropdown")}
            // items={result.pimProductFamily?.map((e) => ({
            //   name: `${e.title} (${e.count})`,
            //   id: e.value,
            // }))}
            items={productFamilies}
            loading={productFamiliesLoading.get}
            searchable
            all
            onOpenMenu={getProductFamilies}
          />
          <AsyncSelect
            label="products.filter.brand"
            value={params.brand}
            setValue={handleSetParams("brand", "dropdown")}
            url="/productservice/api/brands"
            renderItems={(data) =>
              data.map((e: Brand.Item) => ({
                name: e.name,
                id: e.code,
              }))
            }
            // items={result.brand?.map((e) => ({
            //   name: `${e.title} (${e.count})`,
            //   id: e.value,
            // }))}
            all
          />
          <AsyncSelect
            label="products.filter.supplier"
            value={params.supplier}
            setValue={handleSetParams("supplier", "dropdown")}
            url="/salesservice/api/suppliers"
            renderItems={(data) =>
              data.map((e: Supplier.Item) => ({
                name: e.name,
                id: e.supplierId,
              }))
            }
            all
          />
          <Select
            label="products.filter.program"
            value={params.programId ?? null}
            setValue={handleSetParams("programId", "dropdown")}
            items={programs}
            loading={programLoading.get}
            disabled={!hasSupplierId}
            onOpenMenu={getPrograms}
            searchable
            all
          />
          <div className="grid grid-cols-2 items-center gap-2">
            <label className="text-base block w-full mb-2 truncate col-span-full">
              <Text>products.filter.priceRange</Text>
            </label>
            <InputGroup
              value={params.priceFrom}
              setValue={handleSetParams("priceFrom", "input-group")}
              placeholder="products.filter.priceFrom"
              type="price"
            />
            <InputGroup
              value={params.priceTo}
              setValue={handleSetParams("priceTo", "input-group")}
              placeholder="products.filter.priceTo"
              type="price"
            />
          </div>
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-end gap-4">
          <Button type="button" variant="danger" onClick={toggle}>
            <Text>products.filter.cancelBtn</Text>
          </Button>
          <Button type="submit" variant="primary">
            <Text>products.filter.submit</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
