import {
  Dispatch,
  Fragment,
  SetStateAction,
  createContext,
  createElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router";
import {
  BackButton,
  Button,
  Icon,
  Layout,
  Loading,
  NoItems,
  Tabs,
  Text,
  Wrapper,
} from "../../../components";
import { CustomerCard } from "../../../containers";
import { IncidentStatus } from "../../../enums";
import { withPermission } from "../../../hoc";
import { useAxios, usePermission, useToggle } from "../../../hooks";
import { Company, Customer, Incident } from "../../../types";
import Activities from "./Activities";
import ChangeIncidentStep from "./ChangeIncidentStep";
import Documents from "./Documents";
import General from "./General";
import HeaderInfo from "./HeaderInfo";
import Stages from "./Stages";

type IncidentData = Incident.Details;
type IncidentDetailsContextType = {
  incident: IncidentData;
  getIncident: () => Promise<void>;
  setIncident: Dispatch<SetStateAction<IncidentData | null>>;
  isActive: boolean;
  stages: Incident.StageItem[];
  activeStageIndex: number;
  branch: null;
  updateLoading: boolean;
  isFinished: boolean;
  hasStages: boolean;
  activeStageId: string | null;
  isEnabled: boolean;
  canEdit: boolean;
};
export const IncidentDetailsContext = createContext(
  {} as IncidentDetailsContextType
);

function IncidentDetails() {
  const { incidentId } = useParams();
  const canEdit = usePermission("CMS_IncidentFullAccess", "CMS_UpdateIncident");
  const { axios, loading } = useAxios();
  const { axios: customerAxios, loading: customerLoading } = useAxios();
  const [incident, setIncident] = useState<IncidentData | null>(null);
  const [customer, setCustomer] = useState<
    (Customer.Details & Company.Details) | null
  >(null);
  const [showChangeStep, toggleChangeStep, hideChangeStep] = useToggle(false);
  const getLoading = loading.get && !incident;
  const updateLoading = loading.get && !!incident;
  const hasData = !!incident;
  const branch = null;
  const isActive = incident?.status === IncidentStatus.Active;
  const initStages = incident?.stages ?? [];
  const stages = initStages.sort((a, b) => a.priority - b.priority);
  const activeStage = stages.find((e) => !e.isProcessed);
  const activeStagePriority = activeStage?.priority ?? 0;
  const activeStageIndex = stages.findIndex(
    (e) => e.priority === activeStagePriority
  );
  const hasStages = !!stages.length;
  const activeStageId = activeStage?.id ?? null;
  const isLastStage = activeStageIndex === stages.length - 1;
  const isFinished = stages.every((e) => e.isProcessed);
  // const hasNextStageId = !!nextStageId;
  const tabs = [
    {
      label: "incident.details.activitiesTab",
      id: "0",
      component: Activities,
    },
    {
      label: "incident.details.generalTab",
      id: "1",
      component: General,
    },
    {
      label: "incident.details.documentsTab",
      id: "2",
      component: Documents,
    },
  ];
  const isEnabled = canEdit && isActive;

  const getIncident = useCallback(async () => {
    const url = `/channelmanagerservice/api/incidents/${incidentId}`;
    return await axios.get(url).then(({ data }) => {
      setIncident(data);
    });
  }, [incidentId]);
  const getCustomer = useCallback(() => {
    const id = incident?.customer?.customerId;
    const isCompany = incident?.customer?.customerType;
    if (!id) return;
    const url = isCompany
      ? `/accountservice/api/customers/${id}/as-company`
      : `/accountservice/api/customers/${id}`;
    customerAxios.get(url).then(({ data }) => {
      setCustomer(data);
    });
  }, [incident?.customer?.customerId, incident?.customer?.customerType]);
  useEffect(() => {
    getIncident();
  }, [getIncident]);
  useEffect(() => {
    getCustomer();
  }, [getCustomer]);
  return (
    <Fragment>
      <Layout>
        <Layout.Header>
          <BackButton to="/incidents" className="mr-auto" />
          {!getLoading && hasStages && !isLastStage && (
            <Button
              type="button"
              className="flex items-center font-normal text-sm gap-2"
              onClick={toggleChangeStep}
            >
              <span>
                <Text>
                  {isLastStage
                    ? "incident.details.finalButtonButton"
                    : "incident.details.goToNextStepButton"}
                </Text>
              </span>
              <Icon name="ArrowRight2" className="size-5" />
            </Button>
          )}
        </Layout.Header>
        <Layout.Body className="space-y-4">
          {getLoading ? (
            <Loading.Header />
          ) : hasData ? (
            <IncidentDetailsContext.Provider
              value={{
                incident,
                getIncident,
                setIncident,
                isActive: isActive,
                branch,
                stages,
                activeStageIndex,
                updateLoading,
                isFinished,
                activeStageId,
                hasStages,
                isEnabled,
                canEdit,
              }}
            >
              <section className="grid grid-cols-1 lg:grid-cols-5 gap-3">
                <Stages />
                <HeaderInfo />
                {customerLoading.get ? (
                  <div className="lg:!col-span-2 loading" />
                ) : (
                  <CustomerCard
                    customer={customer}
                    className="lg:!col-span-2"
                  />
                )}
              </section>
              <Tabs activeKey={tabs[0].id}>
                <Wrapper className="col-span-full">
                  <Wrapper.Body className="py-0">
                    <Tabs.ButtonGroup>
                      {tabs.map((e) => (
                        <Tabs.Button key={e.id} eventKey={e.id}>
                          <Text>{e.label}</Text>
                        </Tabs.Button>
                      ))}
                    </Tabs.ButtonGroup>
                  </Wrapper.Body>
                </Wrapper>
                {hasData && (
                  <Fragment>
                    {tabs.map((e) => (
                      <Tabs.Item key={e.id} eventKey={e.id}>
                        {createElement(e.component)}
                      </Tabs.Item>
                    ))}
                  </Fragment>
                )}
              </Tabs>
              {hasStages && (
                <ChangeIncidentStep
                  isOpen={showChangeStep}
                  toggle={hideChangeStep}
                />
              )}
            </IncidentDetailsContext.Provider>
          ) : (
            <NoItems />
          )}
        </Layout.Body>
      </Layout>
    </Fragment>
  );
}

export default withPermission(IncidentDetails, [
  "CMS_IncidentFullAccess",
  "CMS_GetIncident",
]);
