import { ComponentType } from "react";
import noPermissions from "../assets/images/no-permission-page.svg";
import { Image, Layout, Text } from "../components";
import { usePermission } from "../hooks";
import { Permission } from "../types";

export default function withPermission<P extends object>(
  Component: ComponentType<P>,
  permissions: Permission[]
) {
  const Error = () => {
    return (
      <Layout>
        <Layout.Header></Layout.Header>
        <Layout.Body className="py-10 space-y-4">
          <Image
            src={noPermissions}
            alt="No Permission"
            className="block w-full max-w-56 mx-auto"
          />
          <p className="text-base text-dark text-center">
            <Text>global.noPermissionPageTitle</Text>
          </p>
          <p className="text-sm text-placeholder text-center">
            <Text>global.noPermissionPageText</Text>
          </p>
        </Layout.Body>
      </Layout>
    );
  };
  return (props: P) => {
    const hasPermission = usePermission(...permissions);
    if (hasPermission) return <Component {...props} />;
    return <Error />;
  };
}
