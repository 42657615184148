import { cloneDeep } from "lodash";
import { Fragment, useContext, useEffect, useMemo } from "react";
import toast from "react-hot-toast";
import { twMerge } from "tailwind-merge";
import { IncidentDetailsContext } from "..";
import {
  Alert,
  Button,
  Form,
  Icon,
  InputGroup,
  Select,
  Text,
  Wrapper,
} from "../../../../components";
import { rules, status, types } from "../../../../constants";
import {
  ChooseIncidentLineItem,
  ChooseOrder,
  IncidentLineItemCard,
  IncidentOrderCard,
} from "../../../../containers";
import { SelectedLineItem } from "../../../../containers/_ChooseIncidentLineItem";
import { SelectedOrder } from "../../../../containers/_ChooseOrder";
import IncidentAssignedEmployee, {
  SelectedEmployee,
} from "../../../../containers/_IncidentAssignedEmployee";
import {
  useAxios,
  useDataState,
  useToggle,
  useTranslate,
} from "../../../../hooks";
import { Incident } from "../../../../types";

export default function General() {
  const translate = useTranslate();
  const { axios, loading } = useAxios();
  const {
    incident,
    getIncident,
    activeStageId,
    stages,
    activeStageIndex,
    isActive,
    updateLoading,
    isEnabled,
    canEdit,
  } = useContext(IncidentDetailsContext);
  const hasOrder = !!incident.orderId;
  const hasLineItem = !!incident.orderLineId;
  const hasEmployee = !!incident.assignedId;

  const initData: Incident.Update = useMemo(() => {
    return {
      customerId: incident.customer?.customerId,
      orderId: incident.orderId,
      assignedId: incident.assignedId ?? null,
      assignedType: incident.assignedType ?? 0,
      orderLineId: incident.orderLineId,
      branchId: incident.branchId,
      title: incident.title,
      priority: incident.priority,
      origin: incident.origin,
      activeStageId: activeStageId,
      documents: incident.documents,
      subjectId: incident.subjectId,
      customerMood: incident.customerMood,
      description: incident.description,
      customerType: incident.customer?.customerType,
      technicalInspectionNeeded: incident.technicalInspectionNeeded,
    };
  }, [incident, activeStageId]);
  const initOrder: SelectedOrder | null = useMemo(() => {
    if (!hasOrder) return null;
    return {
      id: incident.orderId!,
      name: incident.orderName!,
      number: incident.orderNumber!,
      totalAmount: 0,
    };
  }, [incident, hasOrder]);
  const initLineItem: SelectedLineItem | null = useMemo(() => {
    if (!hasLineItem) return null;
    return {
      id: incident.orderLineId!,
      title: incident.orderLineName!,
    };
  }, [incident, hasLineItem]);
  const initEmployee: SelectedEmployee | null = useMemo(() => {
    if (!hasEmployee) return null;
    return {
      id: incident.assignedId ?? "",
      name: incident.assignedName ?? "",
      type: incident.assignedType ?? 0,
    };
  }, [incident, hasEmployee]);
  const [data, setData, setBaseData, isChanged] = useDataState(initData);
  const [showOrderList, toggleOrderList] = useToggle(false);
  const [showLineItemsList, toggleLineItemsList] = useToggle(false);
  const [order, setOrder, setBaseOrder, isOrderChanged] =
    useDataState(initOrder);
  const [lineItem, setLineItem, setBaseLineItem, isLineItemChanged] =
    useDataState(initLineItem);
  const [employee, setEmployee, setBaseEmployee, isEmployeeChanged] =
    useDataState(initEmployee);

  const isDataChanged = [
    isChanged,
    isOrderChanged,
    isEmployeeChanged,
    isLineItemChanged,
  ].some(Boolean);
  const hasLoading = [loading.update, updateLoading].some(Boolean);
  const incidentStatusTitle =
    status.incident.find((e) => e.id === incident.status)?.name ?? "--";
  const disabledSelectOrder = useMemo(() => {
    const currentStage = stages?.[activeStageIndex];
    const isFirstStage = currentStage?.priority === 1;
    if (!isEnabled) return true;
    if (!stages.length) return false;
    if (!isFirstStage) return true;
    return false;
  }, [stages, activeStageIndex, isEnabled]);

  const handleSetValue = (key: keyof typeof data) => {
    return (value: any) => {
      setData((p) => ({ ...p, [key]: value }));
    };
  };
  const handleSetOrder = (e: SelectedOrder | null) => {
    const isNewOrder = e?.id !== order?.id;
    if (!isNewOrder) return;
    setOrder(e);
    setLineItem(null);
  };
  const submit = () => {
    if (!canEdit) return;
    const url = `/channelmanagerservice/api/incidents/${incident.crmIncidentId}`;
    const body = cloneDeep(data);
    body.priority = Number(body.priority);
    body.origin = Number(body.origin);
    body.customerId ||= null;
    body.orderId = order?.id || null;
    body.assignedId = employee?.id || null;
    body.assignedType = employee?.type ?? 0;
    body.orderLineId = lineItem?.id ?? null;
    axios.put(url, body).then(() => {
      getIncident().then(() => {
        toast.success("incident.form.updateSuccessMessage");
        setBaseData(body);
        setBaseOrder(order);
        setBaseLineItem(lineItem);
        setBaseEmployee(employee);
      });
    });
  };

  useEffect(() => {
    setBaseData(initData);
    setBaseOrder(initOrder);
    setBaseLineItem(initLineItem);
    setBaseEmployee(initEmployee);
  }, [initData, initOrder, initLineItem, initEmployee]);
  return (
    <Fragment>
      <Form
        onSubmit={submit}
        className="grid grid-cols-1 lg:grid-cols-2 lg:items-start gap-4"
      >
        {isActive ? (
          canEdit && (
            <div className="col-span-full flex items-center gap-4">
              <p className="flex-1 text-base text-dark font-medium truncate">
                <Text>incident.form.submitChangeText</Text>
              </p>
              <Button
                type="submit"
                variant="primary"
                disabled={!isDataChanged}
                loading={hasLoading}
              >
                <Text>incident.form.submitChangeBtn</Text>
              </Button>
            </div>
          )
        ) : (
          <Alert variant="warning" light className="col-span-full">
            <Alert.Title>
              <Text status={translate(incidentStatusTitle)}>
                incident.details.alertTitle
              </Text>
            </Alert.Title>
            <Alert.Text>
              <Text>incident.details.alertText</Text>
            </Alert.Text>
          </Alert>
        )}
        <Wrapper>
          <Wrapper.Body className="space-y-6">
            <InputGroup
              label="incident.form.title"
              value={data.title}
              placeholder="incident.form.title"
              setValue={handleSetValue("title")}
              rules={rules.required}
              disabled={!isEnabled}
            />
            {/* <InputGroup
              as="textarea"
              label="incident.form.topicDescription"
              value={data.topicDescription}
              placeholder="incident.form.topicDescriptionPlaceholder"
              setValue={handleSetValue("topicDescription")}
              rules={rules.required}
              disabled={!isEnabled}
            /> */}
            <InputGroup
              as="textarea"
              label="incident.form.description"
              value={data.description}
              placeholder="incident.form.descriptionPlaceholder"
              setValue={handleSetValue("description")}
              rules={rules.required}
              disabled={!isEnabled}
              className={twMerge(
                "[&_textarea]:lg:min-h-[15.75rem]",
                order && "[&_textarea]:lg:min-h-[24.375rem]",
                lineItem && "[&_textarea]:lg:min-h-[27.9375rem]"
              )}
            />
          </Wrapper.Body>
        </Wrapper>
        <div className="space-y-3">
          <Wrapper>
            <Wrapper.Body className="space-y-3">
              {!!order ? (
                <Fragment>
                  <IncidentOrderCard
                    order={order}
                    onClear={handleSetOrder}
                    disabled={disabledSelectOrder}
                  />
                  <IncidentLineItemCard
                    lineItem={lineItem}
                    onClear={setLineItem}
                    onSelectLineItem={toggleLineItemsList}
                    disabled={!isEnabled}
                  />
                </Fragment>
              ) : (
                <InputGroup
                  label="incident.form.attachmentOrder"
                  value={null}
                  placeholder="incident.form.attachmentOrderPlaceholder"
                  // rules={rules.required}
                  onClick={toggleOrderList}
                  readOnly
                  disabled={!isEnabled}
                  append={
                    <span className="input-group-text text-primary">
                      <Icon name="ReceiptText" variant="Bold" size={22} />
                    </span>
                  }
                />
              )}
            </Wrapper.Body>
          </Wrapper>
          <Wrapper>
            <Wrapper.Body className="space-y-6">
              <Select
                label="incident.form.priority"
                value={data.priority}
                placeholder="incident.form.priorityPlaceholder"
                setValue={handleSetValue("priority")}
                items={types.incidentPriority}
                rules={rules.required}
                disabled={!isEnabled}
              />
              <Select
                label="incident.form.origin"
                value={data.origin}
                placeholder="incident.form.originPlaceholder"
                setValue={handleSetValue("origin")}
                items={types.incidentOrigin}
                rules={rules.required}
                disabled={!isEnabled}
              />
              <section>
                <label className="block w-full mb-2 truncate after:content-['*'] after:text-danger">
                  <Text>incident.form.customerMood</Text>
                </label>
                <div className="flex-center gap-3">
                  {types.customerModes.map((e) => (
                    <button
                      key={e.id}
                      type="button"
                      data-active={data.customerMood === e.id}
                      className="flex-center bg-gray-50 border border-gray-50 flex-1 h-10 text-2xl rounded opacity-50 data-active:opacity-100 data-active:border-primary transition-[opacity,border-color] disabled:!opacity-40"
                      dangerouslySetInnerHTML={{ __html: e.name }}
                      onClick={() => handleSetValue("customerMood")(e.id)}
                      disabled={!isEnabled}
                    />
                  ))}
                </div>
              </section>
            </Wrapper.Body>
          </Wrapper>
        </div>
        <div className="col-span-full">
          <Wrapper className="lg:max-w-screen-sm">
            <Wrapper.Body>
              <IncidentAssignedEmployee
                value={employee}
                setValue={setEmployee}
                disabled={!isEnabled}
              />
            </Wrapper.Body>
          </Wrapper>
        </div>
      </Form>
      {isEnabled && (
        <Fragment>
          <ChooseOrder
            crmCheckRecordExistence
            isOpen={showOrderList}
            toggle={toggleOrderList}
            onSelect={handleSetOrder}
            params={{ customerId: data.customerId, isPublished: true }}
          />
          <ChooseIncidentLineItem
            orderId={order?.id}
            isOpen={showLineItemsList}
            toggle={toggleLineItemsList}
            onSelect={(lineItem) => setLineItem(lineItem)}
          />
        </Fragment>
      )}
    </Fragment>
  );
}
