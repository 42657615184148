import { Button, Drawer, Icon, Text } from "../../../components";
import { dateFormats } from "../../../constants";
import { useConverters } from "../../../hooks";
import { Order, togglePropsType } from "../../../types";

type Props = {
  orderUpfrontPayments: Order.UpfrontPaymentItem[];
} & togglePropsType;

export default function UpFrontPaymentDrawer({
  isOpen,
  toggle,
  orderUpfrontPayments,
}: Props) {
  const { convertAmount, convertDate } = useConverters();
  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header>
          <h6 className="text-base text-dark font-medium text-center">
            <Text>drawerTitles.orderUpFrontPayments</Text>
          </h6>
        </Drawer.Header>
        <Drawer.Body className="divide-y">
          {orderUpfrontPayments.map((e) => (
            <div
              key={e.paymentDate}
              className="space-y-2.5 py-2.5 first:pt-0 last:pb-0"
            >
              <p className="text-secondary text-sm font-medium">
                <Text amount={convertAmount(e.amount) ?? ""}>
                  global.orderTotalUpFrontPaymentAmount
                </Text>
              </p>
              <p className="text-secondary text-base font-medium flex items-center gap-1">
                <Icon name="Calendar2" variant="Bulk" className="size-5" />
                <span>{convertDate(e.paymentDate, dateFormats.fullDate)}</span>
              </p>
            </div>
          ))}
        </Drawer.Body>
        <Drawer.Footer className="flex-center">
          <Button type="button" variant="primary" light>
            <Text>button.close</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
