import { useContext } from "react";
import { IncidentDetailsContext } from ".";
import checkIcon from "../../../assets/icons/check.svg";
import { Wrapper } from "../../../components";

export default function Stages() {
  const { stages, activeStageIndex, hasStages, isFinished } = useContext(
    IncidentDetailsContext
  );
  if (!hasStages) return null;
  return (
    <Wrapper className="lg:!col-span-full">
      <Wrapper.Body className="space-y-2.5 overflow-auto">
        <section className="relative w-fit flex items-center min-w-full">
          {stages.map((e, i) => {
            const isLast = i === stages.length - 1;
            const isPrev = i < activeStageIndex || isFinished || undefined;
            const isActive = i === activeStageIndex;
            return (
              <div
                key={e.title}
                data-active={isActive}
                data-prev={isPrev}
                className="group flex-1 min-w-fit space-y-2.5"
              >
                <div className="flex items-center gap-3">
                  <div className="flex-center text-xl bg-primary/10 text-primary size-10 min-w-10 rounded transition-colors group-data-active:bg-primary group-data-active:text-white">
                    <img
                      src={checkIcon}
                      alt={e.title}
                      className="hidden group-data-[prev]:inline-block size-4"
                    />
                    <span className="inline-flex group-data-[prev]:hidden">
                      {e.priority}
                    </span>
                  </div>
                  <div className="flex-1 min-w-fit pe-5">
                    <h6 className="block w-full truncate text-sm font-medium whitespace-nowrap">
                      {e.title}
                    </h6>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="size-10 flex-center">
                    <span className="block size-2.5 rounded-full bg-[#E1E1E7] group-data-active:bg-primary group-data-[prev]:bg-primary" />
                  </div>
                  {!isLast && (
                    <div className="relative flex-1 h-10">
                      <div className="absolute w-[calc(100%+2rem)] h-0 border-t border-[#E1E1E7] group-data-[prev]:border-primary border-dashed -left-4 inset-y-0 m-auto"></div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </section>
      </Wrapper.Body>
    </Wrapper>
  );
}
