import { useContext } from "react";
import { toast } from "react-hot-toast";
import { LineItemsContext } from ".";
import { Button, Icon, Modal, Text } from "../../../../components";
import { useAxios } from "../../../../hooks";
import { LineItem, togglePropsType } from "../../../../types";
import { OrderDetailsContext } from "../../../Orders/Details";
type CancelLineItemProps = togglePropsType & {
  lineItem: LineItem.Item;
  isSubLineItem: boolean;
};
export default function CancelLineItem({
  isOpen,
  toggle,
  lineItem,
  isSubLineItem,
}: CancelLineItemProps) {
  const { axios, loading } = useAxios();
  const orderContext = useContext(OrderDetailsContext);
  const { updateLineItems, updateLineItemsLoading } =
    useContext(LineItemsContext);

  const order = orderContext?.order;
  const updateOrder = orderContext?.updateOrder;
  const updateOrderLoading = orderContext?.updateOrderLoading;

  const hasLoading = [
    loading.update,
    updateOrderLoading,
    updateLineItemsLoading,
  ].some(Boolean);

  const submitCancel = () => {
    const url = `/salesservice/api/orderlineitems/${lineItem.id}/cancel`;
    const body = { orderLineItemId: lineItem.id, orderId: order?.id };
    axios.put(url, body).then(() => {
      updateOrder?.().then(() => {
        updateLineItems().then(() => {
          const message = isSubLineItem
            ? "toast.success.cancelSubLineItem"
            : "toast.success.cancelLineItem";
          toast.success(message);
          toggle();
        });
      });
    });
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Modal.Header>
        <h6>
          <Text>modalTitles.cancelOrderLineItem</Text>
        </h6>
      </Modal.Header>
      <Modal.Body className="space-y-8">
        <p className="text-secondary">
          <Text>confirms.cancelOrderLineItem</Text>
        </p>
        <div className="flex items-center text-start gap-4">
          {isSubLineItem ? (
            <Icon.Wrapper className="border border-primary bg-white">
              <Icon name="Box1" variant="Linear" size={28} />
            </Icon.Wrapper>
          ) : (
            <Icon.Wrapper>
              <Icon name="Box" variant="Bold" size={28} />
            </Icon.Wrapper>
          )}
          <div className="flex-1 space-y-1">
            <span className="block">{lineItem.title}</span>
            <span className="block text-secondary">
              #{lineItem.product?.articleNumber}
            </span>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="flex items-center justify-end gap-4">
        <Button light onClick={toggle}>
          <Text>button.cancel</Text>
        </Button>
        <Button variant="danger" onClick={submitCancel} loading={hasLoading}>
          <Text>button.submitCancelOrderLineItem</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
