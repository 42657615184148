import { useContext } from "react";
import toast from "react-hot-toast";
import { CompanyContext } from ".";
import { Button, CheckBox, Modal, Text } from "../../../components";
import { CompanyApprovalStatus } from "../../../enums";
import { useAxios, useDataState } from "../../../hooks";
import { togglePropsType } from "../../../types";

type ChangeStatusProps = togglePropsType;
export default function ChangeStatus({ isOpen, toggle }: ChangeStatusProps) {
  const { axios, loading } = useAxios();
  const { companyData, setCompanyData } = useContext(CompanyContext);
  const [data, setData, setBaseData, isChanged] = useDataState(companyData);
  const hasLoading = loading.update || loading.get;
  const getCimNumber = async (force: boolean) => {
    const cimNumber = companyData.cimNumber;
    const hasCimNumber = !!cimNumber;
    const url = `/accountservice/api/customers/${companyData.id}/as-company`;
    if (hasCimNumber || !force) return Promise.resolve(cimNumber);
    return await axios
      .get(url)
      .then((res) => res.data.cimNumber as string)
      .catch(() => null);
  };
  const submit = () => {
    const url = `/accountservice/api/customers/${companyData.id}/as-company/change-approval-status`;
    const body = {
      companyRegistrationNumberApproved: data.companyRegistrationNumberApproved,
      personPowerOfAttorneyApproved: data.personPowerOfAttorneyApproved,
    };
    axios.put(url, body).then(() => {
      const isApprove = Object.values(body).every(Boolean);
      const approvalStatus = isApprove
        ? CompanyApprovalStatus.Approved
        : CompanyApprovalStatus.PendingApproval;
      getCimNumber(isApprove).then((cimNumber) => {
        toast.success("company.details.changeStatus.successMessage");
        setCompanyData((p) => ({ ...p, ...body, approvalStatus, cimNumber }));
        setBaseData((p) => ({ ...p, ...body }));
        toggle();
      });
    });
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Modal.Header>
        <Text>company.details.changeStatus.title</Text>
      </Modal.Header>
      <Modal.Body className="space-y-4">
        <div className="w-full max-h-96 bg-gray-50 text-dark text-base rounded-none overflow-auto space-y-2 px-4 py-5">
          <p>
            When creating a new company address, it is important to identify the
            authorized representatives according to the respective legal form in
            order to determine who is authorized to make purchases in our
            company on behalf of this company.
          </p>
          <ul className="list-disc list-outside px-5 space-y-2">
            <li>
              <b>Register numbers: </b>
              For corporations (e.g. For limited liability companies (e.g. GmbH)
              and limited partnerships (KG), the commercial register number is
              required.
            </li>
            <li>
              <b>Commercial Register Extract: </b>
              You can access the commercial register extract online via the
              joint register portal of the states: Register portal | Normal
              search
            </li>
            <li>
              <b>Powers of Attorney: </b>
              When submitting a power of attorney, it should be clearly worded
              and, in case of doubt, notarized in order to clearly prove the
              authorization of the authorized representative.
            </li>
            <li>
              <b>Missing power of attorney: </b>
              If there is no power of attorney and the contact person is neither
              the owner, managing director nor authorized representative, please
              record the contact person's private address and temporarily enter
              it as the delivery address until additional information can be
              provided to another contact person.
            </li>
          </ul>
          <p>
            Please ensure that all required documents are complete and up to
            date to ensure a smooth purchasing process. If you have any
            uncertainties or questions, please contact the relevant department
            or your supervisor.
          </p>
        </div>
        <CheckBox
          label="company.details.changeStatus.gdprAccepted"
          value={true}
          disabled
          //   setValue={(gdprAccepted) => setData((p) => ({ ...p, gdprAccepted }))}
        />
        <CheckBox
          label="company.details.changeStatus.companyRegistrationNumberApproved"
          value={!!data.companyRegistrationNumberApproved}
          setValue={(companyRegistrationNumberApproved) =>
            setData((p) => ({ ...p, companyRegistrationNumberApproved }))
          }
        />
        <CheckBox
          label="company.details.changeStatus.personPowerOfAttorneyApproved"
          value={!!data.personPowerOfAttorneyApproved}
          setValue={(personPowerOfAttorneyApproved) =>
            setData((p) => ({ ...p, personPowerOfAttorneyApproved }))
          }
        />
      </Modal.Body>
      <Modal.Footer className="flex items-center justify-end gap-4">
        <Button
          type="button"
          variant="danger"
          onClick={toggle}
          disabled={hasLoading}
        >
          <Text>company.details.changeStatus.cancelBtn</Text>
        </Button>
        <Button
          type="button"
          disabled={!isChanged}
          variant="success"
          onClick={submit}
          loading={hasLoading}
        >
          <Text>company.details.changeStatus.submitBtn</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
