import { Fragment, useContext } from "react";
import { CreateIncidentContext } from ".";
import { Icon, InputGroup, Wrapper } from "../../../components";
import { rules } from "../../../constants";
import {
  ChooseIncidentLineItem,
  ChooseOrder,
  IncidentLineItemCard,
  IncidentOrderCard,
} from "../../../containers";
import { SelectedOrder } from "../../../containers/_ChooseOrder";
import { useToggle } from "../../../hooks";

export default function IncidentInfo() {
  const {
    data,
    setData,
    customer,
    order,
    setOrder,
    lineItem,
    setLineItem,
    canEditOrder,
  } = useContext(CreateIncidentContext);
  const [showOrderList, toggleOrderList] = useToggle(false);
  const [showLineItemList, toggleLineItemList] = useToggle(false);
  const hasOrder = !!order;
  const handleSetOrder = (e: SelectedOrder | null) => {
    const isNewOrder = e?.id !== order?.id;
    if (!isNewOrder) return;
    setOrder(e);
    setLineItem(null);
  };
  const handleSetValue = (key: keyof typeof data) => {
    return (value: any) => {
      setData((p) => ({ ...p, [key]: value }));
    };
  };
  return (
    <Fragment>
      <Wrapper>
        <Wrapper.Body className="space-y-8 max-w-screen-md mx-auto">
          {hasOrder ? (
            <IncidentOrderCard
              order={order}
              onClear={canEditOrder ? handleSetOrder : undefined}
            />
          ) : (
            <InputGroup
              label="incident.form.attachmentOrder"
              value=""
              placeholder="incident.form.attachmentOrderPlaceholder"
              // rules={rules.required}
              onClick={toggleOrderList}
              readOnly
              append={
                <span className="input-group-text text-primary">
                  <Icon name="ReceiptText" variant="Bold" size={22} />
                </span>
              }
            />
          )}
          {hasOrder && (
            <IncidentLineItemCard
              lineItem={lineItem}
              onSelectLineItem={toggleLineItemList}
              onClear={setLineItem}
            />
          )}
          <InputGroup
            label="incident.form.title"
            value={data.title}
            placeholder="incident.form.titlePlaceholder"
            setValue={handleSetValue("title")}
            rules={rules.required}
          />
          {/* <InputGroup
            as="textarea"
            label="incident.form.topicDescription"
            value={data.topicDescription}
            placeholder="incident.form.topicDescriptionPlaceholder"
            setValue={handleSetValue("topicDescription")}
            rules={rules.required}
          /> */}
          <InputGroup
            as="textarea"
            label="incident.form.description"
            value={data.description}
            placeholder="incident.form.descriptionPlaceholder"
            setValue={handleSetValue("description")}
            rules={rules.required}
          />
        </Wrapper.Body>
      </Wrapper>
      <ChooseOrder
        crmCheckRecordExistence
        isOpen={showOrderList}
        toggle={toggleOrderList}
        onSelect={handleSetOrder}
        params={{ customerId: customer?.id, isPublished: true }}
      />
      {hasOrder && (
        <ChooseIncidentLineItem
          isOpen={showLineItemList}
          toggle={toggleLineItemList}
          orderId={order.id}
          onSelect={setLineItem}
        />
      )}
    </Fragment>
  );
}
