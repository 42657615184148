import { cloneDeep } from "lodash";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { OrderDetailsContext } from ".";
import {
  Button,
  Drawer,
  Form,
  Icon,
  InputGroup,
  Text,
} from "../../../components";
import { useAxios } from "../../../hooks";
import { togglePropsType } from "../../../types";
type Data = {
  title: string;
  orderNote: string | null;
  supplierNote: string | null;
  customerNote: string | null;
  currencyCode: string;
  taxFree: boolean;
};
type AcceptOrderProps = togglePropsType;
export default function EditOrder({ isOpen, toggle }: AcceptOrderProps) {
  const { axios, loading } = useAxios();
  const orderContext = useContext(OrderDetailsContext);
  const order = orderContext?.order;
  const updateOrder = orderContext?.updateOrder;
  const updateOrderLoading = orderContext?.updateOrderLoading;
  const initData: Data = {
    title: order?.title ?? "",
    orderNote: order?.orderNote ?? "",
    customerNote: order?.customerNote ?? "",
    supplierNote: order?.supplierNote ?? "",
    currencyCode: order?.currencyCode ?? "",
    taxFree: order?.taxFree ?? false,
  };
  const hasLoading = [loading.update, updateOrderLoading].some(Boolean);
  const [data, setData] = useState(initData);

  const resetData = () => {
    if (!isOpen) return;
    setData(initData);
  };
  const handleSetValue = (key: keyof Data) => {
    return (value: any) => setData((p) => ({ ...p, [key]: value }));
  };
  const submit = () => {
    const url = `/salesservice/api/orders/${order?.id}`;
    const body = cloneDeep(data);
    body.orderNote ||= null;
    body.customerNote ||= null;
    body.supplierNote ||= null;
    axios.put(url, body).then(() => {
      updateOrder?.().then(() => {
        toast.success("toast.success.editOrder");
        toggle();
      });
    });
  };
  useEffect(resetData, [isOpen]);
  return (
    <Drawer as={Form} onSubmit={submit} isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header>
          <h6>
            <Icon
              name="TickCircle"
              variant="Bold"
              size={16}
              className="text-success"
            />{" "}
            <Text>drawerTitles.editOrder</Text>
          </h6>
        </Drawer.Header>
        <Drawer.Header className="space-y-1">
          <Icon.Wrapper className="mx-auto">
            <Icon name="DiscountShape" variant="Bold" />
          </Icon.Wrapper>
          <h6>{order?.title}</h6>
          <p className="text-secondary">#{order?.number}</p>
        </Drawer.Header>
        <Drawer.Body className="space-y-4">
          <InputGroup
            label="formControls.orderTitle"
            value={data.title}
            setValue={handleSetValue("title")}
            // rules={rules.required}
          />
          <InputGroup
            as="textarea"
            label="formControls.orderNote"
            value={data.orderNote}
            setValue={handleSetValue("orderNote")}
            // rules={rules.required}
          />
          {/* <Toggler
            label="formControls.orderTaxFree"
            value={!!data.taxFree}
            setValue={handleSetValue("taxFree")}
          /> */}
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-end gap-4">
          <Button type="button" variant="danger" onClick={toggle}>
            <Text>button.cancel</Text>
          </Button>
          <Button variant="success" type="submit" loading={hasLoading}>
            <Text>button.submitEditOrder</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
