import commissions from "./commissions";
import company from "./companies";
import customers from "./customers";
import errorCodes from "./errorCodes";
import incident from "./incidents";
import pickup from "./pickup";
import products from "./products";
import sellOffProducts from "./sellOffProducts";
import suppliers from "./suppliers";
import task from "./task";

const de = {
  global: {
    noItems: "Kein Eintrag gefunden",
    userNotFoundTitle: "Nutzer nicht gefunden",
    userNotFoundText: "Bitte etwas anderes probieren",
    pagination: "Zeige {{ from }} bis {{ to }} von {{ totalItems }} Einträgen",
    filter: "Filter",
    customerInfo: "Kundeninfo",
    addedAt: "Hinzugefügt am",
    createdAt: "Erstellt am",
    createdByName: "Created by DE",
    lastUpdatedAt: "Letzte Aktualisierung",
    lastUpdatedByName: "Updated by DE",
    until: "bis",
    updatedAt: "Aktualisiert am",
    customerRegistrationAt: "Registriert am",
    searchBox: "Suchen...",
    searchCalendarItem: "Aufgabentitel oder Kundennamen suchen...",
    submit: "Bitte übermitteln Sie Ihre Änderungen",
    logout: "Abmelden",
    by: "bei",
    version: "Version",
    signedByCustomer: "Vom Kunden signiert",
    orEnterManual: "oder manuelle Eingabe",
    noAddress: "Keine Adresse vorhanden",
    tomorrow: "Morgen",
    next3days: "Die nächsten 3 Tage",
    in7days: "Die nächsten 7 Tage",
    thisWeek: "diese Woche",
    thisMonth: "dieser Monat",
    last6Month: "letzte 6 Monate",
    selectStore: "Filiale wählen",
    selectSaleChannel: "Bitte Verkaufskanal wählen",
    price: "Preis",
    originalPrice: "Originalpreis",
    finalPrice: "Endpreis",
    totalPrice: "Gesamtpreis",
    orderTaxFreeBadge: "Tax free DE",
    orderTotalUpFrontPayment: "Total Upfront payment DE",
    orderTotalUpFrontPaymentAmount:
      "Amount paid: <span class='text-base text-dark'>{{ amount }}</span> DE",
    productPrice: "Produktpreis",
    totalLineItemPrice: "Gesamtpreis der Positionen",
    supplierPrice: "EK Preis",
    customerAddress: "Kundenadresse",
    noPimProduct: "Kein PIM-Produkt gefunden",
    addProductManually: "Produkt manuell hinzufügen",
    confirmDeleteDocument:
      "Sind Sie sicher, dass Sie dieses Dokument löschen möchten?",
    pleaseWait: "Bitte warten...",
    storeName: "Geschäftsname",
    offerLogisticText: "Wunschlieferdatum",
    offerCompletedInfo: "Abgeschlossene Informationen",
    infoTitle: "Informationen",
    logisticsTitle: "Logistik",
    shippingMethodsText: "Verfügbare Versandmethoden",
    lineItemProductTitle: "Produkttitel",
    articleNumber: "Artikelnummer",
    supplierName: "Lieferant",
    supplierArticleNumber: "Lieferanten-Artikelnummer",
    supplierCode: "Lieferantencode",
    programName: "Programm",
    brandName: "Brand DE",
    lineItemNormalPrice: "Normalpreis",
    lineItemSalesPrice: "Verkaufspreis",
    lineItemProductInventoryTitle: "Verfügbarkeit (Lager)",
    lineItemProductInventoryYes: "Ja",
    lineItemProductInventoryNo: "Nein",
    lineItemProductInventoryStock: "Lagerbestand",
    lineItemIsReserved: "Reserviert",
    lineItemReservationNumber: "Reservierungsnummer",
    lineItemProductInventoryReserve: "Reservieren",
    lineItemProductInventoryUnreserve: "Reservierung aufheben",
    lineItemProductInventoryNotEnough: "Nicht ausreichend",
    lineItemProductInventoryNoAvailable: "Lagerinformationen nicht verfügbar",
    lineItemOptionalDiscountManualValue: "Manueller Wert",
    lineItemOptionalDiscountDescription: "Beschreibung",
    offerTitle: "Angebot",
    downPaymentAmount: "Betrag",
    downPaymentDueDate: "Fälligkeitsdatum",
    downPaymentReason: "Grund für das unterschrieten der Mindestanzahlung",
    quantityTitle: "Menge",
    lineItemDiscounts: "Nachlass",
    orderTitle: "Bestellung",
    orderDeadline: "Frist",
    orderPendingCustomerConfirmation: "Bestätigung ausstehend",
    orderConfirmedCustomerConfirmation: "Vom Kunden bestätigt",
    desireDeliveryDateTitle: "Wunschlieferdatum",
    lineItemsTitle: "Artikelliste",
    calendarWeek: "Kalenderwoche",
    lineItemCalendarWeekNotValid: "Nicht möglich",
    calendarWeekText: "Vom {{ date1 }} bis {{ date2 }}",
    offerDownPaymentIsNotSet: "Nicht festgelegt",
    companyIsNotApproved: "Dieses Unternehmen hat noch keine Freigabe!",
    orderVersion: "Version",
    orderChannelName: "Kanal",
    orderLastPublishAt: "Veröffentlicht am",
    orderLastPublishDate: "Letztes Veröffentlichungsdatum",
    orderLastPublishedVersion: "Letzte veröffentlichte Version",
    orderLastPublishedBy: "Letztmalig veröffentlicht von",
    media: "Medien",
    confirmDeleteAddress:
      "Sind Sie sicher, dass Sie diese Adresse löschen möchten?",
    offerFinancingInfo: "Finanzierungsinformationen",
    orderFinancingInfo: "Finanzierungsinformationen",
    orderAssignedToCommissionGroup: "Verknüpfung zuweisen",
    orderSalesPersonTitle: "Ersteller (Verkauf) ",
    orderSecondSalesPersonTitle: "2. Verkäufer(in)",
    orderSecondSalesPersonShareTitle: "Vertragsprovision teilen",
    lineItemInfoTitle: "Info DE",
    lineItemPriceTitle: "Price DE",
    lineItemProductGroupTitle: "zugeordnete Produktgruppe",
    lineItemSalesPersonCommissionTitle: "Sales commission DE",
    lineItemStatusInfo: "Status",
    selectChannel: "Kanal auswählen",
    currentErpStatus: "Aktueller Status ERP", // order/order line item details
    allFilterItem: "Alle",
    noPermissionPageTitle: "Keine Berechtigung zur Anzeige der Seite",
    noPermissionPageText: "Bitte etwas anderes probieren.",
    noPermissionDashboardTitle: "Keine Berechtigung zur Anzeige des Dahboards ",
    noPermissionDashboardText: "Bitte mit einem anderen Konto anmelden",
  },
  status: {
    customer: {
      notRegistered: "Nicht registriert",
      onboarding: "In Einarbeitung",
      registered: "Registriert",
    },
    company: {
      approved: "Genehmigt",
      pending: "In Bearbeitung",
      suspended: "Ausgesetzt",
    },
    offer: {
      none: "-",
      active: "Aktiv",
      accepted: "Angenommen",
      rejected: "Abgelehnt",
    },
    order: {
      published: "Veröffentlicht",
      notPublished: "Nicht veröffentlicht",
    },
    incident: {
      active: "Aktiv",
      resolved: "Gelöst",
      canceled: "Abgebrochen",
    },
    incidentReason: {
      notDefined: "Nicht definiert",
      inProgress: "In Bearbeitung",
      onHold: "Angehalten",
      waitingForDetails: "Warten auf Details",
      researching: "Wird qualifiziert",
      new: "Neu",
      processStarted: "Prozess gestartet",
      waitingForTechnicalInspection: "Waren auf Technische Beurteilung",
      manufactureServiceOrder: "Lieferanten Reklamationsbestellung",
      problemSolved: "Problem gelöst",
      informationProvided: "Informationen bereitgestellt",
      cancelled: "Abgebrochen",
      merged: "Zusammengeführt",
    },
    incidentActivity: {
      open: "Offen",
      completed: "Abgeschlossen",
      canceled: "Abgebrochen",
    },
    sales: {
      notPublished: "--",
      none: "Veröffentlicht",
      backorder: "In Bearbeitung",
      delivered: "Geliefert",
      invoiced: "Rechnung erstellt",
      canceled: "storniert",
    },
    purchase: {
      notPublished: "--",
      none: "Nicht verarbeitet",
      backorder: "In Bearbeitung",
      received: "Erhalten",
      invoiced: "Rechnung erstellt",
      canceled: "storniert",
    },
    document: {
      notPublished: "--",
      none: "Nicht verarbeitet",
      purchaseOrder: "Lieferantenbestellung offen",
      confirmation: "Bestellung bestätigt",
      pickingList: "Kommissionierliste",
      pickingSlip: "Lieferschein",
      invoiced: "Rechnung erstellt",
    },
    CRMTaskReason: {
      notStarted: "nicht gestartet",
      inProgress: "in Bearbeitung",
      waitingOnSomeoneElse: "Warte auf Aktivität anderer",
      deferred: "Verweis",
      completed: "Komplett",
      cancelled: "Gelöscht",
    },
    availability: {
      active: "Aktiv",
      inactive: "Bestellstopp Bestand",
      salesOnly: "Abverkauf",
      blocked: "Verkaufsstopp",
    },
    sellOffProduct: {
      preparation: "Vorbereitung",
      active: "Aktiv",
      sold: "Verkauft",
      needPhaseChange: "Phasenwechsel erforderlich",
      needClose: "Schließen erforderlich",
      closed: "Geschlossen",
      notSold: "Nicht verkauft",
    },
    printRequest: {
      onProcess: "in Bearbeitung",
      completed: "Komplett",
      failed: "Kein Druck erfolgt",
    },
    pickup: {
      none: "Abholbereit",
      pickupRequested: "Abholanfrage",
      pickupRequestCanceled: "Abholung abgebrochen",
      pickedUp: "PABholung durch Kunden erfolgt",
    },
  },
  types: {
    customer: {
      company: "Unternehmen",
      person: "Privatperson",
    },
    gender: {
      unknown: "Unbekannt",
      male: "Männlich",
      female: "Weiblich",
      diverse: "Divers",
      notDefined: "Nicht definiert",
    },
    salutation: {
      unknown: "Unbekannt",
      company: "Firma",
      family: "Familie",
      mr: "Herr",
      mrs: "Frau",
      notProvided: "Nicht angegeben",
      divers: "Divers",
      mrAndMrs: "Herr und Frau",
    },
    salutationTitle: {
      dr: "Dr.",
      prof: "Prof.",
      mag: "Mag.",
    },
    offerSortBy: {
      none: "Standard",
      creationDate: "Erstellungsdatum",
      lastUpdate: "Letzte Aktualisierung",
      offerTitle: "Angebotstitel",
    },
    product: {
      pim: "PIM Produkt",
      iwofurn: "Iwofurn",
      programManual: "Programmprodukt",
      programConfigurable: "Konfigurierbar",
    },
    supplier: {
      tradingGoods: "Handelsware",
      consumableGoods: "Verbrauchsware",
    },
    program: {
      none: "Kein",
      manual: "Manuell",
      configurable: "Konfigurierbar",
    },
    supplierContactPersonDepartment: {
      general: "Allgemein",
      sales: "Verkauf",
      logistics: "Logistik",
      marketing: "Marketing",
      support: "Service",
      other: "andere",
    },
    supplierShippingDurationUnit: {
      none: "keine",
      workDay: "Arbeitstage",
      calendarDay: "Kalendertage",
    },
    document: {
      template: "Vorlage",
      personal: "Persönliches Dokument",
    },
    incidentPriority: {
      notDefined: "Nicht definiert",
      high: "Hohe Priorität",
      normal: "Normale Priorität",
      low: "Niedrige Priorität",
    },
    incidentOrigin: {
      notDefined: "Nicht definiert",
      phone: "Telefon",
      email: "E-Mail",
      web: "Webformular",
    },
    incidentTopic: {
      notDefined: "Nicht definiert",
      generalRequest: "Allgemeine Anfrage",
      productRequest: "Produktanfrage",
      deliveryDateAgreement: "Lieferterminvereinbarung",
      reserveVan: "Transporter reservieren",
      purchaseAgreementChange: "Kaufvertragsänderung",
      customerComplaint: "Kundenbeschwerde",
    },
    incidentActivity: {
      notDefined: "Nicht definiert",
      email: "E-Mail",
      phoneCall: "Telefonanruf",
      appointment: "Termin",
      task: "Aufgabe",
      post: "Post",
      note: "Notiz",
      incidentresolution: "Problembehebung",
    },
    lineItem: {
      pimProduct: "PIM Produkt",
      iwofurnProduct: "Iwofurn Produkt",
      customProduct: "Katalogprodukt",
      productService: "Dienstleistung",
      bundle: "Kopfartikel",
      pimBundle: "Bundle Produkte",
      pimBundleItem: "Bundle Produkt Item",
      sellOffProduct: "Abverkaufsprodukt",
      sellOffBundle: "Abverkauf-Bundler",
      sellOffBundleItem: "Abverkauf Bundle-Einheit",
    },
    taskPriority: {
      low: "niedrig",
      normal: "normal",
      high: "hoch",
    },
    calculationValue: {
      percent: "Prozent",
      amount: "Betrag",
      multiply: "Multipikation",
      pureProfit: "prozentualer Aufschlag",
    },
    crmTarget: {
      incident: "Vorfall",
      lead: "Lead",
      account: "Konto",
      contact: "Kunde",
      order: "Auftrag",
    },
    documentTags: {
      sendToCustomer: "An Kunden senden",
      contract: "bei Bestellung anfügen",
      information: "Information",
    },
    pimProduct: {
      standard: "Einzelprodukt",
      bundle: "Bundleprodukt",
    },
    amountCalculation: {
      amount: "Betrag",
      percent: "Prozent",
    },
    sellOffProductTimelineAction: {
      none: "Keins",
      productCreated: "Produkt erstellt",
      phaseStarted: "Phase gestartet",
      phaseChanged: "Phase gewechselt",
      productSold: "Produkt verkauft",
      phaseClosed: "Phase geschlossen",
      soldProductWithdrew: "Verkauftes Produkt zurückgezogen",
    },
    salePriceTemplateItemSteps: {
      system1: "System 1",
      system2: "System 2",
      automaticDiscounts: "Automatische Discounts",
      catalogCalculation: "Katalogkalkulation",
      optionalDiscounts: "Optionale Discounts",
      individualDiscounts: "Individuelle Rabatte",
    },
  },
  rules: {
    required: "Feld ist erforderlich",
    email: "Die E-Mail ist ungültig",
    postalCode: "Die Postleitzahl muss 5 Zeichen lang sein",
    integer: "Nur ganze Zahlen sind gültig",
    phoneNumber: "Das Feld darf nicht mit 0 beginnen",
    percent: "Die Prozentangabe muss zwischen 0 und 100 liegen",
    minmax: "Der Wert muss zwischen {{ min }} und {{ max }} liegen",
    quantity: "Der Wert NULL ist nicht möglich",
  },
  radioButton: {
    desireDeliveryDatePickDate: "Datum wählen",
    desireDeliveryDateMarkWeek: "Woche markieren",
    active: "Aktiv",
    block: "Geblockt",
    basketImportAsSingleItem: "Importieren als Einzelartikel",
    basketImportAsBundle: "Importieren als Bundle-Produkt",
  },
  button: {
    filter: "Filter",
    action: "Aktion",
    addNew: "Neu hinzufügen",
    ok: "Ok",
    cancel: "Abbrechen",
    back: "Zurück",
    close: "Schließen",
    change: "Ändern",
    delete: "Löschen",
    next: "Weiter",
    submit: "Abschicken",
    submitChanges: "Änderungen einreichen",
    search: "Suchen",
    viewDetails: "Details anzeigen",
    choose: "Wählen",
    submitCustomer: "Kunde übermitteln",
    deactivateAccount: "Konto deaktivieren",
    addOffer: "Angebot hinzufügen",
    saveAndCloseOffer: "Speichern und schließen",
    nextAndCompleteOffer: "Weiter und abschließen",
    rejectOffer: "Angebot ablehnen",
    acceptOffer: "Angebot annehmen",
    cancelForNow: "Jetzt abbrechen",
    submitOffer: "Angebot einreichen",
    setOfferDownPayment: "Anzahlung festlegen",
    chooseFile: "Datei wählen",
    createDocument: "Dokument erstellen",
    requireSigned: "Unterschrift erforderlich",
    optionalSigned: "Unterschrift optional",
    addDocument: "Dokument hinzufügen",
    resetFilter: "Filter zurücksetzen",
    changeStore: "Filiale wechseln",
    changeSaleChannel: "Verkaufskanal wechseln",
    selectStore: "Filiale wählen",
    selectSaleChannel: "Verkaufskanal wählen",
    clear: "Löschen",
    mobileAccount: "App-Benutzer",
    haveNotMobileAccount: "Kein App-Benutzer",
    clickToSign: "Hier unterschreiben",
    showInfo: "Mehr Infos",
    addAttribute: "Attribut hinzufügen",
    addProduct: "Produkt hinzufügen",
    addLineItem: "Position hinzufügen",
    addAddress: "Adresse hinzufügen",
    orderDownPaymentQrCode: "QR-Code",
    day: "Tag",
    month: "Monat",
    year: "Jahr",
    useCustomerAddress: "Kundenadresse verwenden",
    productList: "Produktliste",
    calculate: "Preis berechnen",
    importProducts: "Produkte importieren",
    submitIncident: "Anfrage einreichen",
    createNewIncident: "Neue Anfrage erstellen",
    attachProductsIncident: "Produkte zu Anfrage hinzufügen",
    viewOfferDetails: "Angebotdetails anzeigen",
    viewOrderDetails: "Bestelldetails anzeigen",
    backToBasketList: "Zurück zur Liste",
    importBasket: "Iwofurn Korb importieren",
    showCalendar: "Kalender anzeigen",
    hideCalendar: "Kalender verbergen",
    submitAppointment: "Termin einreichen",
    submitTodo: "Aufgabe einreichen",
    checkOfferCompleted: "Angebot überprüfen",
    editLineItem: "Position bearbeiten",
    addBillingAddress: "Rechnungsadresse hinzufügen",
    addShippingAddress: "Lieferadresse hinzufügen",
    editBillingAddress: "Rechnungsadresse bearbeiten",
    editShippingAddress: "Lieferadresse bearbeiten",
    documentDownload: "Dokument herunterladen",
    addLineItemDiscount: "Rabatt hinzufügen",
    applyLineItemDiscount: "Rabatt anwenden",
    setLineItemDesireDeliveryDate: "Wunschlieferdatum festlegen",
    setOfferDesireDeliveryDate: "Wunschlieferdatum festlegen",
    setShippingMethodToAllLineItems:
      "Lieferart für alle Positionen berücksichtigen",
    lineItemDetails: "Positionsdetails",
    addSubLineItem: "Unterposition hinzufügen",
    details: "Details",
    edit: "Bearbeiten",
    publishOrder: "Bestellung veröffentlichen",
    republishOrder: "Bestellung erneut veröffentlichen",
    submitEditOrder: "Bestellung einreichen",
    allOrderLineItems: "Alles amrkeieren",
    activeOrderLineItems: "Aktive Positionen",
    canceledOrderLineItems: "Stornierte Positionen",
    cancelOrderLineItem: "Position stornieren",
    submitCancelOrderLineItem: "Positionsstornierung einreichen",
    addBundleLineItem: "Kopfartikel hinzufügen",
    addBundleCatalogueLineItem: "Kopfartikel hinzufügen ٍِ",
    addOrderSecondSalesPerson: "Zweitperson Verkauf",
    cancelOrderSecondSalesPerson: "Löschen",
    submitOrderSecondSalesPerson: "2. Verkaufsperson bestätigenn",
    status: "Status",
    checkTaskTarget: "überprüfen",
    allOrders: "Alle",
    useAuctions: "Use auctions DE",
  },
  tableTitles: {
    lineItemName: "Position",
    lineItemSupplier: "Lieferant",
    lineItemAction: "Aktion",
    lineItemUnitPrice: "Einzelpreis",
    lineItemQuantity: "Menge",
    lineItemPrice: "Positionspreis",
    lineItemProgramCode: "Programmcode",
    lineItemSupplierILN: "Lieferanten-ILN",
    basketInternalTag: "Interner Korb-Tag",
    basketTag: "Tag",
    basketCreatedAt: "Erstellt am",
    basketSenderName: "Absendername",
    basketPrice: "Iwofurn Korbpreis",
    lineItemBrand: "Brand DE",
  },
  drawerTitles: {
    filter: "Filter",
    createOffer: "Angebot erstellen",
    editOffer: "Angebot bearbeiten",
    acceptOffer: "Angebot annehmen",
    rejectOffer: "Angebot ablehnen",
    personalDocument: "Persönliche Dokumente",
    documentTemplate: "Dokumentvorlage wählen",
    shipTo: "Lieferadresse",
    billTo: "Rechnungsadresse",
    addProduct: "Produkt hinzufügen",
    chooseProgram: "Programm wählen",
    chooseSupplier: "Lieferant wählen",
    editOrderInfo: "Bestellinformationen bearbeiten",
    createNewIncident: "Neue Anfrage hinzufügen",
    attachProductsIncident: "Produkte zur Anfrage hinzufügen",
    settings: "Einstellungen",
    languages: "Sprache",
    importBasket: "Produkte von IWOFURN importieren",
    addNewAppointment: "Neuen Termin hinzufügen",
    addNewTodo: "Neue Aufgabe hinzufügen",
    searchPimProduct: "PIM-Produkte suchen",
    searchBundleProduct: "Suche Bundle Produkte",
    lineItem: "Position",
    bundle: "Kopfartikel",
    subLineItem: "Zugehöriger Artikel",
    editLineItem: "Position bearbeiten",
    lineItemOptionalDiscount: "Optionale Rabatte hinzufügen",
    createNewBundle: "Neuen Kopafrtikel erstellen",
    createProductFromCatalogue: "Produkt aus Katalog erstellen",
    publishOrder: "Bestellung veröffentlichen",
    republishOrder: "Bestellung erneut veröffentlichen",
    lineItemDocument: "Positions-Dokument",
    editOrder: "Bestellung bearbeiten",
    chooseCommissionGroup: "Verbundene Aufträge auswählen",
    addOrderSecondSalesPerson: "Verkausfprovision teilen mit",
    orderStatus: "Bestellstatus",
    erpSalesStatuses: "Auftragsstatus",
    erpSalesDocumentStatuses: "Vertrag Dokumentenstatus",
    erpPurchaseStatuses: "Bestellstatus",
    erpPurchaseDocumentStatuses: "Bestelldokument Status",
    searchSellOffProducts: "Suche Abverkaufsprodukte",
    orderUpFrontPayments: "Payment status DE",
  },
  modalTitles: {
    favoriteProductDetails: "Produktdetails",
    rejectInfo: "Ablehnen",
    updateInfo: "Aktualisieren",
    downPayment: "Anzahlung",
    documentType: "Dokumenttyp wählen",
    signHere: "Hier unterschreiben",
    mobileAccountInfo: "App-Benutzerinformationen",
    deleteProduct: "Produkt löschen",
    selectProductList: "Produktliste wählen",
    shareQrCodeWithCustomer: "Zahlungs-QR-Code teilen",
    confirmDeleteDocument: "Dokument löschen bestätigen",
    assignedOffer: "Zugewiesenes Angebot",
    assignedOrder: "Zugewiesene Bestellung",
    acceptOfferInfo: "Angebotspublikationsinformationen",
    chooseCalendarTaskType: "Aufgabentyp wählen",
    chooseCustomer: "Kunde wählen",
    offerCompleteInfo: "Angebot Abschlussinfo",
    addDeliveryDate: "Lieferdatum",
    desireDeliveryDate: "Wunschlieferdatum",
    cancelOrderLineItem: "Position stornieren",
    confirmDeleteAddress: "Adresse löschen bestätigen",
    setShippingMethodToAllLineItems: "Lieferart für alle Positionen übernehmen",
  },
  actionTitles: {
    editOffer: "Angebot bearbeiten",
    sendEmailOffer: "Angebot per E-Mail senden",
    rejectOffer: "Angebot ablehnen und löschen",
    customerDetailsOffer: "Kundeninformationen",
    personalDocument: "Dokument hochladen",
    templateDocument: "Vorlagen auswählen",
    configProduct: "Produkt konfigurieren",
    searchProduct: "Produkte suchen",
    importBasket: "IWOFURN-Korb importieren",
    addBundleLineItem: "Kopfartikel erstellen",
    addBundleCatalogueLineItem: "Kopfartikel aus Katalog erstellen",
    createProductFromCatalogue: "Produkt aus Katalog erstellen",
    editProduct: "Produkt bearbeiten",
    addSubProduct: "Unterprodukt hinzufügen",
    deleteProduct: "Produkt löschen",
    searchBundleProduct: "Bundle Produkt aus PIM",
    searchSellOffProduct: "Suche Abverkaufsprodukte",
    editOrder: "Bestellung bearbeiten",
    assignedOffer: "Angebot zuweisen",
    sendOrderEmail: "Bestellung per E-Mail senden",
    assignToCommissionGroup: "Verbundene Aufträgen zuweisen",
    setManual: "setze manuelle Berarbeitung",
    editDeliveryDate: "Lieferdatum bearbeiten",
    deleteDeliveryDate: "Lieferdatum löschen",
    addNewAppointment: "Neuen Termin hinzufügen",
    addNewTodo: "Neue Aufgabe hinzufügen",
    editTask: "Aufgabe bearbeiten",
    deleteTask: "Aufgabe löschen",
  },
  actionDescriptions: {
    personalDocument: "Vorhandenes Dokument hochladen",
    templateDocument: "Dokumente aus Vorlagen suchen und importieren",
    configProduct: "IWOFURN Produkt konfigurieren",
    searchProduct: "Produkte von PIM importieren",
    importBasket: "Von IWOFURN importieren",
    addBundleLineItem: "Kopfartikel aus einem Programm erstellen",
    addBundleCatalogueLineItem: "Kopfartikel aus einem Katalog erstellen",
    createProductFromCatalogue: "Manuell ein Produkt aus Katalog erstellen",
    searchBundleProduct: "Produkt-Bundle aus PIM wählen",
    searchSellOffProduct: "Aus den Abverkaufsprodukten importieren",
  },
  tabs: {
    basicInfo: "Allgemeine Informationen",
    contactInfo: "Kontaktinformationen",
    location: "Standort",
    completed: "Abgeschlossen",
    overview: "Übersicht",
    communication: "Werbekanäle",
    offers: "Angebote",
    orders: "Bestellungen",
    favoritesProducts: "Favoriten",
    customerIncidents: "Vorfälle",
    commissions: "Auftrag verknüpfen",
    addresses: "Adressen",
    lineItems: "Artikel",
    logistic: "Logistik",
    billing: "Rechnungsstellung",
    offerDocuments: "Angebotsdokument",
    orderDocuments: "Bestelldokumente",
    notes: "Notizen",
    orderIncidents: "Servicefälle",
    orderSalesInfo: "Informationen zum Auftrag",
  },
  tabsDescription: {
    basicInfo: "Name und andere grundlegende Details",
    contactInfo: "E-Mail und Telefonnummer",
    location: "Adresse und Standortinformationen",
    completed: "Abschlussinformationen",
  },
  formControls: {
    firstName: "Vorname",
    lastName: "Nachname",
    gender: "Geschlecht",
    salutation: "Anrede",
    salutationTitle: "Titel",
    birthDate: "Geburtsdatum",
    email: "E-Mail-Adresse",
    countryCode: "Ländercode",
    mobileNumber: "Handynummer",
    phoneNumber: "Telefonnummer",
    searchAddress: "Adresse suchen",
    name: "Name in Adresse",
    additionalInfo: "zusätzliche Informationen",
    street: "Straße",
    number: "Hausnummer",
    postalCode: "Postleitzahl",
    city: "Stadt",
    state: "Bundesland",
    country: "Land",
    description: "Beschreibung",
    offerTitle: "Angebotstitel",
    offerNote: "Angebotsnotiz",
    offerCustomerNote: "Kundennote zum Angebot",
    offerSupplierNote: "Lieferantennote zum Angebot",
    lineItemNote: "Positionsnotiz",
    lineItemCustomerNote: "Kundennotiz zur Position",
    lineItemSupplierNote: "Lieferantennote zur Position",
    CustomerFirstName: "Kundenvorname",
    CustomerLastName: "Kundenachname",
    CustomerEmailAddress: "Kunden-E-Mail-Adresse",
    CustomerMobileNumber: "Kundenhandynummer",
    CustomerPhoneNumber: "Kundentelefonnummer",
    reason: "Grund",
    paymentDueDate: "Zahlungsdatum",
    orderTitle: "Bestellungstitel",
    orderNote: "Bestellnotiz",
    orderEnableDeadline: "Kunden hat Bedenkzeit",
    orderDeadline: "Bedenkzeit bis",
    orderSecondSalesPerson: "Provisionsteilung Verkauf (2. Verkaufsperson",
    orderTaxFree: "Tax free DE",
    downPayment: "Anzahlung",
    downPaymentDueDate: "Fälligkeitsdatum der Anzahlung",
    downPaymentReason: "Gründe für Ausnahme",
    documentTitle: "Dokumenttitel",
    note: "Notiz",
    file: "Datei",
    addedSince: "Hinzugefügt seit",
    lineItemTitle: "Positionstitel",
    lineItemEan: "EAN",
    articleNumber: "Artikelnummer",
    price: "Preis",
    quantity: "Menge",
    supplierArticleNumber: "Lieferantenartikelnummer",
    deActiveCustomer: "Kundenkonto deaktivieren bestätigen",
    incidentTitle: "Servicefall",
    incidentPriority: "Priorität des Vorfalls",
    incidentOrigin: "Herkunft des Vorfalls",
    incidentTopic: "Thema des Vorfalls",
    incidentTopicDescription: "Beschreibung zum Vorfallsthema",
    incidentAttachmentProducts: "Produkte zum Vorfall hinzufügen",
    incidentCustomerMood: "Kundenstimmung zum Vorfall",
    incidentDeadline: "Frist für den Vorfall",
    incidentDescription: "Beschreibung des Vorfalls",
    removeBasketAfterImport: "Korb nach Import entfernen",
    calendarTaskTime: "Zeit für Kalenderaufgabe",
    calendarTaskSyncToUser: "Mit Benutzer synchronisieren",
    calendarTaskTitle: "Titel der Kalenderaufgabe",
    calendarTaskDescription: "Beschreibung der Kalenderaufgabe",
    supplier: "Lieferant",
    program: "Programm",
    priceFrom: "Preis von",
    priceTo: "Preis bis",
    desireDeliveryDateWeek: "Wunschwoche",
    desireDeliveryDateYear: "Wunschjahr",
    lineItemCatalogueProductTitle: "Produkttitel im Katalog",
    lineItemCatalogueTitle: "Titel der Katalogposition",
    lineItemCatalogueSupplierArticleNumber:
      "Lieferantenartikelnummer im Katalog",
    lineItemCatalogueEAN: "EAN des Katalogprodukts",
    lineItemCatalogueSalesPrice: "Verkaufspreis im Katalog",
    lineItemCatalogueQuantity: "Menge im Katalog",
    lineItemCatalogueDescription: "Beschreibung im Katalog",
    lineItemConfigurationText: "Konfigurationstext",
    lineItemBundleTitle: "Kopfartikel Name",
    lineItemBundleQuantity: "Menge",
    lineItemBundleDescription: "Beschreibung",
    lineItemBundleCatalogueTitle: "Kopfartikel Name",
    lineItemBundleCatalogueQuantity: "Menge",
    lineItemBundleCatalogueDescription: "Beschreibung",
    applyDesireDeliveryDateToAllLineItemCheckBox:
      "Wunschlieferdatum auf alle Positionen anwenden",
    title: "Titel",
    financing: "Finanzierung",
    orderSecondSalesPersonShare: "Verkaufsprovision teilen",
    documentTags: "Tags",
    blockReason: "Sperrgrund",
    expireAt: "gültig bis",
  },
  togglers: {
    requiredSign: "Unterschrift erforderlich",
    erp: "In ERP enthalten",
    signedByCustomer: "Vom Kunden unterschrieben",
    calendarTaskAllDay: "Ganztägig",
  },
  confirms: {
    addOffer:
      "Ich bestätige die ausgewählte Filiale und die Bestellung wird in der ausgewählten Filiale erstellt.",
    acceptOffer: "Ich bestätige, dass das Angebot vom Kunden angenommen wird.",
    rejectOffer: "Ich bestätige, dass das Angebot vom Kunden abgelehnt wird.",
    deleteProduct: "Möchten Sie dieses Produkt wirklich entfernen?",
    cancelOrderLineItem:
      "Sind Sie sicher, dass Sie diese Position stornieren möchten?",
  },
  placeholders: {
    searchAddressCountry: "Land suchen",
    searchAddress: "Adresse suchen",
    firstName: "Vorname",
    lastName: "Nachname",
    gender: "Bitte Geschlecht auswählen",
    salutation: "Anrede auswählen",
    salutationTitle: "Titel auswählen",
    birthDate: "Geburtsdatum wählen",
    email: "Bitte E-Mail-Adresse eingeben",
    mobileNumber: "Beispiel: +49 1645687890",
    phoneNumber: "Beispiel: +49 1645687890",
    name: "Adressinfo / Name in Adresse",
    additionalInfo:
      "Bitte ggf. an dieser Stelle die Etage oder weitere Adresshinweise einfügen",
    street: "Straße",
    number: "Hausnummer",
    postalCode: "Postleitzahl",
    city: "Stadt",
    state: "Bundesland",
    country: "Land",
    documentTitle: "Titel",
    documentNote: "Weitere Infos",
    value: "Wert",
    attributeName: "Name",
    code: "Code",
    searchPimProduct: "Produktname oder Artikelnummer suchen",
    incidentTitle: "Serviceanfrage",
    incidentPriority: "Priorität wählen",
    incidentOrigin: "Herkunft wählen",
    incidentTopic: "Thema wählen",
    incidentTopicDescription: "Themenbeschreibung",
    incidentAttachmentProducts: "Produkte auswählen",
    incidentDeadline: "Abgabetermin",
    incidentDescription: "Beschreibung auswählen",
    searchBaskets: "IWOFURN-Korb-ID oder Namen suchen",
    calendarTaskTime: "Zeit auswählen",
    title: "Titel",
    searchOrderSecondSalesPerson: "Suche Verkäuferin",
    select: "Auswählen",
  },
  alertTitles: {
    deactivateAccount:
      "Sind Sie sicher, dass Sie dieses Kundenkonto deaktivieren möchten?",
    supplierLineItemRequired:
      "Es gibt eine Position in diesem Angebot, die keinen Lieferanten hat. Bitte überprüfen Sie das.",
    supplierSubLineItemRequired:
      "Es gibt eine Unterposition in diesem Angebot, die keinen Lieferanten hat. Bitte überprüfen Sie das.",
    programLineItemRequired:
      "Es gibt eine Position in diesem Angebot, die kein Programm hat. Bitte überprüfen Sie das.",
    programSubLineItemRequired:
      "Es gibt eine Unterposition in diesem Angebot, die kein Programm hat. Bitte überprüfen Sie das.",
    addressRequired: "Rechnungs- und Lieferadressen nicht festgelegt.",
    documentRequired:
      "Es gibt Dokumente, die nicht vom Kunden unterschrieben sind.",
    lineItemsRequired: "Required line items DE",
    paymentConditionRequired: "Zahlungsbedingungen nicht festgelegt.",
    productSupplierNotSet: "Lieferant nicht festgelegt.",
    productProgramNotSet: "Programm nicht festgelegt.",
    offerRequiredDocument: "Erforderliche Dokumente nicht vorhanden.",
    offerRequiredDesiredDeliveryDate: "Gewünmschtes Lieferdatum",
    offerRequiredAddress: "Erforderliche Adresse nicht festgelegt.",
    offerRequiredPaymentConditions:
      "Erforderliche Zahlungsbedingungen nicht festgelegt.",
    offerDownPaymentEmpty:
      "Eine Anzahlung kann für dieses Angebot nocht festgelegt wertden: Keine Verkaufsartikel vorhanden",
    downPaymentMoreThanTotalAmount: "Zahlungslimit überschritten (> 100%)",
    allOfferLineItemMustHaveShippingMethod:
      "Alle Angebotspostionen müssen eine Versandmethode haben.",
    allOrderLineItemMustHaveShippingMethod:
      "Alle Bestellpositionen müssen eine Versandmethode haben.",
    canceledOrderLineItem: "Diese Position wurde bereits storniert.",
    orderStatus: "Auftragstatus",
    blockedOrder: "Blockiert",
    orderExpireAt: "Blockiert bis",
    allOfferLineItemMustHaveShippingDate:
      "Bitte Lieferart des Verkaufsartikels angeben",
  },
  alertDescriptions: {
    deactivateAccount:
      "Über diese Aktion wird der Kunde per E-Mail benachrichtigt.",
    supplierLineItemRequired:
      "Es gibt eine Position in diesem Angebot, die keinen Lieferanten hat. Bitte überprüfen Sie das.",
    supplierSubLineItemRequired:
      "Es gibt eine Unterposition in diesem Angebot, die keinen Lieferanten hat. Bitte überprüfen Sie das.",
    programLineItemRequired:
      "Es gibt eine Position in diesem Angebot, die kein Programm hat. Bitte überprüfen Sie das.",
    programSubLineItemRequired:
      "Es gibt eine Unterposition in diesem Angebot, die kein Programm hat. Bitte überprüfen Sie das.",
    addressRequired: "Rechnungs- und Lieferadressen sind nicht festgelegt.",
    documentRequired:
      "Es gibt Dokumente, die nicht vom Kunden unterschrieben sind.",
    lineItemsRequired:
      "There are no line items. Please check line items tab. DE",
    paymentConditionRequired: "Zahlungsbedingungen sind nicht festgelegt.",
    downPaymentMoreThanTotalAmount:
      "Eine Anzahlung kann nicht höher sein als der Gesamtbetrag. Bitte anpassen",
    selectStore: "Bitte wählen Sie Ihre Filiale aus.",
    selectSaleChannel: "Bitte Verkaufskanal auswählen",
    haveNotMobileAccount: "Dieser Benutzer hat kein mobiles Konto!",
    customerRegistrationNotCompleted:
      "Die Registrierung ist nicht abgeschlossen!",
    allOfferLineItemMustHaveShippingMethod:
      "Alle Angebotspostionen müssen eine Versandmethode haben.",
    allOrderLineItemMustHaveShippingMethod:
      "Alle Bestellpositionen müssen eine Versandmethode haben.",
    canceledOrderLineItem: "Diese Position wurde bereits storniert.",
    allOfferLineItemMustHaveShippingDate:
      "Alle Bestellpositionen müssen eine Lieferart haben!",
  },
  menu: {
    titles: {
      customers: "KUNDEN",
      account: "KONTEN",
    },
    items: {
      customers: "Kunden",
      offers: "Angebote",
      orders: "Aufträge",
      products: "Produkte",
      incidents: "Servicefälle",
      suppliers: "Lieferanten",
      tasks: "Aufgaben",
      pickup: "Abholung",
      notifications: "Benachrichtigungen",
      settings: "Einstellungen",
      sellOffProducts: "Abverkaufsprodukte",
    },
  },
  toast: {
    success: {
      addCustomer: "Der Kunde wurde erfolgreich hinzugefügt.",
      editCustomer: "Der Kunde wurde erfolgreich bearbeitet.",
      removeCustomer: "Der Kunde wurde erfolgreich entfernt.",
      createOffer: "Das Angebot wurde erfolgreich erstellt.",
      editOffer: "Das Angebot wurde erfolgreich bearbeitet.",
      editOrder: "Die Bestellung wurde erfolgreich bearbeitet.",
      acceptOffer: "Das Angebot wurde erfolgreich angenommen.",
      rejectOffer: "Das Angebot wurde erfolgreich abgelehnt.",
      sendOfferMail: "Das Angebot wurde erfolgreich per E-Mail gesendet.",
      sendOrderMail: "Die Bestellung wurde erfolgreich per E-Mail gesendet.",
      copyCimNumber: "Die CIM-Nummer wurde erfolgreich kopiert.",
      copyOfferNumber: "Die Angebotsnummer wurde erfolgreich kopiert.",
      copyOrderNumber: "Die Bestellnummer wurde erfolgreich kopiert.",
      setDownPayment: "Die Anzahlung wurde erfolgreich festgelegt.",
      addDocument: "Das Dokument wurde erfolgreich hinzugefügt.",
      editDocument: "Das Dokument wurde erfolgreich bearbeitet.",
      removeDocument: "Das Dokument wurde erfolgreich entfernt.",
      addSubLineItem: "Die Unterposition wurde erfolgreich hinzugefügt.",
      addLineItem: "Die Position wurde erfolgreich hinzugefügt.",
      editSubLineItem: "Die Unterposition wurde erfolgreich bearbeitet.",
      editLineItem: "Die Position wurde erfolgreich bearbeitet.",
      removeSubLineItem: "Die Unterposition wurde erfolgreich entfernt.",
      removeLineItem: "Die Position wurde erfolgreich entfernt.",
      addIncident: "Der Vorfall wurde erfolgreich hinzugefügt.",
      importIwofurnProduct:
        "Das Produkt wurde erfolgreich von IWOFURN importiert.",
      importBasket: "Die Korbartikel wurden erfolgreich importiert.",
      offerDesiredDeliveryDate:
        "Das Wunschlieferdatum wurde erfolgreich aktualisiert.",
      orderDesiredDeliveryDate:
        "Das Wunschlieferdatum wurde erfolgreich aktualisiert.",
      lineItemDesiredDeliveryDate:
        "Das Wunschlieferdatum wurde erfolgreich aktualisiert.",
      lineItemReserve: "Die Position wurde erfolgreich reserviert.",
      lineItemUnreserve:
        "Die Reservierung der Position wurde erfolgreich aufgehoben.",
      publishOrder: "Die Bestellung wurde erfolgreich veröffentlicht.",
      cancelSubLineItem: "Die Unterposition wurde erfolgreich storniert.",
      cancelLineItem: "Die Position wurde erfolgreich storniert.",
      addCustomerAddress: "Die Kundenadresse wurde erfolgreich hinzugefügt.",
      editCustomerAddress: "Die Kundenadresse wurde erfolgreich bearbeitet.",
      removeCustomerAddress: "Die Kundenadresse wurde erfolgreich entfernt.",
      setShippingMethodToAllLineItems:
        "Positionen : Lieferart wurde aktualisiert",
      orderStatus: "Status erfolgreich geändert",
      editSalesTemplate: "Das Verkaufstemplate wurde erfolgreich erstellt.",
    },
    warning: {
      suggestedAddress:
        "Die Adresse wurde geändert. Bitte überprüfen Sie diese vor der Einreichung.",
      informedOrderVersion:
        "Bitte beachten Sie, dass diese Version nicht die letzte veröffentlichte Version ist. Bitte veröffentlichen Sie die aktuelle Version.",
    },
    error: {
      favoriteProductNotExistsInCurrentChannel:
        "Produkt in gewählten Kanal nicht verfügbar",
    },
  },
  offerPaymentDetails: {
    text: "Die Zahlungsdetails können Sie hier einsehen",
    charges: "Gebühren",
    quantity: "Menge",
    total: "Gesamt",
    lineItems: "Positionen",
    downPayment: "Anzahlung",
  },
  page: {
    customerList: {
      searchBox: "Suche...",
    },
    addNewCustomer: {
      title: "neuer Kunde",
      conditions: {
        title: "Geschäftsbedingungen",
        text: "Muss vom Kunden akzeptiert werden",
        gdprAccepted: "DSGVO akzeptiert",
      },
    },
    customerDetails: {
      overview: {
        removeCustomer: {
          title: "Kundenkonto deaktivieren",
        },
      },
      communication: {
        byPhone: "Telefon",
        byApplication: "In-App-Benachrichtigung",
        bySocialMedia: "Whatsapp",
        byEmail: "E-Mail",
        bySMS: "SMS",
        byPost: "Post",
        familyBonusApplied: "Teilnahme Familienbonus",
        newsletterSubscribed: "Newsletter abonniert",
        bulkEmail: "E-Mail Verteiler",
      },
      offers: {
        filter: {
          status: {
            label: "Status",
          },
          sortBy: {
            label: "Sortieren nach",
          },
        },
        documents: {
          noDocumentTitle: "Kein Dokument vorhanden",
          noDocumentText: "Sie können kein Dokument hinzufügen oder löschen",
        },
      },
    },
    offers: {
      documents: {
        noDocumentTitle: "Kein Dokument vorhanden",
        noDocumentText: "Sie können kein Dokument hinzufügen oder löschen",
      },
    },
    orders: {
      statusTitle: {
        sales: "Auftrag Status: {{ status }}",
        salesDocument: "Auftrag Dokument Status: {{ status }}",
        purchase: "Bestellung Status: {{ status }}",
        purchaseDocument: "Bestellung Dokument Status: {{ status }}",
      },
      filterMenu: {
        title: "FILTER",
        resetButton: "Filter zurücksetzen",
        addDateRange: "Suche Datumsbereich von-bis",
        fromDate: "von",
        toDate: "bis",
        salesPerson: "Verkaufsperson",
        selectSalesPerson: "Verkaufsperson auswählen",
        salesPersonDrawerTitle: "Suche nach Verkaufspersonen",
        salesPersonSearchBox: "Suche nach Name oder Nummer",
        salesPersonChooseButton: "Auswählen",
        allItem: "Alle auswählen",
        processStatus: "Prozessstatus",
        salesStatus: "Auftragsstatus",
        salesDocumentStatus: "Aufragsdokument Status",
        purchaseStatus: "Bestellstatus",
        purchaseDocumentStatus: "Bestelldokument Status",
        cancelButton: "Abbrechen",
        submitFilterButton: "Filter bestätigen",
      },
    },
    lineItems: {
      statusTitle: {
        sales: "Auftrag Status: {{ status }}",
        salesDocument: "Auftragsdokument Status: {{ status }}",
        purchase: "Bestell Status: {{ status }}",
        purchaseDocument: "Bestelldokument Status: {{ status }}",
      },
    },
    calendar: {
      noItemTitle: "Dieser Tag ist frei",
      noItemDescription: "Kein Eintrag gefunden",
    },
  },
  company,
  incident,
  products,
  commissions,
  customers,
  task,
  suppliers,
  errorCodes,
  sellOffProducts,
  pickup,
};
export default de;
