import { Fragment } from "react";
import { Button, Icon, Modal, Text } from "../../../../components";
import { Company, iconNameType, togglePropsType } from "../../../../types";
type Props = togglePropsType & {
  person: Company.Person;
  onEdit: () => void;
  onDelete: () => void;
  onChangePrimary: () => void;
};
type ActionItem = {
  active: boolean;
  icon: iconNameType;
  label: string;
  className: string;
  action: () => void;
};
export default function ActionsModal({
  isOpen,
  toggle,
  person,
  onEdit,
  onDelete,
  onChangePrimary,
}: Props) {
  const handleOnEdit = () => {
    onEdit();
    toggle();
  };
  const handleOnDelete = () => {
    onDelete();
    toggle();
  };
  const handleOnChangePrimary = () => {
    onChangePrimary();
    toggle();
  };
  const actions: ActionItem[] = [
    {
      active: true,
      icon: "Edit2",
      className: "text-primary",
      label: "company.details.contactPerson.actions.edit",
      action: handleOnEdit,
    },
    {
      active: !person.isPrimary,
      icon: "Repeat",
      className: "text-primary",
      label: "company.details.contactPerson.actions.changeToPrimary",
      action: handleOnChangePrimary,
    },
    {
      active: !person.isPrimary,
      icon: "Trash",
      className: "text-danger",
      label: "company.details.contactPerson.actions.remove",
      action: handleOnDelete,
    },
  ];
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Modal.Header>
        <h6 className="mb-1">
          {person.firstName} {person.lastName}
        </h6>
        <span className="block text-secondary">{person.emailAddress}</span>
      </Modal.Header>
      <Modal.Body>
        {actions
          .filter((e) => e.active)
          .map((e, i) => (
            <Fragment key={e.label}>
              {i !== 0 && <hr className="bg-gray-100 w-full my-4" />}
              <button
                type="button"
                className="w-full flex-center gap-4"
                onClick={e.action}
                // disabled={loading.post}
              >
                <Icon.Wrapper variant="secondary">
                  <Icon name={e.icon} variant="Bold" className={e.className} />
                </Icon.Wrapper>
                <span className="flex-1 text-start">
                  <Text>{e.label}</Text>
                </span>
              </button>
            </Fragment>
          ))}
      </Modal.Body>
      <Modal.Footer className="flex-center">
        <Button
          light
          onClick={toggle}
          // loading={loading.post}
        >
          <Text>button.back</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
