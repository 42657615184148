import { twMerge } from "tailwind-merge";
import { cn } from "../methods";
import { classNameProps } from "../types";
import Text from "./_Text";

type togglerProps = {
  label?: string;
  disabled?: boolean;
  value: boolean;
  setValue: (val: boolean) => void;
} & classNameProps;
export default function Toggler({
  label,
  value,
  setValue,
  disabled,
  className,
}: togglerProps) {
  return (
    <div
      className={twMerge("w-full flex items-center justify-between", className)}
    >
      {!!label && (
        <h6 className="flex-1 text-base text-dark font-medium">
          <Text>{label}</Text>
        </h6>
      )}
      <button
        type="button"
        // className="toggler group relative w-11 h-10 px-2 py-3"
        className="toggler group relative w-11 h-[1.625rem]"
        onClick={() => setValue(!value)}
        disabled={disabled}
      >
        <div
          className={cn(
            "size-full rounded-full transition-colors shadow",
            // value ? "bg-primary" : "bg-gray-400"
            value ? "bg-primary" : "bg-placeholder"
          )}
        ></div>
        <div
          className={cn(
            "absolute top-0 bottom-0 size-5 transition-[left] my-auto",
            value ? "left-5" : "left-1"
          )}
        >
          {/* <span
            className={cn(
              "absolute -inset-2 rounded-full scale-0 transition-[transform,background-color] group-[.toggler:hover]:scale-100 opacity-20",
              value ? "bg-primary" : "bg-gray-400"
            )}
          /> */}
          <span
            // style={{
            //   boxShadow:
            //     "0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f",
            // }}
            className="absolute inset-0 bg-white rounded-full border border-gray-100"
          />
        </div>
      </button>
    </div>
  );
}
