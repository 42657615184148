import { useContext } from "react";
import toast from "react-hot-toast";
import { ContactPersonsContext } from ".";
import { CompanyContext } from "..";
import { Button, Modal, Text } from "../../../../components";
import { useAxios } from "../../../../hooks";
import { Company, togglePropsType } from "../../../../types";

type RemoveContactPersonProps = togglePropsType & {
  person: Company.Person;
};
export default function RemoveContactPerson({
  isOpen,
  toggle,
  person,
}: RemoveContactPersonProps) {
  const { companyData } = useContext(CompanyContext);
  const { getPersons } = useContext(ContactPersonsContext);
  const { axios, loading } = useAxios();
  const removeCustomer = () => {
    const url = `/accountservice/api/customers/${companyData.id}/as-company/company-contact-persons/${person.id}`;
    axios.delete(url).then(() => {
      toast.success("company.details.contactPerson.remove.removeMessage");
      getPersons();
      toggle();
    });
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Modal.Header className="text-dark">
        <h6 className="text-lg text-dark">
          <Text>company.details.contactPerson.remove.title</Text>
        </h6>
      </Modal.Header>
      <Modal.Body>
        <p className="text-secondary py-5">
          <Text>company.details.contactPerson.remove.text</Text>
        </p>
      </Modal.Body>
      <Modal.Footer className="flex items-center justify-end gap-4">
        <Button light onClick={toggle} disabled={loading.delete}>
          <Text>company.details.contactPerson.remove.cancelBtn</Text>
        </Button>
        <Button
          variant="danger"
          onClick={removeCustomer}
          loading={loading.delete}
        >
          <Text>company.details.contactPerson.remove.removeBtn</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
