import { ComponentProps, ElementType } from "react";
import { twMerge } from "tailwind-merge";
import { asProps } from "../types";
function Layout<E extends ElementType = "div">({
  as,
  className = "",
  children,
  ...props
}: asProps<E> & ComponentProps<E>) {
  const Component = as || "div";
  return (
    <Component className={`min-h-screen ${className}`} {...props}>
      {children}
    </Component>
  );
}
function LayoutHeader({
  className = "",
  children,
  ...props
}: ComponentProps<"header">) {
  return (
    <header
      className={`sticky top-0 right-0 bg-white shadow w-full z-10 ${className}`}
      {...props}
    >
      <div className="h-16 flex items-center gap-2 px-8 py-3 w-full max-w-screen-2xl mx-auto">
        {children}
      </div>
    </header>
  );
}
function LayoutBody({
  className = "",
  children,
  ...props
}: ComponentProps<"div">) {
  return (
    <div
      className={twMerge(
        "px-8 py-3 w-full max-w-screen-2xl mx-auto",
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
}
Layout.Header = LayoutHeader;
Layout.Body = LayoutBody;
export default Layout;
