import { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { CompanyContext } from "..";
import {
  Button,
  Icon,
  Pagination,
  Table,
  Text,
  WithPermission,
  Wrapper,
} from "../../../../components";
import { config } from "../../../../constants";
import { CRMRecordType } from "../../../../enums";
import { withPermission } from "../../../../hoc";
import { useAxios, useCrmCheckRecordExistence } from "../../../../hooks";
import { Incident, WithPaging } from "../../../../types";
import IncidentRow from "./IncidentRow";

function Incidents() {
  const navigate = useNavigate();
  const [checkRecordExistence, isChecking] = useCrmCheckRecordExistence();
  const { companyData } = useContext(CompanyContext);
  const { axios, loading } = useAxios();
  const [data, setData] = useState<WithPaging<Incident.Item>>({});
  const [params, setParams] = useState<Incident.Params>({
    pageNumber: 1,
    pageSize: config.pageSize,
    customerId: companyData.id,
  });

  const handleGoToCreateIncident = () => {
    checkRecordExistence({
      recordId: companyData.id,
      crmRecordType: CRMRecordType.Account,
    }).then(() => {
      navigate("/incidents/new", { state: { customer: companyData } });
    });
  };
  const handleSetParams = (key: keyof Incident.Params) => {
    return (val: any) => {
      if (key !== "pageNumber") {
        return setParams((p: any) => ({ ...p, [key]: val, pageNumber: 1 }));
      }
      setParams((p: any) => ({ ...p, [key]: val }));
    };
  };
  const getData = () => {
    const url = "/channelmanagerservice/api/incidents";
    const config = { params };
    axios.get(url, config).then(({ data }) => {
      setData(data);
    });
  };
  useEffect(getData, [params]);
  return (
    <Fragment>
      <WithPermission
        permissions={["CMS_IncidentFullAccess", "CMS_CreateIncident"]}
      >
        <section className="flex items-center gap-4">
          <Button
            type="button"
            variant="primary"
            onClick={handleGoToCreateIncident}
            loading={isChecking}
            className="ms-auto"
          >
            <Icon name="Add" /> <Text>incident.list.createNewBtn</Text>
          </Button>
        </section>
      </WithPermission>
      <Wrapper>
        <Wrapper.Body>
          <Table.Wrapper>
            <Table>
              <Table.Body loading={loading.get}>
                {data.items?.map((e) => (
                  <IncidentRow
                    key={e.crmIncidentId}
                    incident={e}
                    onActionEnd={getData}
                  />
                ))}
              </Table.Body>
            </Table>
          </Table.Wrapper>
        </Wrapper.Body>
      </Wrapper>
      <Pagination
        pageNumber={params?.pageNumber ?? 0}
        totalItems={data?.totalItems ?? 0}
        totalPages={data?.totalPages ?? 0}
        setActivePage={handleSetParams("pageNumber")}
      />
    </Fragment>
  );
}
export default withPermission(Incidents, [
  "CMS_IncidentFullAccess",
  "CMS_GetIncident",
]);
