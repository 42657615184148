import { Fragment } from "react";
import noPermissions from "../assets/images/no-permission-dashboard.svg";
import { Button, Image, Layout, Text } from "../components";
import { useLogout, usePermission } from "../hooks";
import { childrenProps } from "../types";

export default function PermissionProvider({ children }: childrenProps) {
  const hasPermission = usePermission("AS_SalesDashboardAccess");
  const logout = useLogout();
  if (hasPermission) return <Fragment>{children}</Fragment>;
  return (
    <Layout>
      <Layout.Header></Layout.Header>
      <Layout.Body className="space-y-4 py-10">
        <Image
          src={noPermissions}
          alt="No Permission"
          className="block w-full max-w-56 mx-auto"
        />
        <p className="text-base text-dark text-center">
          <Text>global.noPermissionDashboardTitle</Text>
        </p>
        <p className="text-sm text-placeholder text-center">
          <Text>global.noPermissionDashboardText</Text>
        </p>
        <Button
          type="button"
          onClick={logout}
          className="block w-fit mx-auto text-sm"
        >
          <Text>global.logout</Text>
        </Button>
      </Layout.Body>
    </Layout>
  );
}
