import { Fragment, ReactNode, useContext } from "react";
import { twMerge } from "tailwind-merge";
import { OrderDetailsContext } from ".";
import { Icon, Status, Text, Wrapper } from "../../../components";
import { useConverters, useToggle } from "../../../hooks";
import { cn } from "../../../methods";
import { iconNameType } from "../../../types";
import UpFrontPaymentDrawer from "../../Orders/Details/UpFrontPaymentDrawer";
import OrderNotesDrawer from "./OrderNotesDrawer";

type MoreInfoItem = {
  icon: iconNameType;
  label: string;
  value: ReactNode;
  iconClasses?: string;
  fullWidth?: boolean;
  bold?: boolean;
  onClick?: VoidFunction;
};

export default function OrderInfo() {
  const { order } = useContext(OrderDetailsContext);
  const { convertDate, convertAmount } = useConverters();
  const [showOrderNote, toggleOrderNote] = useToggle(false);
  const [showUpFrontPayment, toggleUpFrontPayment] = useToggle(false);
  const isPublished = !!order?.lastPublishedDate;
  const totalAmount = order?.totalAmount ?? 0;
  const totalUpFrontPaymentAmount =
    order?.orderUpfrontPayments
      ?.map((e) => e.amount)
      .reduce((a, b) => a + b, 0) ?? 0;
  const hasTotalUpFrontPaymentAmount = !!totalUpFrontPaymentAmount;
  const isUpFrontPaymentEqualTotalAmount =
    totalUpFrontPaymentAmount >= totalAmount;
  const moreInfo: MoreInfoItem[] = [
    {
      icon: "Calendar",
      label: "pickup.details.lastUpdatedAt",
      value: convertDate(order?.lastUpdatedAt || order?.createdAt),
    },
    {
      icon: "User",
      label: "pickup.details.lastUpdatedByName",
      value: order?.lastUpdatedByName || order?.createdByName,
    },
    {
      icon: "Hierarchy2",
      label: "pickup.details.version",
      value: order?.version,
    },
    {
      icon: "DirectboxDefault",
      label: "pickup.details.channelName",
      value: order?.saleChannel?.title,
      fullWidth: true,
    },
    {
      icon: "DollarSquare",
      label: "pickup.details.totalPrice",
      value: convertAmount(totalAmount),
      fullWidth: true,
      bold: true,
    },
    {
      icon: isUpFrontPaymentEqualTotalAmount ? "TickCircle" : "CloseCircle",
      label: "pickup.details.totalUpFrontPayment",
      value: convertAmount(totalUpFrontPaymentAmount),
      fullWidth: true,
      bold: true,
      onClick: hasTotalUpFrontPaymentAmount ? toggleUpFrontPayment : undefined,
      iconClasses: isUpFrontPaymentEqualTotalAmount
        ? "text-success"
        : "text-danger",
    },
  ];
  return (
    <Fragment>
      <Wrapper className="lg:!col-span-3">
        <Wrapper.Body className="flex flex-wrap items-start gap-4">
          <div className="flex-1 flex flex-col gap-3">
            <section className="flex items-center flex-wrap gap-2">
              <Icon.Wrapper rounded>
                <Icon name="ReceiptText" variant="Bold" />
              </Icon.Wrapper>
              <div className="lg:flex-1">
                <h1 className="text-base text-dark">{order?.title}</h1>
                <p className="text-sm text-secondary">#{order?.number}</p>
              </div>
              <Status.Order
                isPublished={isPublished}
                className={cn(
                  "text-sm font-medium",
                  isPublished && "bg-[#E8FFF3] border-none [&>svg]:hidden"
                )}
              />
            </section>
            <section className="flex flex-wrap items-center gap-x-3 gap-y-2">
              {moreInfo.map((e) => {
                const clickable = !!e.onClick;
                const Element = clickable ? "button" : "p";
                return (
                  <Element
                    key={e.label}
                    className={cn(
                      "text-sm flex items-center gap-1 text-start",
                      e.fullWidth && "basis-full",
                      e.bold && "text-dark font-semibold"
                    )}
                    onClick={e.onClick}
                  >
                    <Icon
                      name={e.icon}
                      variant="Bulk"
                      className={twMerge("text-primary", e.iconClasses)}
                    />
                    <span className="text-placeholder">
                      <Text>{e.label}</Text>:
                    </span>
                    <span
                      className={e.bold ? "text-inherit" : "text-secondary"}
                    >
                      {e.value}
                    </span>
                    {clickable && (
                      <Icon
                        name="InfoCircle"
                        className="text-gray-900 size-4"
                      />
                    )}
                  </Element>
                );
              })}
            </section>
            {/* <section className="flex flex-wrap items-center justify-between gap-3 mt-auto">
              <span className="bg-gray-50 px-2 py-1 rounded-md text-base md:me-auto">
                <Icon
                  name="DollarSquare"
                  variant="Bold"
                  size={22}
                  className="text-dark"
                />{" "}
                <Text>pickup.details.totalPrice</Text>:{" "}
                {convertAmount(order?.totalAmount)}
              </span>
              <Button type="button" light onClick={toggleOrderNote}>
                <Icon name="DocumentText" variant="Bold" />{" "}
                <Text>pickup.details.noteButton</Text>
              </Button>
            </section> */}
          </div>
        </Wrapper.Body>
      </Wrapper>
      <OrderNotesDrawer isOpen={showOrderNote} toggle={toggleOrderNote} />
      <UpFrontPaymentDrawer
        isOpen={showUpFrontPayment}
        toggle={toggleUpFrontPayment}
        orderUpfrontPayments={order.orderUpfrontPayments ?? []}
      />
    </Fragment>
  );
}
