import { useTranslation } from "react-i18next";
import { cleanText } from "../methods";
import { TranslateDto } from "../types";

export default function useFindTranslate() {
  const { i18n } = useTranslation();
  const findTranslate = (
    translates: null | TranslateDto[],
    key: keyof TranslateDto = "labelTitle"
  ) => {
    const lng = i18n.language;
    const translate = translates?.find(
      (e) => e.language === lng || !e.language
    );
    return cleanText(translate?.[key]) || "";
  };
  return findTranslate;
}
