import { Fragment } from "react";
import { twMerge } from "tailwind-merge";
import { config } from "../constants";
import Icon from "./_Icon";
import Layout from "./_Layout";
import Progress from "./_Progress";
import Table from "./_Table";
import Text from "./_Text";
import Wrapper from "./_Wrapper";

type ListProps = {
  length?: number;
};
type ImportProductProps = {
  value: number;
};
type ProductCardProps = { isGrid: boolean };
const loadingItems = [{ delay: "0" }, { delay: "-500ms" }, { delay: "0" }];
function Inline() {
  return (
    <div className="w-fit flex-center gap-[2px] mx-auto p-4">
      {loadingItems.map((e, i) => (
        <span
          key={i}
          style={{ animationDelay: e.delay }}
          className="w-5 h-5 rounded-full bg-primary animate-loader"
        />
      ))}
    </div>
  );
}
function Tr({ length = config.pageSize }: ListProps) {
  return (
    <Fragment>
      {[...Array(length).keys()].map((e) => (
        <tr key={e} className="animate-pulse">
          <td>
            <section className="flex items-center gap-3 w-fit">
              <i className="w-10 h-10 rounded-full bg-gray-100" />
              <div className="max-w-full space-y-2">
                <span className="max-w-full block w-48 h-4 rounded-full bg-gray-100" />
                <span className="max-w-full block w-24 h-4 rounded-full bg-gray-100" />
              </div>
            </section>
          </td>
          <td>
            <span className="max-w-full block w-48 h-4 rounded-full bg-gray-100" />
          </td>
          <td>
            <span className="max-w-full block w-32 h-4 rounded-full bg-gray-100" />
          </td>
          <td>
            <span className="max-w-full inline-block w-24 h-10 rounded bg-gray-100" />
          </td>
        </tr>
      ))}
    </Fragment>
  );
}
function List({ length = config.pageSize }: ListProps) {
  return (
    <Fragment>
      {[...Array(length).keys()].map((e) => (
        <li
          key={e}
          className="animate-pulse flex items-center w-full gap-4 py-2 border-b border-dashed border-gray-100 last:border-none"
        >
          <i className="w-10 h-10 rounded bg-gray-100" />
          <div className="flex-1 space-y-2">
            <span className="max-w-full block w-28 h-4 rounded-full bg-gray-100" />
            <span className="max-w-full block w-16 h-4 rounded-full bg-gray-100" />
          </div>
          <span className="w-24 h-10 rounded bg-gray-100" />
        </li>
      ))}
    </Fragment>
  );
}
function Button() {
  return (
    <div className="absolute inset-0 size-full bg-transparent flex-center gap-[2px] backdrop-blur-lg">
      {loadingItems.map((e, i) => (
        <span
          key={i}
          style={{ animationDelay: e.delay }}
          className="w-2 h-2 rounded-full animate-loader bg-current"
        />
      ))}
    </div>
  );
}
function Header() {
  return (
    <Fragment>
      <Wrapper className="animate-pulse col-span-full">
        <Wrapper.Body className="flex items-start gap-4">
          <i className="w-24 h-24 rounded bg-gray-100" />
          <div className="flex-1 flex flex-col items-start justify-around h-24">
            <div className="w-full flex items-center justify-between gap-4">
              <span className="block w-56 h-4 rounded-full bg-gray-100" />
              <span className="block w-40 h-4 rounded-full bg-gray-100" />
            </div>
            <div className="w-full flex items-center justify-between gap-4">
              <span className="block w-44 h-4 rounded-full bg-gray-100" />
              <span className="block w-52 h-4 rounded-full bg-gray-100" />
            </div>
            <div className="w-full flex items-center justify-between gap-4">
              <span className="block w-48 h-4 rounded-full bg-gray-100" />
              <span className="block w-48 h-4 rounded-full bg-gray-100" />
            </div>
          </div>
        </Wrapper.Body>
        <Wrapper.Footer className="flex items-center gap-4">
          <span className="block w-24 h-4 rounded-full bg-gray-100" />
          <span className="block w-28 h-4 rounded-full bg-gray-100" />
          <span className="block w-20 h-4 rounded-full bg-gray-100" />
          <span className="block w-24 h-4 rounded-full bg-gray-100" />
        </Wrapper.Footer>
      </Wrapper>
      <div className="mt-8 col-span-full">
        <Inline />
      </div>
    </Fragment>
  );
}
function LayoutLoading() {
  return (
    <Layout>
      <Layout.Header>
        <Fragment></Fragment>
      </Layout.Header>
      <Layout.Body>
        <Header />
      </Layout.Body>
    </Layout>
  );
}
function Cards() {
  return (
    <Fragment>
      {[...Array(config.pageSize).keys()].map((e) => (
        <div key={e} className="bg-white rounded text-start p-1 pb-2">
          <div className="w-full aspect-image rounded-lg mb-2 bg-gray-100 animate-pulse" />
          <div className="space-y-3 px-1">
            <div className="w-full h-5 rounded-full bg-gray-100 animate-pulse"></div>
            <div className="w-1/2 h-3 rounded-full bg-gray-100 animate-pulse"></div>
            <div className="w-2/3 h-3 rounded-full bg-gray-100 animate-pulse"></div>
          </div>
        </div>
      ))}
    </Fragment>
  );
}
function ImportProduct({ value }: ImportProductProps) {
  return (
    <div className="absolute inset-0 flex-center z-10 bg-white/90">
      <div className="w-full max-w-xs flex-center flex-col text-center gap-6 py-6">
        <Icon
          name="DocumentDownload"
          variant="Bold"
          className="text-primary text-7xl animate-bounce"
        />
        <p className="text-secondary">
          <Text>global.pleaseWait</Text>
        </p>
        <Progress value={value} />
      </div>
    </div>
  );
}
function Document({ length = 10 }: { length?: number }) {
  return (
    <Fragment>
      {[...Array(length).keys()].map((key) => (
        <div
          key={key}
          className="bg-white rounded px-2 py-4 text-center space-y-2 [&>*]:mx-auto"
        >
          <div className="w-16 h-16 mb-6 bg-gray-100 rounded max-w-full animate-pulse"></div>
          <div className="w-[4.5rem] h-3 rounded-full bg-gray-100 max-w-full animate-pulse"></div>
          <div className="w-24 h-3 rounded-full bg-gray-100 max-w-full animate-pulse"></div>
        </div>
      ))}
    </Fragment>
  );
}
function ProductInventory() {
  return (
    <Wrapper.Body className="p-0">
      <Table>
        <tbody className="w-full [&_td]:!px-0">
          <tr>
            <td>
              <div className="w-36 h-4 bg-gray-100 rounded animate-pulse max-w-full"></div>
            </td>
            <td>
              <div className="w-16 h-7 bg-gray-100 rounded animate-pulse ms-auto"></div>
            </td>
          </tr>
          <tr>
            <td>
              <div className="w-32 h-4 bg-gray-100 rounded animate-pulse"></div>
            </td>
            <td>
              <div className="w-6 h-4 bg-gray-100 rounded animate-pulse ms-auto"></div>
            </td>
          </tr>
          <tr>
            <td>
              <div className="w-36 h-4 bg-gray-100 rounded animate-pulse"></div>
            </td>
            <td>
              <div className="w-36 h-4 bg-gray-100 rounded animate-pulse ms-auto"></div>
            </td>
          </tr>
        </tbody>
      </Table>
    </Wrapper.Body>
  );
}
function Address({ length = 10 }: { length?: number }) {
  return (
    <Fragment>
      {[...Array(length).keys()].map((key) => (
        <div key={key} className="rounded p-4 space-y-4 bg-gray-50">
          <div className="w-[4.5rem] h-3 rounded-full bg-gray-100 max-w-full animate-pulse"></div>
          <div className="flex gap-2">
            <div className="flex-1 space-y-3">
              <div className="w-60 h-3 rounded-full bg-gray-100 max-w-full animate-pulse"></div>
              <div className="w-80 h-3 rounded-full bg-gray-100 max-w-full animate-pulse"></div>
            </div>
            <div className="flex gap-2">
              <div className="w-10 h-10 mb-6 bg-gray-100 rounded max-w-full animate-pulse"></div>
              <div className="w-10 h-10 mb-6 bg-gray-100 rounded max-w-full animate-pulse"></div>
            </div>
          </div>
        </div>
      ))}
    </Fragment>
  );
}
function ShippingMethod() {
  return (
    <div className="flex flex-wrap items-center gap-2">
      {["4rem", "12rem", "7.5rem", "7rem", "5rem", "9.5rem"].map((width) => (
        <div
          key={width}
          style={{ width }}
          className="h-[1.875rem] rounded bg-gray-100 animate-pulse"
        ></div>
      ))}
    </div>
  );
}
function PimProductDetails() {
  return (
    <div className="space-y-5">
      <section className="flex items-center gap-4 border-b border-dashed pb-4">
        <div className="w-32 h-20 rounded shadow bg-gray-100 animate-pulse"></div>
        <div className="w-9 h-6 rounded bg-gray-100 animate-pulse"></div>
      </section>
      <table className="w-full text-sm font-medium [&_td]:py-3">
        <tbody>
          {[...Array(6).keys()].map((key) => {
            const max = 15;
            const width1 = (Math.floor(Math.random() * max) || max) + "rem";
            const width2 = (Math.floor(Math.random() * max) || max) + "rem";
            return (
              <tr key={key} className="border-b border-dashed">
                <td>
                  <div
                    style={{ width: width1 }}
                    className="h-4 bg-gray-100 rounded animate-pulse"
                  ></div>
                </td>
                <td>
                  <div
                    style={{ width: width2 }}
                    className="h-4 bg-gray-100 rounded animate-pulse ms-auto"
                  ></div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="space-y-2">
        <div className="w-24 h-4 rounded bg-gray-100 animate-pulse"></div>
        <div className="w-full h-11 rounded bg-gray-100 animate-pulse"></div>
      </div>
      <div className="space-y-2">
        <div className="w-24 h-4 rounded bg-gray-100 animate-pulse"></div>
        <div className="w-full h-11 rounded bg-gray-100 animate-pulse"></div>
      </div>
      <div className="space-y-2 max-w-80">
        <div className="w-24 h-4 rounded bg-gray-100 animate-pulse"></div>
        <div className="w-full h-11 rounded bg-gray-100 animate-pulse"></div>
      </div>
      <div className="space-y-2">
        <div className="w-24 h-4 rounded bg-gray-100 animate-pulse"></div>
        <div className="w-full h-40 rounded bg-gray-100 animate-pulse"></div>
      </div>
      <div className="space-y-2">
        <div className="w-24 h-4 rounded bg-gray-100 animate-pulse"></div>
        <div className="w-full h-40 rounded bg-gray-100 animate-pulse"></div>
      </div>
      <div className="space-y-2">
        <div className="w-24 h-4 rounded bg-gray-100 animate-pulse"></div>
        <div className="w-full h-40 rounded bg-gray-100 animate-pulse"></div>
      </div>
      <div className="space-y-2">
        <div className="w-24 h-4 rounded bg-gray-100 animate-pulse"></div>
        <div className="w-full h-40 rounded bg-gray-100 animate-pulse"></div>
      </div>
    </div>
  );
}
function ProductCard({ isGrid }: ProductCardProps) {
  return (
    <Fragment>
      {[...Array(config.pageSize).keys()].map((e) => (
        <div
          key={e}
          className={twMerge(
            "w-full flex bg-white shadow rounded gap-2 p-2",
            isGrid ? "flex-col" : "flex-row"
          )}
        >
          <div
            className={twMerge(
              "aspect-image rounded bg-gray-100 animate-pulse",
              isGrid ? "w-full" : "h-28"
            )}
          ></div>
          <div className="flex-1 flex flex-col gap-1">
            <div className="w-full h-4 rounded bg-gray-100 animate-pulse"></div>
            <div className="w-1/2 h-4 rounded bg-gray-100 animate-pulse"></div>
            {!isGrid && (
              <Fragment>
                <div className="w-full h-4 rounded bg-gray-100 animate-pulse"></div>
                <div className="w-1/2 h-4 rounded bg-gray-100 animate-pulse mt-auto"></div>
              </Fragment>
            )}
          </div>
          {isGrid && (
            <div className="w-2/3 h-3 bg-gray-100 rounded animate-pulse"></div>
          )}
          <div
            className={twMerge(
              "grid text-sm text-secondary font-semibold pt-1",
              isGrid
                ? "grid-cols-3 gap-3 w-full"
                : "grid-cols-1 self-center gap-1 px-4 w-20"
            )}
          >
            <div className="w-full h-3 rounded bg-gray-100 animate-pulse"></div>
            <div className="w-full h-3 rounded bg-gray-100 animate-pulse"></div>
          </div>
        </div>
      ))}
    </Fragment>
  );
}
function IncidentActivity() {
  return (
    <Fragment>
      {[...Array(config.pageSize).keys()].map((key) => (
        <div
          key={key}
          className="w-full p-2 border border-[#DEEBF4] rounded space-y-2"
        >
          <div className="flex items-start">
            <div className="flex-1 space-y-2">
              <div className="w-2/5 h-5 rounded bg-gray-100 animate-pulse"></div>
              <div className="w-16 h-5 rounded bg-gray-100 animate-pulse"></div>
            </div>
            <div className="flex-center gap-2">
              <div className="size-6 rounded bg-gray-100 animate-pulse"></div>
              <div className="size-6 rounded bg-gray-100 animate-pulse"></div>
              <div className="size-6 rounded bg-gray-100 animate-pulse"></div>
            </div>
          </div>
          <div className="flex items-center justify-between gap-4">
            <div className="w-40 h-5 rounded bg-gray-100 animate-pulse"></div>
            <div className="w-20 h-5 rounded bg-gray-100 animate-pulse"></div>
          </div>
        </div>
      ))}
    </Fragment>
  );
}
function Radios() {
  return (
    <Fragment>
      {["w-48", "w-32", "w-44", "w-24", "w-36"].map((width) => (
        <div
          key={width}
          className="flex items-center w-full gap-4 py-2 border-t border-gray-100 first:border-none"
        >
          <i className="size-5 rounded-full loading" />
          <div className={`${width} h-4 loading`} />
        </div>
      ))}
    </Fragment>
  );
}
function SecondSalesPerson() {
  return (
    <section className="border rounded p-2 space-y-4 md:col-span-2">
      <div className="w-40 h-5 loading"></div>
      <div className="flex items-start gap-2">
        <div className="size-5 loading"></div>
        <div className="flex-1 space-y-1">
          <div className="w-24 h-4 loading"></div>
          <div className="w-14 h-4 loading"></div>
        </div>
      </div>
    </section>
  );
}
function SupplierProgram() {
  return (
    <Fragment>
      <Wrapper>
        <Wrapper.Body className="space-y-2">
          <div className="w-36 h-4 loading"></div>
          <div className="w-36 h-3 loading"></div>
          <div className="w-36 h-3 loading"></div>
        </Wrapper.Body>
        <Wrapper.Footer className="flex items-center gap-4">
          <div className="w-24 h-3 loading" />
          <div className="w-28 h-3 loading" />
          <div className="w-20 h-3 loading" />
          <div className="w-24 h-3 loading" />
        </Wrapper.Footer>
      </Wrapper>
      <Inline />
    </Fragment>
  );
}
function AvailabilityStatus() {
  return (
    <Fragment>
      {[...Array(3).keys()].map((e) => (
        <div
          key={e}
          className="flex w-full items-center gap-2.5 rounded border p-2.5"
        >
          <div className="w-14 h-7 loading"></div>
          <div className="flex-1">
            <div className="w-36 h-5 max-w-full loading"></div>
          </div>
          {/* <div className="size-10 loading"></div> */}
          {/* <div className="size-10 loading"></div> */}
        </div>
      ))}
    </Fragment>
  );
}
function SupplierContactPerson() {
  return (
    <Fragment>
      {[...Array(4).keys()].map((e) => (
        <div key={e} className="w-full h-20 loading"></div>
      ))}
    </Fragment>
  );
}
function SupplierHolidays() {
  return (
    <Fragment>
      {[...Array(2).keys()].map((e) => (
        <section key={e} className="w-full border rounded p-2.5 space-y-3">
          <div className="w-20 h-4 loading"></div>
          <ul className="w-full divide-y">
            {[1, 2, 3, 4].map((key) => (
              <li
                key={key}
                className="flex items-start gap-5 py-3 first:pt-0 last:pb-0"
              >
                <div className="flex-1">
                  <div className="w-2/3 h-4 loading"></div>
                </div>
                <div className="flex-[2]">
                  <div className="w-2/5 h-4 loading"></div>
                </div>
              </li>
            ))}
          </ul>
        </section>
      ))}
    </Fragment>
  );
}
function ActivityInfo() {
  return (
    <Fragment>
      <table className="w-full text-sm font-medium [&_td]:py-3">
        <tbody className="divide-y divide-dashed">
          {[...Array(6).keys()].map((key) => (
            <tr key={key}>
              <td>
                <div className="size-4 inline-block loading"></div>{" "}
                <div className="inline-block h-4 w-32 loading"></div>
              </td>
              <td>
                <div className="h-4 w-32 loading ms-auto"></div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="w-full h-60 loading"></div>
    </Fragment>
  );
}
function ChangePhaseForm() {
  return (
    <Fragment>
      <div className="w-full h-10 loading"></div>
      <div className="w-full h-28 loading"></div>
      <div className="size-4 rounded-full loading mx-auto"></div>
      <div className="w-full h-36 loading"></div>
      <div className="w-20 h-5 loading !mt-8"></div>
      <div className="w-full h-4 loading"></div>
      <div className="w-full h-10 loading"></div>
      <hr />
      <div className="w-full h-10 loading"></div>
      <hr />
      <div className="w-full h-10 loading"></div>
    </Fragment>
  );
}
const Loading = {
  Cards,
  Tr,
  List,
  Inline,
  Button,
  Header,
  ImportProduct,
  Layout: LayoutLoading,
  Document,
  ProductInventory,
  ShippingMethod,
  PimProductDetails,
  ProductCard,
  IncidentActivity,
  Address,
  Radios,
  SecondSalesPerson,
  SupplierProgram,
  AvailabilityStatus,
  SupplierContactPerson,
  SupplierHolidays,
  ActivityInfo,
  ChangePhaseForm,
};
export default Loading;
