import { Fragment } from "react";
import { Avatar, Badge, Button, Icon, Text } from "../../../../components";
import { useToggle } from "../../../../hooks";
import { showCallNumber } from "../../../../methods";
import { Company } from "../../../../types";
import ActionsModal from "./ActionsModal";
import ChangePrimaryContactPerson from "./ChangePrimaryContactPerson";
import ContactPersonForm from "./ContactPersonForm";
import RemoveContactPerson from "./RemoveContactPerson";

type PersonRowProps = { person: Company.Person; onSubmitted: () => void };

export default function PersonRow({ person, onSubmitted }: PersonRowProps) {
  const [showActions, toggleActions] = useToggle(false);
  const [showEdit, toggleEdit] = useToggle(false);
  const [showRemove, toggleRemove] = useToggle(false);
  const [showChangePrimary, toggleChangePrimary] = useToggle(false);
  const hasPosition = !!person.position;
  return (
    <Fragment>
      <tr>
        <td>
          <div className="flex items-center gap-3 w-fit">
            <Avatar />
            <div className="flex-1 space-y-px">
              <h6 className="text-dark text-base font-medium">
                {[person.firstName, person.lastName].join(" ")}{" "}
                {person.isPrimary && (
                  <Badge variant="primary" className="text-xs">
                    <Text>company.details.contactPerson.primary</Text>
                  </Badge>
                )}
              </h6>
              {hasPosition && (
                <p className="text-placeholder text-sm font-normal">
                  <Text>company.details.contactPerson.position</Text>:{" "}
                  {person.position}
                </p>
              )}
            </div>
          </div>
        </td>
        <td>{person.emailAddress}</td>
        <td className="text-gray-900">{showCallNumber(person.mobileNumber)}</td>
        {/* <td data-sticky className="space-x-2">
          <Button type="button" light onClick={toggleEdit}>
            <Text>company.details.contactPerson.viewDetailsBtn</Text>
          </Button>
          <Button type="button" variant="danger" light onClick={toggleRemove}>
            <Icon name="Trash" variant="Bold" />
          </Button>
        </td> */}
        <td data-sticky>
          <Button light onClick={toggleActions} className="btn-sm">
            <Icon name="More" size={18} className="rotate-90" />
          </Button>
        </td>
      </tr>
      <ActionsModal
        isOpen={showActions}
        toggle={toggleActions}
        person={person}
        onEdit={toggleEdit}
        onChangePrimary={toggleChangePrimary}
        onDelete={toggleRemove}
      />
      <ContactPersonForm
        isOpen={showEdit}
        toggle={toggleEdit}
        person={person}
        personId={person.id}
      />
      <ChangePrimaryContactPerson
        isOpen={showChangePrimary}
        toggle={toggleChangePrimary}
        person={person}
      />
      <RemoveContactPerson
        isOpen={showRemove}
        toggle={toggleRemove}
        person={person}
      />
    </Fragment>
  );
}
