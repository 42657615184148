const errorCodes = {
  default: "Es ist ein Fehler aufgetreten",
  file: "Bitte Datei auswählen",
  form: "Die Daten sind nicht korrekt",
  server: "Serverfehler",
  incidentCustomerMood: "Kundenstimmung ist erforderlich",
  inValidAddress: "Adresse ist ungültig.",
  lineItemInventory: "Dieser Inventarservice ist zurzeit nicht verfügbar",
  noSecondSalesPersonAcceptOffer:
    "Keine Person mit dieser Nummer gefunden, bitte erneut versuchen",
  A1002: "Benutzer nicht gefunden",
  A1003: "Mitarbeiter nicht gefunden",
  A1004: "Benutzergruppe nicht gefunden",
  A1005: "Rechtseinheit nicht gefunden",
  A1007: "Kennen wir schon: Handynummer ist bereits registriert",
  A1008: "Kundenkonto nicht gefunden",
  A1009: "Verifizierungscode für Handynummer ist ungültig",
  A1010: "Handynummer ist noch nicht verifiziert",
  A1011: "E-Mail-Adresse ist nicht verifiziert",
  A1012: "Passcode muss festgelegt werden",
  A1013: "E-Mail-Adresse ist bereits registriert",
  A1014: "Passcode muss gültig sein",
  A1015: "E-Mail-Adresse stimmt nicht überein",
  A1016: "Rechtseinheit mit demselben Code existiert bereits",
  A1017: "Neuer Passcode stimmt nicht überein",
  A1018: "Ungültiger Passcode",
  A1019: "Nutzername stimmt nicht mit der E-Mail-Adresse überein",
  A1020: "Benutzer hat sich bereits angemeldet",
  A1021: "Benutzer existiert bereits",
  A1022: "Gerätetoken ist ungültig",
  A1023: "E-Mail-Bestätigungscode ist abgelaufen",
  A1024: "E-Mail-Bestätigungscode ist ungültig",
  A1025: "Handynummer ist nicht bestätigt",
  A1026: "Kunde kann nicht entfernt werden",
  A1027: "Kunde muss den DSGVO-Bedingungen zustimmen",
  A1028: "Kunde muss eine Person sein",
  A1029: "Handynummer muss registriert sein",
  A1030: "Steuernummer des Unternehmens muss ausgefüllt werden",
  A1031: "Unternehmensregistrierungsnummer muss einzigartig sein",
  A1032: "Kunde muss ein Unternehmen sein",
  A1033: "Ansprechpartner des Unternehmens nicht gefunden",
  A1034: "Land nicht gefunden",
  A1035: "Kundenadresse nicht gefunden",
  AS1001: "ELO-Konfiguration nicht gefunden",
  AS1002: "ELO-Konfiguration existiert bereits",
  AS1003: "ELO-Dienst nicht verfügbar",
  AS2001: "Persönliche Azure-Blob-Konfiguration nicht gefunden",
  AS3001: "Mandanten-Speicherkonfiguration nicht gefunden",
  AS3002: "Speichertyp für PULS-Dateityp nicht festgelegt",
  PE0000: "Berechnungseinheit nicht gefunden",
  PE0100: "Angepasste Berechnungseinheit nicht gefunden",
  PE0101: "Benutzerdefinierte Berechnungseinheit nicht gefunden",
  PE0200: "Preisvorlagen-Code existiert bereits",
  PE0201: "Einkaufspreisvorlage nicht gefunden",
  PE0202: "Einkaufspreisvorlage ist nicht aktiv",
  PE0203: "Zeitraum der Einkaufspreisvorlage hat noch nicht begonnen",
  PE0204: "Zeitraum der Einkaufspreisvorlage ist beendet",
  PE0205: "Keine Einkaufspreisvorlage verfügbar",
  PE0206: "Einkaufspreisvorlage enthält keine Artikel",
  PE0300: "Verkaufspreisvorlage nicht gefunden",
  PE0301: "Verkaufspreisvorlage ist nicht aktiv",
  PE0302: "Zeitraum der Verkaufspreisvorlage hat noch nicht begonnen",
  PE0303: "Zeitraum der Verkaufspreisvorlage ist beendet",
  PE0304: "Keine Verkaufspreisvorlage verfügbar",
  PE0305: "Verkaufspreisvorlage enthält keine Artikel",
  PE0306: "Optionale Rabatte in Verkaufspreisvorlage nicht gefunden",
  PE0400: "Artikel der Einkaufspreisvorlage nicht gefunden",
  PE0500: "Artikel der Verkaufspreisvorlage nicht gefunden",
  PE0600: "Werteingabe Manueller Rabatt erforderlich",
  PE0601: "Rabatt liegt außerhalb des definerten Bereichs",
  12000: "Doppelte Iwofurn-Konfiguration",
  12001: "Iwofurn-Konfiguration nicht gefunden",
  12002: "Store-ILN existiert nicht",
  12003: "Iwofurn-Konfigurations-URL nicht festgelegt",
  12004: "Iwofurn-Konfigurations-Benutzername nicht festgelegt",
  12005: "Iwofurn-Konfigurations-Passwort nicht festgelegt",
  12006: "Interner Iwofurn-Tag nicht gefunden",
  12007: "Iwofurn-Bestellung enthält keine Positionen",
  12008: "Iwofurn-API antwortet nicht",
  SS0001: "Objekt nicht gefunden",
  SS0002: "Verkaufskanal nicht gefunden",
  SS0003: "Verkaufskanal-Code muss einzigartig sein",
  SS0100: "Kanalnachricht nicht gefunden",
  SS0200: "PIM-Produkt nicht gefunden",
  SS4000: "Lieferant nicht gefunden",
  SS5006: "Anzahlungsgrund kann nicht leer oder null sein",
  SS6002: "Programm nicht gefunden",
  SS7024: "PIM-Produktlieferant ist nicht festgelegt",
  SS7025: "PIM-Produktlieferantenprogramm ist nicht festgelegt",
  SS7032: "PIM Verfügbarkeitsstatus nicht gefunden",
  SS7033: "PIM Verfühbarkeitsstatus wurde geerbt",
  SS7034: "PIM Persiode des Status beachten!",
  SS8025: "Angebot nicht gefunden",
  SS8026: "Dokument nicht gefunden",
  SS8027: "Bestellung nicht gefunden",
  SS8028:
    "Bestellung kann nicht erstellt werden, wenn die Lieferant-ID der Position leer ist",
  SS8029:
    "Bestellung kann nicht erstellt werden, wenn die Unterlieferant-ID der Position leer ist",
  SS8030: "Angebotsposition nicht gefunden",
  SS8031: "Angebotsdokument nicht gefunden",
  SS8032: "Angebot ist abgelaufen",
  SS8033: "Kunde nicht gefunden",
  SS8034: "Ansprechpartner des Kundenunternehmens nicht gefunden",
  SS8035: "Kunde hat keine CIM-Nummer",
  SS8036: "Kunde ist nicht genehmigt",
  SS8037: "Bestellposition nicht gefunden",
  SS8038: "Angebotsposition ist im Inventar reserviert",
  SS8039: "Angebotsposition ist im Inventar nicht reserviert",
  SS8040: "Alle Angebotspositionen müssen eine Versandmethode haben",
  SS8041:
    "Bestätigungstermin des Kunden kann nicht in der Vergangenheit liegen",
  SS8042: "Bestellposition nicht gefunden",
  SS8043: "Bestellposition ist im Inventar reserviert",
  SS8045: "Bestellposition ist im Inventar nicht reserviert",
  SS8046: "Bestelldokument PDF wurde nicht erstellt",
  SS8047: "Bestelldokument nicht gefunden",
  SS8048:
    "Angebotsposition ist bereits mit der angegebenen Menge im Inventar reserviert",
  SS8049: "Gewünschtes Lieferdatum des Angebots ist ungültig",
  SS8050: "Gewünschtes Lieferdatum der Bestellung ist ungültig",
  SS8051: "Gewünschtes Lieferdatum der Angebotsposition ist ungültig",
  SS8052: "Gewünschtes Lieferdatum der Bestellposition ist ungültig",
  SS8053: "Bündelposition nicht gefunden",
  SS8060:
    "Das Verfügbarkeitsstatusprogramm des Lieferanten für Angebotspositionen ist nicht gültig",
  SS8061:
    "Das Verfügbarkeitsstatusprogramm des Lieferanten für Bestellpositionen ist nicht gültig",
  SS8064:
    "Das Produktverfügbarkeitsstatus der Angebotsposition im PIM ist nicht gültig",
  SS8065:
    "Das Produktverfügbarkeitsstatus der Bestellposition im PIM ist nicht gültig",
  SS8068: "Apply optional discount is not allowed DE",
  SS8069: "Apply individual discount is not allowed DE",
  SS14208: "Preismotor-Service ist nicht verfügbar",
  SS15003: "Excel-Datei enthält keine Daten",
  SS14100: "Dokumentvorlage nicht gefunden",
  SS14101: "Angebots-PDF-Dokument wurde nicht erstellt",
  SS14103: "Angebot wurde akzeptiert",
  SS14104: "Angebot ist nicht aktiv",
  SS14105: "Standard-Dokumentvorlage nicht gefunden",
  SS14106: "Dokumentvorlage existiert bereits",
  SS14107: "Der MIME-Typ der Dokumentvorlage muss übereinstimmen",
  SS14300: "ERP-Inventarkonfiguration nicht gefunden",
  SS14301: "ERP-Inventardienst-Anmeldung bei Microsoft fehlgeschlagen",
  SS14302: "ERP-Inventardienst-Anmeldung bei Dynamics fehlgeschlagen",
  SS14303: "ERP-Inventardienst ist nicht verfügbar",
  SS14304:
    "Nur Filialen mit Rechtsträgercode können im ERP-Inventardienst verwendet werden",
  SS14305: "ERP-Inventardienst: Reservierung des Produkts nicht verfügbar",
  SS14306:
    "ERP-Inventardienst: Reservierung des Produkts im Lager fehlgeschlagen",
  SS14307:
    "ERP-Inventardienst: Aufhebung der Reservierung des Produkts nicht verfügbar",
  SS14308:
    "ERP-Inventardienst: Aufhebung der Reservierung des Produkts im Lager fehlgeschlagen",
  SS14309: "ERP-Inventardienst: Produktmenge reicht nicht aus",
  SS14310:
    "Nur Produkte mit Artikelnummer können im ERP-Inventardienst verwendet werden",
  SS14311: "ERP-Inventardienst: Keine Antwort für Lagerbestandsabfrage",
  SS14312:
    "ERP-Inventardienst: Standort für Lagerbestandsabfrage existiert nicht",
  SS14313:
    "ERP-Inventardienst: Microsoft-Anmeldung führte zu einer fehlerhaften Anforderung",
  SS14314:
    "ERP-Inventardienst: Microsoft-Anmeldung führte zu einem unbefugten Ergebnis",
  SS14315:
    "ERP-Inventardienst: Dynamics-Anmeldung führte zu einer fehlerhaften Anforderung",
  SS14316:
    "ERP-Inventardienst: Dynamics-Anmeldung führte zu einem unbefugten Ergebnis",
  SS14400: "Verkäuferinformationen nicht gefunden",
  SS15000: "Datei nicht gefunden",
  SS16000: "Auftragsgruppe für Provisionen darf nicht leer sein",
  SS16001:
    "Aufträge von verschiedenen Kunden können nicht zur gleichen Auftragsgruppe für Provisionen hinzugefügt werden",
  SS16002:
    "Aufträge von verschiedenen Filialen können nicht zur gleichen Auftragsgruppe für Provisionen hinzugefügt werden",
  SS16003:
    "Aufträge mit bearbeitetem Status können nicht zur Auftragsgruppe für Provisionen hinzugefügt werden",
  SS16004:
    "Aufträge mit einem späteren Lieferdatum können nicht zur Auftragsgruppe für Provisionen hinzugefügt werden",
  SS16005:
    "Lieferdatum der Auftragsgruppe für Provisionen darf nicht in der Vergangenheit liegen",
  SS16006: "Auftragsgruppe für Provisionen nicht gefunden",
  SS16007:
    "Der Auftrag ist bereits einer Auftragsgruppe für Provisionen zugeordnet",
  SS16008: "Auftrag wurde noch nicht veröffentlicht",
  SS17200: "Druckanforderung wurde nicht gefunden",
  SS17201: "Druckanforderung m,eldet keinen Status",
  SS18019: "Nur die letzet Phsa kann geschlossen werden.",
  SS18027:
    "Startphase kann nur nach kompletter Bearbeitung der Checkliste beginnen",
  SS18010:
    "Der Phasenwechsel ist ersta nach Abschluss der aktuellen Phase möglich.",
  SS0500: "Lieferantenprogramm nicht gefunden",
  SS0501: "Verfügbarkeitsstatus des Lieferantenprogramms nicht gefunden",
  SS0502: "Verfügbarkeitsstatus des Lieferantenprogramms ist Standard",
  SS0503:
    "Der Datumsbereich des Verfügbarkeitsstatus des Lieferantenprogramms sollte nicht in Konflikt stehen",
  SS0504: "Lieferantenurlaub nicht gefunden",
  SS0505: "Lieferantenkontaktperson nicht gefunden",
  SS6100: "Filiale nicht gefunden",
  SS8054: "Unterpositionen werden nur zu Bündelprodukten hinzugefügt",
  SS8055: "Unterpositionen eines Bündels können nicht storniert werden",
  SS8056: "Veröffentlichte Bestellpositionen können nicht gelöscht werden",
  SS8062: "Ungültiger Angebotspositionstyp",
  SS8063: "Ungültiger Bestellpositionstyp",
  C9001: "Erp configuration not found DE",
  C9002: "Send message to erp failed DE",
  C9003: "Channel message not found DE",
  C9004: "Favorite not found DE",
  C9005: "Favorite item should be unique DE",
  C9006: "Favorite item not found DE",
  C9007: "Can not create incident DE",
  C9008: "Can not update incident DE",
  C9009: "Can not upsert order in crm DE",
  C9101: "Crm store not found DE",
  C9102: "Crm order not found DE",
  C9103: "Crm order detail not found DE",
  C9104: "Crm customer not found DE",
  C9105: "Crm employee not found DE",
  C9106: "Can not upsert employee in crm DE",
  C9107: "Sale tools pim product not found DE",
  C9108: "Pim product attributes should be unique DE",
  C9109: "Can not remove order commission group DE",
  C9110: "Can not change order commission group DE",
  C9111: "Can not upset customer in crm DE",
  C9112: "Can not deactive contact person DE",
  C9113: "Crm customer type not defined DE",
  C9114: "Can not upset contact person in crm DE",
  C9115: "There is no price list in crm DE",
  C9116: "There is no transaction currency in crm DE",
  C9117: "Selectedcurrency not found in crm DE",
  C9118: "Can not get price list from crm DE",
  C9119: "Can not get transaction currency list from crm DE",
  C9120: "Crm customer c i m number is empty DE",
  C9121: "Crm legal entity code is empty DE",
  C9122: "Crm employee number is empty DE",
  C9123: "Customer with input c i m number not found in c rm DE",
  C9124: "Store with input legal entity code not found in c rm DE",
  C9125: "Can not get customer with c i m number from crm DE",
  C9126: "Can not get store with legal entity code from crm DE",
  C9127: "Can not get employee with employee number from crm DE",
  C9128: "Crm customer model is empty DE",
  C9129: "Crm sale person model is empty DE",
  C9130: "There is nocurrency in crm DE",
  C9131: "Can not get currency list from crm DE",
  C9132: "Sync order detail to crm failed DE",
  C9133: "Sync order document to crm failed DE",
  C9134: "Supplier with input supplier number not found in c rm DE",
  C9135: "Can not get supplier with suppliere number from crm DE",
  C9136: "Get order details of order from crm failed DE",
  C9137: "Get order documents of order from crm failed DE",
  C9138: "Change order detail status in crm failed DE",
  C9139: "Change order status in crm failed DE",
  C9140: "Sync supplier to crm failed DE",
  C9141: "Sync legal to crm failed DE",
  C9142: "Sync store to crm failed DE",
  C9143: "Change order commission in crm failed DE",
  C91044: "Crm supplier not found DE",
  C9145: "Sync contact person to crm failed DE",
  C9146: "Sync customer account to crm failed DE",
  C9147: "Sync favorite to crm failed DE",
  C9148: "Sync supplier holiday to crm failed DE",
  C9149: "Sync supplier program to crm failed DE",
  C9150: "Remove favorite from crm failed DE",
  C9151: "Remove remove order commission group from crm failed DE",
  C9152: "Sync task to crm failed DE",
  C9153: "Assign task in crm failed DE",
  C9154: "Change task in crm failed DE",
  C9155: "Get lead from crm failed DE",
  C9156: "Get task from crm failed DE",
  C9157: "Get task list from crm failed DE",
  C9158: "Get activity from crm failed DE",
  C9159: "Change incident status in crm failed DE",
  C9160: "Assign incident in crm failed DE",
  C9161: "Get incident list from crm failed DE",
  C9162: "Get incident from crm failed DE",
  C9900: "Crm unknown error DE",
  C9901: "Crm dataverse connection failed DE",
  C9163: "Crm department not found DE",
  C9164: "Sync department to crm failed DE",
  C9165: "Deactive department in crm failed DE",
  C9166: "Can not create department in crm DE",
  C9167: "Can not delete department in crm DE",
  C9168: "Get subject incident from crm failed DE",
  C9169: "Crm subject not found DE",
  C9170: "Crm supplier code is empty DE",
  C9171: "Employeee with input employee number not found in c rm DE",
  C9172: "Crm supplier program not found DE",
  C9173: "Get note list from crm failed DE",
  C9174: "Can not create note DE",
  C9175: "Can not get time line records DE",
  C9176: "Can not create incident files DE",
  C9177: "Can not get incident files DE",
  C9178: "Sync sale channel to crm failed DE",
  C9179: "Sale channel not found DE",
  C9180: "File not found DE",
  C9181: "Unpublish contact person in crm failed DE",
  C9182: "Can not set active stage DE",
  C9183: "Can not get incident stage list DE",
  C9184: "Order field is required DE",
  C9185: "Technical inspection field is required DE",
};
export default errorCodes;
