import { ComponentProps, Dispatch, Fragment, MouseEvent, useMemo } from "react";
import { twMerge } from "tailwind-merge";
import { status } from "../constants";
import {
  CRMActivityStatus,
  CRMTaskStatusReason,
  DocumentStatus,
  IncidentStatus,
  IncidentStatusReason,
  OfferStatus,
  PickupStatus,
  PurchaseStatus,
  SalesStatus,
  SellOffProductStatus,
} from "../enums";
import { useErpStatus, useTranslate } from "../hooks";
import { cn, isEmptyValue } from "../methods";
import { classNameProps, ErpStatusDto } from "../types";
import Badge from "./_Badge";
import Button from "./_Button";
import Icon from "./_Icon";
import Text from "./_Text";
type StatusProps = {
  id?: number | null;
};
type IncidentStatusProps = {
  status: IncidentStatus | undefined;
  statusReason?: IncidentStatusReason | null;
};
type TaskStatusProps = {
  status: CRMActivityStatus | undefined;
  statusReason?: CRMTaskStatusReason | null;
};
type ErpStatusProps<T> = {
  id?: T;
  statuses?: null | ErpStatusDto<T>[];
  isOrderPublished?: boolean;
  title?: string;
  onClick?: Dispatch<MouseEvent<HTMLSpanElement>>;
} & classNameProps;
function Customer({ id }: StatusProps) {
  const activeStatus = status.customer.find((e) => e.id === id);
  return (
    <Badge variant={activeStatus?.variant || "warning"}>
      <Text>{activeStatus?.name || "status.customer.notRegistered"}</Text>
    </Badge>
  );
}
function Company({ id }: StatusProps) {
  const activeStatus = status.company.find((e) => e.id === id);
  return (
    <Badge variant={activeStatus?.variant}>
      <Text>{activeStatus?.name || "--"}</Text>
    </Badge>
  );
}
function Offer({ id, className }: StatusProps & classNameProps) {
  const activeStatus = status.offer.find((e) => e.id === id);
  const isAccepted = id === OfferStatus.Accepted;
  const isRejected = id === OfferStatus.Rejected;
  return (
    <Badge
      variant={activeStatus?.variant || "dark"}
      outline={isAccepted || isRejected}
      className={className}
    >
      {isAccepted && (
        <Icon name="TickCircle" variant="Bold" size={18} className="mr-1" />
      )}
      {isRejected && (
        <Icon name="CloseCircle" variant="Bold" size={18} className="mr-1" />
      )}
      <Text>{activeStatus?.name || ""}</Text>
    </Badge>
  );
}
function Order({
  isPublished,
  className,
}: { isPublished: boolean } & classNameProps) {
  return (
    <Badge
      variant={isPublished ? "success" : "primary"}
      outline={isPublished}
      className={className}
    >
      {isPublished && (
        <Icon name="TickCircle" variant="Bold" size={18} className="mr-1" />
      )}
      <Text>
        {isPublished ? "status.order.published" : "status.order.notPublished"}
      </Text>
    </Badge>
  );
}

function Incident({
  status: incidentStatus,
  statusReason,
  light,
  outline,
  onClick,
  ...props
}: IncidentStatusProps & ComponentProps<typeof Button>) {
  const activeStatus = status.incident.find((e) => e.id === incidentStatus);
  const activeStatusReason = status.incidentReason.find(
    (e) => e.id === statusReason
  );
  const hasStatusReason = !!activeStatusReason;
  const isResolved = incidentStatus === IncidentStatus.Resolved;
  const clickable = !!onClick;
  const handleVariant = isResolved ? { outline: true } : { light: true };
  return (
    <Button
      as="button"
      type="button"
      className="px-3 py-2 rounded"
      variant={activeStatus?.variant || "dark"}
      onClick={onClick}
      {...handleVariant}
      {...props}
    >
      <Text>{activeStatus?.name ?? ""}</Text>
      {hasStatusReason && (
        <Fragment>
          {" "}
          (<Text>{activeStatusReason?.name ?? ""}</Text>)
        </Fragment>
      )}
      {clickable && (
        <Fragment>
          {" "}
          <Icon name="ArrowDown2" />
        </Fragment>
      )}
    </Button>
  );
}
function IncidentReason({ id }: StatusProps) {
  const activeStatus = status.incidentReason.find((e) => e.id === id);
  return (
    <Badge variant={activeStatus?.variant || "dark"} fill>
      <Text>{activeStatus?.name || "-"}</Text>
    </Badge>
  );
}
function IncidentActivity({ id }: StatusProps) {
  const activeStatus = status.incidentActivity.find((e) => e.id === id);
  return (
    <Badge variant={activeStatus?.variant || "dark"} className="text-xs">
      <Text>{activeStatus?.name || "-"}</Text>
    </Badge>
  );
}
function Task({ status: taskStatus, statusReason }: TaskStatusProps) {
  const activeStatus = status.CRMActivity.find((e) => e.id === taskStatus);
  const activeStatusReason = status.CRMTaskReason.find(
    (e) => e.id === statusReason
  );
  const hasStatusReason = !!activeStatusReason;
  const isCompleted = taskStatus === CRMActivityStatus.Completed;
  return (
    <Badge
      className="py-1 px-2 text-sm"
      variant={activeStatus?.variant || "dark"}
      outline={isCompleted}
      // {...handleVariant}
    >
      <Text>{activeStatus?.name ?? ""}</Text>
      {hasStatusReason && (
        <Fragment>
          {" "}
          (<Text>{activeStatusReason?.name ?? ""}</Text>)
        </Fragment>
      )}
    </Badge>
  );
}
function TaskReason({ id }: StatusProps) {
  const activeStatus = status.CRMTaskReason.find((e) => e.id === id);
  return (
    <Badge
      variant={activeStatus?.variant || "dark"}
      className="bg-gray-50 text-secondary text-xs bg-opacity-100"
    >
      <Text>{activeStatus?.name || "-"}</Text>
    </Badge>
  );
}
function Availability({ id }: StatusProps) {
  const activeStatus = status.availability.find((e) => e.id === id);
  return (
    <Badge
      variant={activeStatus?.variant || "dark"}
      className="text-xs font-semibold px-2 py-1.5"
    >
      <Text>{activeStatus?.name || "-"}</Text>
    </Badge>
  );
}

function SellOffProduct({ id }: StatusProps) {
  const selloff = status.sellOffProduct.find((e) => e.id === id);
  const isSold = id === SellOffProductStatus.Sold;
  return (
    <Badge
      variant={selloff?.variant}
      className={cn(
        "text-xs",
        isSold && "bg-[#E7EAC5] text-[#5B8777] bg-opacity-100"
      )}
    >
      <Text>{selloff?.name || "-"}</Text>
    </Badge>
  );
}
function PrintRequest({ id }: StatusProps) {
  const activeStatus = status.printRequest.find((e) => e.id === id);
  if (!activeStatus) return null;
  return (
    <Badge variant={activeStatus?.variant}>
      <Text>{activeStatus?.name || "-"}</Text>
    </Badge>
  );
}
function Sales({
  id: initId,
  statuses = [],
  className,
  isOrderPublished = false,
  title,
  onClick,
}: ErpStatusProps<SalesStatus>) {
  const translate = useTranslate();
  const erpStatus = useErpStatus({ statuses });
  const id = erpStatus ?? initId;
  const hasTitle = !!title;
  const activeStatus = status.sales.find((e) => e.id === id);
  const isNone = id === SalesStatus.None;
  const statusLabel = useMemo(() => {
    if (!isOrderPublished) return "status.sales.notPublished";
    if (!!activeStatus) return activeStatus.name;
    return "--";
  }, [isOrderPublished, activeStatus]);
  return (
    <Badge
      variant={activeStatus?.variant || "dark"}
      className={twMerge(
        "text-xs py-[0.375rem]",
        isNone && "text-[#5B8777] bg-[#E7EAC5] bg-opacity-100",
        className
      )}
      onClick={onClick}
    >
      {hasTitle ? (
        <Text status={translate(statusLabel)}>{title}</Text>
      ) : (
        <Text>{statusLabel}</Text>
      )}
    </Badge>
  );
}
function Purchase({
  id: initId,
  statuses = [],
  className,
  // isOrderPublished = false,
  title,
  onClick,
}: ErpStatusProps<PurchaseStatus>) {
  const translate = useTranslate();
  const erpStatus = useErpStatus({ statuses });
  const id = erpStatus ?? initId;
  const hasTitle = !!title;
  const activeStatus = status.purchase.find((e) => e.id === id);
  const isNone = id === PurchaseStatus.None;
  const statusLabel = useMemo(() => {
    // if (!isOrderPublished) return "status.purchase.notPublished";
    if (!!activeStatus) return activeStatus.name;
    return "--";
  }, [activeStatus]);
  return (
    <Badge
      variant={activeStatus?.variant || "dark"}
      className={twMerge(
        "text-xs py-[0.375rem]",
        isNone && "text-[#5B8777] bg-[#E7EAC5] bg-opacity-100",
        className
      )}
      onClick={onClick}
    >
      {hasTitle ? (
        <Text status={translate(statusLabel)}>{title}</Text>
      ) : (
        <Text>{statusLabel}</Text>
      )}
    </Badge>
  );
}
function Document({
  id: initId,
  statuses = [],
  className,
  // isOrderPublished = false,
  title,
  onClick,
}: ErpStatusProps<DocumentStatus>) {
  const erpStatus = useErpStatus({ statuses });
  const id = erpStatus ?? initId;
  const translate = useTranslate();
  const hasTitle = !!title;
  const activeStatus = status.document.find((e) => e.id === id);
  const statusLabel = useMemo(() => {
    // if (!isOrderPublished) return "status.document.notPublished";
    if (!!activeStatus) return activeStatus.name;
    return "--";
  }, [activeStatus]);
  return (
    <Badge
      variant={activeStatus?.variant || "dark"}
      className={twMerge("text-xs py-[0.375rem]", className)}
      onClick={onClick}
    >
      {hasTitle ? (
        <Text status={translate(statusLabel)}>{title}</Text>
      ) : (
        <Text>{statusLabel}</Text>
      )}
    </Badge>
  );
}
function Pickup({
  id,
  reservationNumber,
}: StatusProps & { reservationNumber?: string | null }) {
  const activeType = status.pickup.find((e) => e.id === id);
  const isNone = id === PickupStatus.None;
  const isPickupRequested = id === PickupStatus.PickupRequested;
  const hasReservationNumber = !!reservationNumber && isPickupRequested;
  if (isEmptyValue(id)) return null;
  return (
    <Badge
      variant={activeType?.variant ?? "dark"}
      fill={isNone}
      className="py-1 px-2 text-xs space-x-1"
    >
      <span className="inline-block">
        <Text>{activeType?.name ?? ""}</Text>
      </span>
      {hasReservationNumber && (
        <span className="inline-block">{`(${reservationNumber})`}</span>
      )}
    </Badge>
  );
}

const Status = {
  Pickup,
  Company,
  Customer,
  Offer,
  Incident,
  IncidentReason,
  Sales,
  Purchase,
  Document,
  IncidentActivity,
  Order,
  Task,
  TaskReason,
  Availability,
  SellOffProduct,
  PrintRequest,
};
export default Status;
