import { cloneDeep } from "lodash";
import { useContext, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { ContactPersonsContext } from ".";
import { CompanyContext } from "..";
import {
  Button,
  Drawer,
  Form,
  InputGroup,
  Select,
  Text,
} from "../../../../components";
import { countryCodes, rules, types } from "../../../../constants";
import { useAxios } from "../../../../hooks";
import { Optional, togglePropsType } from "../../../../types";
import { CallNumber } from "../../../../types/customer";

type PersonData = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  salutation: number;
  salutationTitle: number | null;
  gender: number;
  phoneNumber: CallNumber | null;
  mobileNumber: CallNumber | null;
  position: string;
};
type ContactPersonFormProps = togglePropsType & {
  isPrimary?: boolean;
  personId?: string | null;
  person?: PersonData | null;
};

export default function ContactPersonForm({
  isOpen,
  toggle,
  isPrimary = false,
  personId = null,
  person = null,
}: ContactPersonFormProps) {
  const defaultCallNumber = {
    number: NaN,
    countryCode: +(countryCodes[0]?.id ?? ""),
  };
  const { axios, loading } = useAxios();
  const { companyData } = useContext(CompanyContext);
  const { getPersons } = useContext(ContactPersonsContext);
  const initData = useMemo(() => {
    return (
      person ?? {
        gender: types.gender[0].id,
        mobileNumber: defaultCallNumber,
        phoneNumber: defaultCallNumber,
        salutation: types.salutation[0].id,
        salutationTitle: null,
      }
    );
  }, [person]);
  const [data, setData] = useState<Optional<PersonData>>(initData);
  const isNew = !personId && !isPrimary;
  const hasPhoneNumber = !!data.phoneNumber?.number;
  const handleSetCountryCodeValue = (key: "mobileNumber" | "phoneNumber") => {
    return (value: any) =>
      setData((p) => {
        const data = cloneDeep(p);
        const number = data[key]?.number ?? NaN;
        data[key] = { countryCode: value, number };
        return data;
      });
  };
  const handleSetValue = (key: keyof PersonData) => {
    const isMobileNumber = key === "mobileNumber";
    const isPhoneNumber = key === "phoneNumber";
    return (value: any) => {
      setData((p) => {
        const data = cloneDeep(p);
        if (isMobileNumber || isPhoneNumber) {
          const countryCode = data[key]?.countryCode ?? NaN;
          data[key] = { countryCode, number: value };
          return data;
        }
        data[key] = value;
        return data;
      });
    };
  };
  const addNewPerson = () => {
    const url = `/accountservice/api/customers/${companyData.id}/as-company/company-contact-persons`;
    const body = cloneDeep(data);
    if (!hasPhoneNumber) {
      body.phoneNumber = null;
    }
    axios.post(url, body).then(() => {
      toast.success("company.details.contactPerson.form.addPersonMessage");
      getPersons();
      setData(initData);
      toggle();
    });
  };
  const updatePerson = () => {
    const url = `/accountservice/api/customers/${companyData.id}/as-company/company-contact-persons/${personId}`;
    const body = cloneDeep(data);
    if (!hasPhoneNumber) {
      body.phoneNumber = null;
    }
    axios.put(url, body).then(() => {
      toast.success("company.details.contactPerson.form.editPersonMessage");
      getPersons();
      toggle();
    });
  };
  const submit = () => {
    if (!!personId) return updatePerson();
    return addNewPerson();
  };
  return (
    <Drawer as={Form} onSubmit={submit} isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header>
          <h6 className="text-dark text-lg text-center">
            <Text>company.details.contactPerson.form.formTitle</Text>
          </h6>
        </Drawer.Header>
        <Drawer.Body className="space-y-4">
          <InputGroup
            label="company.details.contactPerson.form.firstName"
            value={data.firstName}
            setValue={handleSetValue("firstName")}
            placeholder="company.details.contactPerson.form.firstNamePlaceholder"
            rules={rules.required}
            autoCapitalize
          />
          <InputGroup
            label="company.details.contactPerson.form.lastName"
            value={data.lastName}
            setValue={handleSetValue("lastName")}
            placeholder="company.details.contactPerson.form.lastNamePlaceholder"
            rules={rules.required}
            autoCapitalize
          />
          <Select
            label="company.details.contactPerson.form.gender"
            value={data.gender}
            setValue={handleSetValue("gender")}
            placeholder="company.details.contactPerson.form.genderPlaceholder"
            rules={rules.required}
            items={types.gender}
          />
          <Select
            label="company.details.contactPerson.form.salutation"
            value={data.salutation}
            setValue={handleSetValue("salutation")}
            placeholder="company.details.contactPerson.form.salutationPlaceholder"
            rules={rules.required}
            items={types.salutation}
          />
          <Select
            label="company.details.contactPerson.form.salutationTitle"
            value={data.salutationTitle}
            setValue={handleSetValue("salutationTitle")}
            placeholder="company.details.contactPerson.form.salutationTitlePlaceholder"
            items={types.salutationTitle}
          />
          <InputGroup
            label="company.details.contactPerson.form.emailAddress"
            value={data.emailAddress}
            setValue={handleSetValue("emailAddress")}
            placeholder="company.details.contactPerson.form.emailAddressPlaceholder"
            rules={rules.email}
            type="email"
          />
          <InputGroup
            label="company.details.contactPerson.form.position"
            value={data.position}
            setValue={handleSetValue("position")}
            placeholder="company.details.contactPerson.form.positionPlaceholder"
          />
          <div className="flex items-start gap-3">
            <Select
              label="company.details.contactPerson.form.countryCode"
              value={data.mobileNumber?.countryCode}
              setValue={handleSetCountryCodeValue("mobileNumber")}
              placeholder="+49"
              items={countryCodes}
              rules={rules.required}
              className="flex-[2]"
            />
            <InputGroup
              label="company.details.contactPerson.form.mobileNumber"
              value={data.mobileNumber?.number}
              setValue={handleSetValue("mobileNumber")}
              placeholder="company.details.contactPerson.form.mobileNumberPlaceholder"
              type="integer"
              rules={rules.required}
              className="flex-[4]"
            />
          </div>
          <div className="flex items-start gap-3">
            <Select
              label="company.details.contactPerson.form.countryCode"
              value={data.phoneNumber?.countryCode}
              setValue={handleSetCountryCodeValue("phoneNumber")}
              placeholder="+49"
              items={countryCodes}
              rules={hasPhoneNumber ? rules.required : []}
              className="flex-[2]"
            />
            <InputGroup
              label="company.details.contactPerson.form.phoneNumber"
              value={data.phoneNumber?.number}
              setValue={handleSetValue("phoneNumber")}
              placeholder="company.details.contactPerson.form.phoneNumberPlaceholder"
              type="integer"
              rules={hasPhoneNumber ? rules.phoneNumber : []}
              className="flex-[4]"
            />
          </div>
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-end gap-4">
          <Button variant="danger" onClick={toggle}>
            <Text>company.details.contactPerson.form.cancelBtn</Text>
          </Button>
          {isNew ? (
            <Button type="submit" loading={loading.post}>
              <Text>company.details.contactPerson.form.submitPerson</Text>
            </Button>
          ) : (
            <Button type="submit" loading={loading.update}>
              <Text>company.details.contactPerson.form.submitChanges</Text>
            </Button>
          )}
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
